<home-toolbar [showBackButton]="true" [title]="'reisrapport' | translate"></home-toolbar>

<ion-content>
    <div class="container">

        <div class="row">
            <div class="col-md-6 mb-md-2 mb-sm-2 mb-3">
                <h1 class="text-uppercase mb-3"> {{ 'reisrapport' | translate }}</h1>

                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'reisnummer' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.TravelNumber }}</span>
                </div>
                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'laadhaven' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.LoadingPortName }}</span>
                </div>
                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'vertrek' | translate}}</label>
                    <span
                        class="col-md-6 col-sm-6 col-6">{{ journeyReport?.DepartmentDate | date: 'dd-MM-yyyy HH:mm' }}</span>
                </div>
                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'loshaven' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.DischargePortName }}</span>
                </div>
                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'aankomst' | translate}}</label>
                    <span
                        class="col-md-6 col-sm-6 col-6">{{ journeyReport?.ArrivalDate | date: 'dd-MM-yyyy HH:mm' }}</span>
                </div>
                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'lading' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.CargoName }}</span>
                </div>
                <div class="row align-items-center mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'tonnage_schip' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.Tonnage }}</span>
                </div>
            </div>

            <div class="col-md-6">
                <h1 class="text-uppercase mb-3">{{ 'gasolieverbruik' | translate }}</h1>

                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'gasolie_stand_aanvang' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.OilLevelStart }}</span>
                </div>
                <div class="row align-items-center mb-2 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'gebunkerd_tijdens_reis' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.BunkeredAmount }}</span>
                </div>
                <div class="row align-items-center mb-3 mr-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{'gasolie_stand_aankomst' | translate}}</label>
                    <span class="col-md-6 col-sm-6 col-6">{{ journeyReport?.OilLevelEnd }}</span>
                </div>

                <div *ngIf="journeyReport?.Barges?.length">
                    <h1 class="text-uppercase mb-3 mt-3">{{ 'duwbakken' | translate }}</h1>

                    <div class="row">
                        <div class="col-md-10 col-sm-12 col-12">
                            <table class="table table-fc">
                                <thead>
                                    <tr>
                                        <th>{{'naam' | translate}}</th>
                                        <th>{{'tonnage' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let barge of journeyReport?.Barges">
                                        <td>{{barge?.Name}}</td>
                                        <td>{{barge?.Tonnage}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div *ngIf="journeyReport?.Images?.length">
                    <h1 class="text-uppercase mb-3 mt-3">{{ 'fotos' | translate }}</h1>

                    <div class="row mb-3" *ngFor="let image of journeyReport?.Images">
                        <div class="col-12">
                            <img [src]="image.Image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>