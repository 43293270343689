import { NavController } from '@ionic/angular';
import { Disposable } from './Disposable';


export class BaseComponent extends Disposable {

    constructor(protected navController: NavController) {
        super();
    }

    navigateForward(route: string) {
        this.navController.navigateForward(route);
    }

    navigateBack(route: string) {
        this.navController.navigateBack(route);
    }

    navigateRoot(route: string) {
        this.navController.navigateRoot(route);
    }
}
