<div class="lang-select">
    <label class="lang">
        <img class="flag" [src]="'./assets/icon/flags/' + currentLanguage + '.png'">
        {{'taal' | translate}}:
    </label>
    <ion-select [value]="currentLanguage" interface="popover" (ionChange)="onLanguageChange($event)" class="col">
        <ion-select-option value="nl">{{'nederlands' | translate}}</ion-select-option>
        <ion-select-option value="en">{{'engels' | translate}}</ion-select-option>
        <ion-select-option value="de">{{'duits' | translate}}</ion-select-option>
    </ion-select>
</div>
