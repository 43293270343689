import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, from, throwError } from 'rxjs';
import {
  NavController,
  ToastController,
  LoadingController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalAppSettingsService } from '../services/local-app-settings.service';
import { AuthenticationService } from '../services/authentication.service';
import { ILoginResult } from '../shared/models/LoginModel';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private navController: NavController,
    private toastController: ToastController,
    private translateService: TranslateService,
    private appSettings: LocalAppSettingsService,
    private authenticationService: AuthenticationService,
    private loadingController: LoadingController
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse): Observable<any> => {
        if (error && error.status == 401) {
          return from(this.handleUnAuthorized(req, error)).pipe(
            mergeMap((result) => {
              const freshTokenReq = req.clone({
                setHeaders: { Authorization: 'Bearer ' + result.token },
              });
              return next.handle(freshTokenReq);
            }),
            catchError((error) => {
              return throwError(() => error);
            })
          );
        } else if (error && error.status == 500) {
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handleUnAuthorized(
    request: HttpRequest<any>,
    error: HttpErrorResponse
  ): Promise<ILoginResult> {
    return new Promise((resolve, reject) => {
      if (this.appSettings.rememberMe && !request.url.includes('refresh')) {
        this.authenticationService.refresh().then((result: ILoginResult) => {
          this.authenticationService.saveAccessTokens(result);
          resolve(result);
        });
      } else {
        this.toastController
          .create({
            message: this.translateService.instant('sessie_verlopen'),
            duration: 2000,
            position: 'bottom',
            color: 'primary',
          })
          .then((toast) => {
            toast.present();
            this.navController.navigateRoot('/account/login');
            reject(error);
          })
          .finally(() => this.loadingController.dismiss());
      }
    });
  }
}
