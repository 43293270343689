import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IShipRole } from 'src/app/shared/models/ShipRole';
import { PlanningService } from '../planning.service';
import * as moment from 'moment';
import { IScheduleItem } from 'src/app/shared/models/Schedule';

@Component({
  selector: 'planning-item',
  templateUrl: './planning-item.component.html',
  styleUrls: ['./planning-item.component.scss'],
})
export class PlanningItemComponent implements OnInit, OnChanges {
  @Input() role?: IShipRole;
  @Input() date?: string;
  @Input() display?: string;

  events?: IScheduleItem[];
  description?: string;

  constructor(private planningService: PlanningService) {}

  ngOnInit() {
    this.checkForEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let item in changes)
      if (!changes[item].firstChange) this.checkForEvents();
  }

  private checkForEvents() {
    const date = moment(this.date).format('L');

    if (this.planningService.groupedScheduleItems.hasOwnProperty(date)) {
      const itemsForDate = this.planningService.groupedScheduleItems[date];
      this.events = itemsForDate.filter((item) => {
        return item?.ShipRoleId === this.role?.Id;
      }).map(x => x!);
    } else {
      this.events = [];
      this.description = '';
      return;
    }

    if (this.events.length == 1) {
      this.description = this.events[0].EmployeeName;
    } else if (this.events.length == 2) {
      this.description =
        this.events[0].EmployeeName + ' / ' + this.events[1].EmployeeName;
    } else {
      this.description = '';
    }
  }

  hasHalfSchedule() {
    if (this.events?.length) {
      if (this.display == 'mobile') {
        var todayStart = moment(this.date).set('hour', 0).set('minute', 0);
        var todayEnd = moment(this.date).set('hour', 23).set('minute', 59);

        var scheduledStartDate = moment(this.events[0].StartTime);
        var scheduledEndDate = moment(this.events[0].EndTime);

        if (
          todayStart.diff(scheduledStartDate) > 0 &&
          todayEnd.diff(scheduledEndDate) < 0
        ) {
          return false;
        }

        if (this.events.length < 2) {
          return true;
        }

        return false;
      } else {
        var currentStartDate = moment(this.date);
        var currentEndDate = moment(this.date)
          .set('hour', 23)
          .set('minute', 59);

        var scheduledStartDate = moment(this.events[0].StartTime);
        var scheduledEndDate = moment(this.events[0].EndTime);

        if (
          currentStartDate.diff(scheduledStartDate) > 0 &&
          currentEndDate.diff(scheduledEndDate) < 0
        ) {
          return false;
        }

        if (this.events.length < 2) {
          return true;
        }

        return false;
      }
    }
    return false;
  }
}
