import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/classes/BaseFormComponent';
import { NavController, AlertController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IChangePasswordModel } from 'src/app/shared/models/ChangePassword';
import { comparePasswords } from 'src/app/shared/classes/FormValidators';
import { AccountService } from './account.service';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent extends BaseFormComponent implements OnInit {

    username?: string;
    isOnline: boolean = false;
    pushEnabled: boolean = false;

    constructor(navController: NavController,
        private translateService: TranslateService,
        private alertController: AlertController,
        private accountService: AccountService,
        private appSettings: LocalAppSettingsService,
        private settingsService: SettingsService,
        private networkService: NetworkService) {
        super(navController);
    }

    ngOnInit() {
        this.pushEnabled = this.appSettings.pushNotificationsEnabled;
        this.username = this.appSettings.username;
        this.isOnline = this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online;

        this.form = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.minLength(7)]),
            passwordRepeat: new FormControl('', [Validators.required, Validators.minLength(7)])
        }, { validators: comparePasswords });
    }

    onNotificationToggleChange(event: CustomEvent) {
        let checked = event.detail.checked;

        if (checked != this.appSettings.pushNotificationsEnabled) {
            if (checked) {
                this.settingsService.enablePushNotifications();
            } else {
                this.settingsService.notificationSubscription?.unsubscribe();
            }

            this.appSettings.pushNotificationsEnabled = checked;
        }
    }

    async submit(value: IChangePasswordModel) {
        try {
            await this.disableFormDuringApiCall(() => this.accountService.update({password: value.password}));

            const alert = await this.alertController.create({
                header: this.translateService.instant('mijn_account'),
                message: this.translateService.instant('wachtwoord_veranderd'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
            this.navController.navigateBack("/home");
        } catch (exception: any) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('mijn_account'),
                message: this.translateService.instant('error_wachtwoord_veranderen'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }
    }
}
