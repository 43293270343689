import { ISerenityModel } from './serenity/SerenityEntity';
import { HandOverFormType } from '../classes/HandOverFormType';

export interface IHandOverEntity extends ISerenityModel {
    HandOverFormType: HandOverFormType;
    ShipId: number;
    City: string | null;
    Date: Date;
    TravelOrders: string | null;
    LoadingPapers: string | null;
    LoadingSituation: string | null;
    BunkerFront: number | null;
    BunkerRear: number | null;
    BunkerOrdered: number | null;
    DrinkWaterFront: number | null;
    DrinkWaterRear: number | null;
    NauticalEquipment: string | null;
    MovementQualities: string | null;
    Pumps: string | null;
    Engines: string | null;
    CertificatesValid: boolean;
    SafetyEquipmentValid: boolean;
    Remarks: string | null;
    MaterialsOrdered: boolean;
    Particularities: string | null;
    NameCaptainOnboard: string;
    SignatureCaptainOnboard: string;
    NameCaptainOffboard: string;
    SignatureCaptainOffboard: string;
    FormTypeSpecificFields: string;
}

export class HandOver implements IHandOver {
    handOverFormType: HandOverFormType;
    shipId: number;
    city: string | null;
    date: Date;
    travelOrders: string | null;
    loadingPapers: string | null;
    loadingSituation: string | null;
    bunkerFront: number | null;
    bunkerRear: number | null;
    bunkerOrdered: number | null;
    drinkWaterFront: number | null;
    drinkWaterRear: number | null;
    nauticalEquipment: string | null;
    movementQualities: string | null;
    pumps: string | null;
    engines: string | null;
    certificatesValid: boolean;
    safetyEquipmentValid: boolean;
    remarks: string | null;
    materialsOrdered: boolean;
    particularities: string | null;
    nameCaptainOnboard: string | null;
    signatureCaptainOnboard: string | null;
    nameCaptainOffboard: string | null;
    signatureCaptainOffboard: string | null;
    formTypeSpecificFields: string | null;

    constructor() {
        this.handOverFormType = HandOverFormType.DryLoad;
        this.shipId = 0;
        this.city = '';
        this.date = new Date();
        this.travelOrders = '';
        this.loadingPapers = '';
        this.loadingSituation = '';
        this.bunkerFront = 0;
        this.bunkerRear = 0;
        this.bunkerOrdered = 0;
        this.drinkWaterFront = 0;
        this.drinkWaterRear = 0;
        this.nauticalEquipment = '';
        this.movementQualities = '';
        this.pumps = '';
        this.engines = '';
        this.certificatesValid = false;
        this.safetyEquipmentValid = false;
        this.remarks = '';
        this.materialsOrdered = false;
        this.particularities = '';
        this.nameCaptainOnboard = '';
        this.signatureCaptainOnboard = '';
        this.nameCaptainOffboard = '';
        this.signatureCaptainOffboard = '';
        this.formTypeSpecificFields = '';
    }
    
}


export interface IHandOver {
    handOverFormType: HandOverFormType;
    shipId: number;
    city: string | null;
    date: Date;
    travelOrders: string | null;
    loadingPapers: string | null;
    loadingSituation: string | null;
    bunkerFront: number | null;
    bunkerRear: number | null;
    bunkerOrdered: number | null;
    drinkWaterFront: number | null;
    drinkWaterRear: number | null;
    nauticalEquipment: string | null;
    movementQualities: string | null;
    pumps: string | null;
    engines: string | null;
    certificatesValid: boolean;
    safetyEquipmentValid: boolean;
    remarks: string | null;
    materialsOrdered: boolean;
    particularities: string | null;
    nameCaptainOnboard: string | null;
    signatureCaptainOnboard: string | null;
    nameCaptainOffboard: string | null;
    signatureCaptainOffboard: string | null;
    formTypeSpecificFields: string | null;
}
