<ion-menu side="end" contentId="content" menuId="fc-menu" class="fc-menu" type="overlay" [disabled]="router.url === '/account/login' || router.url === '/account/wachtwoordvergeten'">
    <ion-content>
        <div class="menu-toggle-wrapper">
            <div class="float-right">
                <ion-menu-toggle auto-hide="false" menu="fc-menu" slot="end">
                    <ion-button color="secondary">
                        <ion-icon slot="icon-only" name="menu"></ion-icon>
                    </ion-button>
                </ion-menu-toggle>
            </div>
        </div>

        <ion-list>
            <ion-item href="#" [routerLink]="[]" (click)="navigateToRoute('/home')">
                {{'home' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]" (click)="navigateToRoute('/home/live-locations')">
                {{'live_locaties' | translate}}
            </ion-item>
            <ion-item-group (click)="showJourneyreportMenu = !showJourneyreportMenu">
                <ion-item>
                    <ion-label>{{'reisrapport' | translate}}</ion-label>
                </ion-item>
                <ion-list [inset]="true" *ngIf="showJourneyreportMenu">
                    <ion-item menu-close href="#" [routerLink]="[]" (click)="navigateToRoute('/home/add-journey-report')">
                        {{'reisrapport_toevoegen' | translate}}
                    </ion-item>
                    <ion-item menu-close [routerLink]="[]" (click)="navigateToRoute('/home/journey-reports')">
                        {{'reisrapport_archief' | translate}}
                    </ion-item>
                </ion-list>
            </ion-item-group>
            <ion-item href="#" [routerLink]="[]" (click)="navigateToRoute('/home/planning')">
                {{'planning' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]" (click)="navigateToRoute('/home/maintainance')">
                {{'onderhoud' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]"  (click)="navigateToRoute('/home/certificates')">
                {{'certificaten' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]" (click)="navigateToRoute('/home/repairs')">
                {{'reparaties' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]" (click)="navigateToRoute('/home/inspection-reports')">
                {{'inspectierapporten' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]"  (click)="navigateToRoute('/home/declarations')">
                {{'declaraties' | translate}}
            </ion-item>
            <ion-item-group (click)="showWorkOrderMenu = !showWorkOrderMenu">
                <ion-item>
                    <ion-label>{{'werkbon' | translate}}</ion-label>
                </ion-item>
                <ion-list [inset]="true" *ngIf="showWorkOrderMenu">
                    <ion-item menu-close href="#" [routerLink]="[]" (click)="navigateToRoute('/home/add-work-order')">
                        {{'werkbon_toevoegen' | translate}}
                    </ion-item>
                    <ion-item menu-close [routerLink]="[]" (click)="navigateToRoute('/home/work-orders')">
                        {{'werkbon_archief' | translate}}
                    </ion-item>
                </ion-list>
            </ion-item-group>
            <ion-item-group (click)="showhandOverMenu = !showhandOverMenu">
                <ion-item>
                    <ion-label>{{'overdracht' | translate}}</ion-label>
                </ion-item>
                <ion-list [inset]="true" *ngIf="showhandOverMenu">
                    <ion-item menu-close href="#" [routerLink]="[]" (click)="navigateToRoute('/home/add-handover')">
                        {{'overdracht_toevoegen' | translate}}
                    </ion-item>
                    <ion-item menu-close [routerLink]="[]" (click)="navigateToRoute('/home/handovers')">
                        {{'overdracht_archief' | translate}}
                    </ion-item>
                </ion-list>
            </ion-item-group>
            <ion-item href="#" [routerLink]="[]"  (click)="navigateToRoute('/home/my-account')">
                {{'mijn_account' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]"  (click)="navigateToRoute('/home/choose-ship')">
                {{'choose_ship' | translate}}
            </ion-item>
            <ion-item href="#" [routerLink]="[]" (click)="logout()">
                {{'uitloggen' | translate}}
            </ion-item>
        </ion-list>

        <language-switch></language-switch>

    </ion-content>
</ion-menu>
