import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrderStatus } from '../classes/WorkOrderStatus';

@Pipe({
    name: 'workOrderStatus'
})
export class WorkOrderStatusPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(status?: WorkOrderStatus, args?: any): any {
        switch (status) {
            case WorkOrderStatus.Yes:
                return this.translateService.instant("werkbonstatus_ja");
            case WorkOrderStatus.No:
                return this.translateService.instant("werkbonstatus_nee");
            case WorkOrderStatus.Partially:
                return this.translateService.instant("werkbonstatus_deels");
        }

        return this.translateService.instant("werkbonstatus_nee");
    }
}
