<home-toolbar
  [showBackButton]="true"
  [title]="'overdracht' | translate"></home-toolbar>

<ion-content>
  <div class="d-flex flex-column justify-content-between h-100">
    
      <div class="container">
        <div class="row">
          <div class="col mb-md-2 mb-sm-2 mb-5">
            <!-- Overdracht -->
            <h1 class="text-uppercase mb-3">{{ "overdracht" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"plaats" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.City}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"datum" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">
                  {{handover?.Date | date : "dd-MM-yyyy"}}
                </label>
              </div>
            </div>
            <!-- Eind overdracht -->

            <!-- Lading -->
            <h1 class="text-uppercase mb-3">{{ "lading" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"reis_orders" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.TravelOrders}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"ladingspapieren" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.LoadingPapers}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"ladingssituatie" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.LoadingSituation}}</label>
              </div>
            </div>


            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"toestandladingstanks" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('stateChargeTanks')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"leidingen_slangen" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('pipesHoses')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"reinigen_gasvrij" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('cleaningGasFree')}}</label>
              </div>
            </div>
            <!-- Eind lading -->

            <!-- Slobtanks -->
            <h1 *ngIf="shipType == handOverFormType.Tanker" class="text-uppercase mb-3">{{ "slobtanks" | translate }}</h1>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"slobtanks_bb" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('slobtanksBb')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"slobtanks_mid" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('slobtanksMid')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"slobtanks_sb" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('slobtanksSb')}}</label>
              </div>
            </div>
            <!-- Eind Slobtanks -->

            <!-- Spoelwatertanks -->
            <h1 *ngIf="shipType == handOverFormType.Tanker" class="text-uppercase mb-3">{{ "rinsingWaterTank" | translate }}</h1>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"RinsingWaterTank_bb" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('rinsingWaterTankBb')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"RinsingWaterTank_sb" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('rinsingWaterTankSb')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"RinsingWaterTank_Forepeak" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('rinsingWaterTankPeak')}}</label>
              </div>
            </div>
            <!-- Eind Spoelwatertanks -->

            <!-- ballastBunkers -->
            <h1 class="text-uppercase mb-3">{{ "ballastBunkers" | translate }}</h1>
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "ballast" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('rinsingWaterTankBallast')}}</label>
              </div>
            </div>
            <!-- Eind ballastBunkers -->

            <!-- Bunker -->
            <h1 class="text-uppercase mb-3 mt-4">{{ "bunker" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"voor" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.BunkerFront}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"achter" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.BunkerRear}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"besteld" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.BunkerOrdered}}</label>
              </div>
            </div>

            <!-- TODO: koppelverband -->

            <!-- Eind bunker -->

            <!-- Drinkwater -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "drinkwater" | translate }}
            </h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"voor" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.DrinkWaterFront}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"achter" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.DrinkWaterRear}}</label>
              </div>
            </div>
            <!-- Eind drinkwater -->
          </div>

          <div class="col-lg-6 mb-5">
            <!-- Nautische informatie -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "nautische_informatie" | translate }}
            </h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"nautische_apparatuur" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.NauticalEquipment}}</label>                
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"manoeuvreer_eigenschappen" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.MovementQualities}}</label>
              </div>
            </div>
            <!-- Eind nautische informatie -->

            <!-- Technische informatie -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "technische_informatie" | translate }}
            </h1>
            
            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"tankniveau_apparatuur" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('tankLevelEquipment')}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"pompen" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.Pumps}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"motoren" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.Engines}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"ketel" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('boiler')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"tracing" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('tracing')}}</label>
              </div>
            </div>
            <!-- Eind technische informatie -->

            <!-- Certificaten -->
            <h1 class="text-uppercase mb-3 mt-4">
              {{ "certificaten" | translate }}
            </h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"certificaten_geldig" | translate}}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">                
                <label class="label col-md-6 col-sm-6 col-6">
                  <ion-checkbox
                    disabled="true"
                    name="CertificatesValid"
                    checked="{{handover?.CertificatesValid}}">
                  </ion-checkbox>
                </label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"veiligheidsuitrusting_in_orde" | translate}}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">                
                <label class="label col-md-6 col-sm-6 col-6">
                  <ion-checkbox
                    disabled="true"
                    name="SafetyEquipmentValid"
                    checked="{{handover?.SafetyEquipmentValid}}">
                  </ion-checkbox>
                </label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"opmerkingen" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.Remarks}}</label>
              </div>
            </div>
            <!-- Eind certificaten -->

            <!-- Overig -->
            <h1 class="text-uppercase mb-3 mt-4">{{ "overig" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"materialen_besteld" | translate}}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">
                  
                  <ion-checkbox
                    disabled="true"
                    name="MaterialsOrdered"
                    checked="{{handover?.MaterialsOrdered}}">
                  </ion-checkbox>
                </label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{"bijzonderheden" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{handover?.Particularities}}</label>
              </div>
            </div>
            <!-- Eind overig -->
          </div>
        </div>
        <div class="row">
          <h1 class="text-uppercase mb-3 mt-4 ml-3">{{ "bemanningsWissel" | translate }}</h1>

          <div class="col-12">

            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.Tanker">
              <label class="label col-md-5 col-sm-6 col-12">{{"Crewmatrix_bijgewerkt" | translate}}</label>
              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                
                <ion-checkbox
                  disabled="true"
                  name="crewMatrixUpdated"
                  checked="{{GetFormTypeSpecificValue('crewMatrixUpdated')}}">
                </ion-checkbox>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.DryLoad">
              <label class="label col-md-5 col-sm-6 col-12">{{"onderhoudssyteem_bijgewerkt" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('maintenanceUpdated')}}</label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.DryLoad">
              <label class="label col-md-5 col-sm-6 col-12">{{"reisverslagen_bijgewerkt" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('journeyReportUpdated')}}</label>
              </div>
            </div>
            
            <div class="row align-items-center mb-2 mr-sm-0" *ngIf="shipType == handOverFormType.DryLoad">
              <label class="label col-md-5 col-sm-6 col-12">{{"reparatielijst_bijgewerkt" | translate}}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="label col-md-6 col-sm-6 col-6">{{GetFormTypeSpecificValue('repairListUpdated')}}</label>
              </div>
            </div>

          </div>

          <div class="col-lg-6">
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <h1 class="text-uppercase mb-3 smaller">
                  {{ "handtekening_kapitein_aanboord" | translate }}
                </h1>

                <div class="row align-items-center mb-2 mr-sm-0">
                  <label class="label col-md-5 col-sm-6 col-12">{{"naam_kapitein_aanboord" | translate}}</label>

                  <div class="col-md col-sm-6 col-12 p-sm-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{handover?.NameCaptainOnboard}}</label>
                  </div>
                </div>

                <img [src]="handover?.SignatureCaptainOnboard" />                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <h1 class="text-uppercase mb-3 smaller">
                  {{ "handtekening_kapitein_vanboord" | translate }}
                </h1>

                <div class="row align-items-center mb-2 mr-sm-0">
                  <label class="label col-md-5 col-sm-6 col-12">{{"naam_kapitein_vanboord" | translate}}</label>

                  <div class="col-md col-sm-6 col-12 p-sm-0">
                    <label class="label col-md-6 col-sm-6 col-6">{{handover?.NameCaptainOffboard}}</label>
                  </div>
                </div>

                <img [src]="handover?.SignatureCaptainOffboard" />
              </div>
            </div>
          </div>
        </div>        
      </div>
    
  </div>
</ion-content>