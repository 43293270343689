<home-toolbar [showBackButton]="true" [title]="'reparaties' | translate"></home-toolbar>

<ion-content>
    <div class="container">
        <form class="form-repair" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" novalidate
            autocomplete="off">

            <div class="row align-items-center mb-2 mr-0">
                <label class="label col-5">{{'beschrijving' | translate}}</label>
                <input class="input-text col-7 readonly" type="text" formControlName="description" id="description"
                    name="description" readonly />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'plaats' | translate}}</label>
                <input class="input-text col-7 readonly" type="text" formControlName="explanation" id="explanation"
                    name="explanation" readonly />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'uitvoer_datum' | translate}}</label>
                <input class="input-text col-7 readonly" type="text" formControlName="executeDate" id="executeDate"
                    name="executeDate" [value]="form.get('executeDate')!.value | date: 'dd-MM-yyyy'" readonly />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'contact' | translate}}</label>
                <input class="input-text col-7 readonly" type="text" formControlName="contact" id="contact"
                    name="contact" readonly />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'prioriteit' | translate}}</label>
                <input class="input-text col-7 readonly" type="text" [value]="selectedPriority | repairPriority" id="priority"
                    name="priority" readonly />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'status' | translate}}</label>
                <ion-select class="dropdown" [value]="selectedStatus" (ionChange)="onStatusChanged($event)"
                    interface="popover" [cancelText]="'annuleren' | translate" [okText]="'ok' | translate">
                    <ion-select-option *ngFor="let repairStatus of statusOptions" [value]="repairStatus">{{repairStatus
                        | repairStatus}}</ion-select-option>
                </ion-select>
            </div>

            <div class="mb-3">
                <div class="row">
                    <div class="col-6 pr-0">
                        <button class="btn-delete" expand="full" type="button" [disabled]="!form.valid" (click)="deleteRepairItem()" >{{'verwijderen' | translate}}</button>
                    </div>
                    <div class="col-6">
                        <button class="btn-login" expand="full" type="submit" [disabled]="!form.valid">{{'aanpassen' | translate}}</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</ion-content>
