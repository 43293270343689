import { Injectable } from '@angular/core';
import { IComponentTaskDetailModel } from 'src/app/shared/models/IComponentTaskDetailModel';
import * as moment from "moment";
import { Constants } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class ReadingCalculationService {
  constructor() {}



  nextReadingDate(task: IComponentTaskDetailModel): String {        
    if(task.LastExecutedDate == null)
        task.LastExecutedDate = task.StartDate;

    switch (task.IntervalType) {
        case Constants.IntervalTypes.Once:
            return this.formatDate(task.StartDate);
        case Constants.IntervalTypes.Weekly:
            return this.formatDate(moment(task.LastExecutedDate).add(1, 'weeks').toDate());
        case Constants.IntervalTypes.TwoWeekly:
            return this.formatDate(moment(task.LastExecutedDate).add(2, 'weeks').toDate());
        case Constants.IntervalTypes.Monthly:
            return this.formatDate(moment(task.LastExecutedDate).add(1, 'months').toDate());
        case Constants.IntervalTypes.Yearly:
            return this.formatDate(moment(task.LastExecutedDate).add(1, 'years').toDate());
        default:
            return this.formatDate(task.StartDate);
    }
}

formatDate(date: Date): string {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('-');
}



}