import { ModalController } from '@ionic/angular';
import { Disposable } from './Disposable';

export abstract class BaseModalComponent extends Disposable  {

    constructor(protected modalController: ModalController) {
        super();
    }

    closeModal() {
        this.modalController.dismiss();
    }
}
