import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaintainanceComponent } from './maintainance.component';
import { MaintainanceDetailsComponent } from './maintainance-details/maintainance-details.component';
import { MaintainanceRoutingModule } from './maintainance.routing.module';
import { ComponentTaskHoursComponent } from './maintainance-details/component-task-engine-hours/task-engine-hours.component';
import { ComponentTaskTimeComponent } from './maintainance-details/component-task-time/task-engine-time.component';
import { TaskStatusComponent } from './maintainance-details/TaskStatusComponent/taskStatus.component';

@NgModule({
    imports: [
        SharedModule,
        MaintainanceRoutingModule
    ],
    declarations: [
        MaintainanceComponent,
        MaintainanceDetailsComponent,
        ComponentTaskHoursComponent,
        ComponentTaskTimeComponent,
        TaskStatusComponent
    ]
})
export class MaintainanceModule { }
