import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private loadingController: LoadingController,
        private http: HttpClient) {
    }

    async get<T>(url: string, showLoader = true) {
        let loader;
        if (showLoader) {
            loader = await this.loadingController.create();
            await loader.present();
        }

        return this.http.get<T>(url).pipe(this.dismissLoader(loader, showLoader));
    }

    async post<T>(url: string, body: any, showLoader = true, options: {} = {}) {
        let loader;
        if (showLoader) {
            loader = await this.loadingController.create();
            await loader.present();
        }

        return this.http.post<T>(url, body, options).pipe(this.dismissLoader<T>(loader, showLoader));
    }

    async put<T>(url: string, body: any, showLoader = true) {
        let loader;
        if (showLoader) {
            loader = await this.loadingController.create();
            await loader.present();
        }

        return this.http.put<T>(url, body).pipe(this.dismissLoader<T>(loader, showLoader));
    }

    async delete<T>(url: string, showLoader = true) {
        let loader;
        if (showLoader) {
            loader = await this.loadingController.create();
            await loader.present();
        }

        return this.http.delete<T>(url).pipe(this.dismissLoader<T>(loader, showLoader));
    }

    async patch<T>(url: string, body: any, showLoader = true) {
        let loader;
        if (showLoader) {
            loader = await this.loadingController.create();
            await loader.present();
        }

        return this.http.patch<T>(url, body).pipe(this.dismissLoader<T>(loader, showLoader));
    }

    private dismissLoader<T>(loader: any, showLoader: boolean) {
        return finalize<T>(async () => {
            if (showLoader)
                await loader.dismiss();
        });
    }
}
