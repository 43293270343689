import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavController, AlertController } from '@ionic/angular';
import { AuthenticationService } from '../../services/authentication.service';
import { ILoginModel } from 'src/app/shared/models/LoginModel';
import { BaseFormComponent } from 'src/app/shared/classes/BaseFormComponent';
import { TranslateService } from '@ngx-translate/core';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseFormComponent implements OnInit {

    constructor(navController: NavController,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private alertController: AlertController,
        private appSettings: LocalAppSettingsService) {
        super(navController);

        
        if(appSettings.userId != null && appSettings.userId > 0){
            this.navController.navigateForward("/home");
        }
    }

    ngOnInit() {
        this.form = new FormGroup({
            username: new FormControl('', [Validators.required, Validators.maxLength(60)]),
            password: new FormControl('', [Validators.required, Validators.maxLength(60)]),
            rememberMe: new FormControl(false, [])
        });
    }

    async submit(model: ILoginModel) {
        try {
            const loginResult = await this.authenticationService.login(model.username, model.password);
            this.appSettings.rememberMe = model.rememberMe;
            this.authenticationService.saveAccessTokens(loginResult);
            this.form.reset();
            this.navController.navigateForward("/home");
        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('error'),
                message: this.translateService.instant('ongeldige_gebruikersnaam_of_wachtwoord'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }
    }
}
