<home-toolbar [showBackButton]="true" [title]="'certificaten' | translate"></home-toolbar>

<ion-content>
    <div class="container">
        <div class="home-tiles">

            <span class="certificate-title">{{'schip_certificaten' | translate}}</span>
            <div class="row mb-2">
                <div class="col-6 col-md-3 mb-2 ion-activatable certificate-tile expired" [ngClass]="checkExpiration(certificate)" *ngFor="let certificate of shipCertificates" (click)="showCertificate(certificate)">
                    <ion-ripple-effect></ion-ripple-effect>
                    <div class="tile-title"> {{certificate.Name}} </div>
                    <div class="tile-details">
                        {{'geldig_tot' | translate }}: {{certificate.ValidUntil | date:'dd-MM-yyyy'}} <br />
                        {{'aantal_reddingsvesten' | translate}}: {{certificate.LifeJacketsCount}}
                    </div>
                </div>
            </div>

            <span *ngIf="certificates == null || certificates.length > 0" class="certificate-title">{{'bak_certificaten' | translate}}</span>
            <div class="row mb-2">
                <div class="col-6 col-md-3 mb-2 ion-activatable certificate-tile expired" [ngClass]="checkExpiration(certificate)" *ngFor="let certificate of certificates" (click)="showCertificate(certificate)">
                    <ion-ripple-effect></ion-ripple-effect>
                    <div class="tile-title"> {{certificate.Name}} </div>
                    <div class="tile-details">
                        {{'geldig_tot' | translate }}: {{certificate.ValidUntil | date:'dd-MM-yyyy'}} <br />
                        {{'aantal_reddingsvesten' | translate}}: {{certificate.LifeJacketsCount}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>
