import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { IFileUploadModel, ITemporaryFile } from '../shared/models/FileUploadModel';
import { flatMap, mergeMap } from 'rxjs/operators';
import { ImageModel } from '../shared/models/ImageModel';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private http: HttpService) { }

    async uploadTemporaryFile(model: IFileUploadModel): Promise<ImageModel> {
        const http$ = await this.http.post<ITemporaryFile>(`${environment.baseUrl}/api/File/TemporaryUpload`, this.toFormData(model));
        return firstValueFrom(http$.pipe(
            mergeMap(async result => {
                return { Image: result.TemporaryFile } as ImageModel
            })
        ));
    }

    private toFormData(model: IFileUploadModel): FormData {
        let formData = new FormData();

        formData.append("Serenity_ImageUploadEditor29[]", model.file);

        return formData;
    }
}
