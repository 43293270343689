import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from "../../../environments/environment";
import { ISerenityListResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { Constants } from 'src/app/constants';
import { catchError, map } from 'rxjs/operators';
import { OfflineEventService } from 'src/app/services/offline-event.service';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { firstValueFrom, throwError } from 'rxjs';
import { Observable} from 'rxjs';
import { IShip } from 'src/app/shared/models/Ship';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ChooseShipService {

    constructor(private http: HttpService,
        private appSettings: LocalAppSettingsService,
        private storage: StorageService,
        private networkService: NetworkService,
        private offlineEventService: OfflineEventService) { 

        }


    async retrieve(take = 100, includeColumns?: string[]) {
        const http$ = await this.http.post<ISerenityListResponse<IShip>>(`${environment.baseUrl}/api/Ship/List`, { take, includeColumns });
        return firstValueFrom(http$
            .pipe(
                map((result:ISerenityListResponse<IShip>) => {
                    return result.Entities;
                }),
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        const reports = await this.storage.get<IShip[]>(Constants.sqlStorageKeys.journeyReports);
                        return reports;
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };

    setShipData(ship: IShip) {
        this.appSettings.ship = ship;
    }

}
