import { Component, OnInit, Input } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { AuthenticationService } from '../services/authentication.service';
import { BaseComponent } from '../shared/classes/BaseComponent';
import { Router } from '@angular/router';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss'],
})
export class AppMenuComponent extends BaseComponent implements OnInit {
    showJourneyreportMenu = false;
    showWorkOrderMenu = false;
    showhandOverMenu = false;

    constructor(navController: NavController,
        public router: Router,
        private menu: MenuController,
        private authenticationService: AuthenticationService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    ngOnInit() {}

    navigateToRoute(route: string) {
        if (route === "/home") {
            this.navigateRoot(route);
        }
        else {
            this.navigateForward(route);
        }
        this.menu.close();
    }

    logout() {
        this.authenticationService.logout();
        this.navigateRoot('/account/login');
        this.menu.close();
        this.appSettings.currentJourneyReport = null;


    }
}
