import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Disposable } from '../../../shared/classes/Disposable';
import { HandOverService } from '../handover.service';
import { IHandOverEntity } from 'src/app/shared/models/HandOver';
import { HandOverFormType } from "src/app/shared/classes/HandOverFormType";
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';

@Component({
    selector: 'app-handover-detail',
    templateUrl: './handover-detail.component.html',
    styleUrls: ['./handover-detail.component.scss'],
})
export class HandOverDetailComponent extends Disposable implements OnInit {

    handover?: IHandOverEntity;
    shipType: HandOverFormType = HandOverFormType.DryLoad;

    constructor(private route: ActivatedRoute, 
        private appSettings: LocalAppSettingsService,
        private handOverService: HandOverService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(this.route.paramMap.subscribe(async params => {
            const id = params.get("id")!;
            
            this.handover = await this.handOverService.retrieve(id);
            this.shipType = this.appSettings.ship.HandOverFormType;
        }));
    }

    GetFormTypeSpecificValue(key: string){
        let formTypeSpecificFields = this.handover?.FormTypeSpecificFields ?? ''
        
        if(formTypeSpecificFields == '') return

        let formTypeSpecificJson = JSON.parse(formTypeSpecificFields)
        let formTypeSpecificValue = formTypeSpecificJson[key]

        return formTypeSpecificValue;
    }

    public get handOverFormType(): typeof HandOverFormType {
        return HandOverFormType; 
    }
}
