import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ISerenityListResponse } from '../shared/models/serenity/SerenityResponse';
import { catchError, mergeMap } from 'rxjs/operators';
import { Constants } from '../constants';
import { NetworkService, ConnectionStatus } from './network.service';
import { firstValueFrom, throwError } from 'rxjs';
import { ITask } from '../shared/models/Task';
import { StorageService } from './storage.service';
import { IReadingFormModel } from 'src/app/shared/models/IReadingFormModel'
import { ISerenitySaveResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { OfflineEventService } from 'src/app/services/offline-event.service';
import { IComponentReadingForm } from 'src/app/shared/models/IComponentReadingForm';
import { IComponentTaskTimeForm } from 'src/app/shared/models/IComponentTaskTimeForm'

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    constructor(private http: HttpService,
        private storage: StorageService,
        private offlineEventService: OfflineEventService,
        private networkService: NetworkService) { }

    async list(shipId: number, take = 100, containsText?: string, includeColumns?: string[]): Promise<ITask[]> {
        const http$ = await this.http.post<ISerenityListResponse<ITask>>(`${environment.baseUrl}/api/task/List`, {shipId, take, containsText, includeColumns});

        return firstValueFrom(http$
            .pipe(mergeMap(async (result) => {
                await this.storage.set(Constants.sqlStorageKeys.tasks, result.Entities);
                return result.Entities;
            }))
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        return await this.storage.get<ITask[]>(Constants.sqlStorageKeys.tasks);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    }
    
    async postTaskHourReading(componentTaskReading: IReadingFormModel) {
        const url = `${environment.baseUrl}/api/ShipZoneComponent/CreateHourTaskReading`;

        const http$ = await this.http.post<ISerenitySaveResponse>(url, componentTaskReading);
        return firstValueFrom(http$
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        await this.offlineEventService.storeRequest(url, "post", componentTaskReading);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    }
    
    async postComponentReading(componentReading: IComponentReadingForm) {
        const url = `${environment.baseUrl}/api/ShipZoneComponent/CreateComponentReading`;

        const http$ = await this.http.post<ISerenitySaveResponse>(url, componentReading);
        return firstValueFrom(http$
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        await this.offlineEventService.storeRequest(url, "post", componentReading);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    }

    async postTaskTimeJob(componentTaskReading: IComponentTaskTimeForm) {
        const url = `${environment.baseUrl}/api/ShipZoneComponent/CreateTimeTaskReading`;

        const http$ = await this.http.post<ISerenitySaveResponse>(url, componentTaskReading);
        return firstValueFrom(http$
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        await this.offlineEventService.storeRequest(url, "post", componentTaskReading);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    }

}