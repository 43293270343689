import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../../../shared/classes/BaseFormComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RepairStatus } from '../../../shared/classes/RepairStatus';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { RepairService } from '../repair.service';
import { IRepairListModel, IRepair } from 'src/app/shared/models/Repair';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { RepairPriority } from 'src/app/shared/models/RepairPriority';

@Component({
    selector: 'edit-repair',
    templateUrl: './edit-repair.component.html',
    styleUrls: ['./edit-repair.component.scss', '../repairs.component.scss'],
})
export class EditRepairComponent extends BaseFormComponent implements OnInit {

    repair?: IRepair;
    statusOptions?: any[];
    selectedStatus: RepairStatus = RepairStatus.Open;
    selectedPriority!: RepairPriority;

    constructor(navController: NavController,
        private route: ActivatedRoute,
        private alertController: AlertController,
        private translateService: TranslateService,
        private repairService: RepairService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    ngOnInit() {
        const repairId = this.route.snapshot.paramMap.get("id");
        if (repairId == null)
            return;

        this.form = new FormGroup({
            description: new FormControl('', [Validators.required]),
            explanation: new FormControl('', [Validators.required]),
            status: new FormControl('', [Validators.required]),
            executeDate: new FormControl('', []),
            contact: new FormControl('', []),
        });

        this.setRepairStatusOptions();

        this.repairService.retrieve(repairId).then((repair) => {
            if (!repair)
                return;

            this.repair = repair;

            this.form.controls['description'].setValue(repair.Description);
            this.form.controls['explanation'].setValue(repair.ExecutiveName);
            this.form.controls['status'].setValue(repair.Status);
            this.form.controls['executeDate'].setValue(repair.ExecuteDate);
            this.form.controls['contact'].setValue(repair.Contact);

            this.selectedStatus = repair.Status;
            this.selectedPriority = repair.Priority;
        });
    }

    onStatusChanged(event: CustomEvent) {
        const selectedStatus = event.detail.value;
        this.selectedStatus = selectedStatus;
        this.form.controls['status'].setValue(selectedStatus);
    }

    private setRepairStatusOptions() {
        const keys = Object.keys(RepairStatus).filter(k => typeof RepairStatus[k as any] === "number");
        this.statusOptions = keys.map(k => RepairStatus[k as any]);
    }

    async submit(value: IRepairListModel) {
        if (!this.repair)
            return;

        value.executiveName = this.appSettings.username;

        try {
            await this.disableFormDuringApiCall(() => this.repairService.update(this.repair!, value));

            const alert = await this.alertController.create({
                header: this.translateService.instant('reparaties'),
                message: this.translateService.instant('reparatie_aangepast'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
            this.repair.Status = this.selectedStatus;
            this.navController.navigateBack("/home/repairs");
        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('error'),
                message: this.translateService.instant('error_aanpassen_reparatie'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }
    }

    async deleteRepairItem() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('reparatie_verwijderen'),
            message: this.translateService.instant('repair_item_verwijderen_bevestiging'),
            buttons: [
                {
                    text: this.translateService.instant('annuleren'),
                    role: 'cancel'
                },
                {
                    text: this.translateService.instant('ok'),
                    handler: () => {
                        this.deleteRepair();
                    }
                }
            ]
        });

        alert.present();
    }

    private async deleteRepair() {
        try {
            await this.repairService.delete(this.repair!.Id);

            const alert = await this.alertController.create({
                header: this.translateService.instant('reparaties'),
                message: this.translateService.instant('reparatie_verwijderd'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
            this.navController.navigateBack("/home/repairs");

        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('error'),
                message: this.translateService.instant('error_verwijderen_reparatie'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }
    }
}
