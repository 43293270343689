import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FileChangeEvent } from '../../classes/FileChangeEvent';
import { PopoverController } from '@ionic/angular';
import { AddImageComponent } from './add-image/add-image.component';
import { Photo } from '@capacitor/camera';

@Component({
    selector: 'upload-picture',
    templateUrl: './picture-upload.component.html',
    styleUrls: ['./picture-upload.component.scss'],
})
export class PictureUploadComponent implements OnInit {

    constructor(public popoverController: PopoverController) { }

    @Input() index?: number;
    @Output() pictureChanged = new EventEmitter<FileChangeEvent>();

    image?: string | null;
    placeholderImagePath = "/assets/picture-placeholder.png";

    ngOnInit() { }

    async addPicture(event: MouseEvent) {
        const popover = await this.popoverController.create({
            component: AddImageComponent,
            event: event,
            translucent: true,
            mode: "md"
        });

        popover.onDidDismiss<Photo>().then(event => {
            if (!!event?.data?.dataUrl) {
                this.image = event.data.dataUrl;
                console.log('picture added', event)
                var file = this.dataURItoBlob(event.data.dataUrl);
                this.pictureChanged.emit(new FileChangeEvent(file, this.index));
            }
        });

        await popover.present();
    }

    dataURItoBlob(dataURI: string) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([ia], {type:mimeString});
    }
    
    deletePicture(event: MouseEvent) {
        event.preventDefault();
        this.image = null;
        this.pictureChanged.emit(new FileChangeEvent(null, this.index));
    }
}
