import { NgModule } from '@angular/core';
import { AccountRoutingModule } from './account.routing.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [LoginComponent, ForgotPasswordComponent],
    imports: [
        SharedModule,
        AccountRoutingModule
    ]
})
export class AccountPageModule { }
