import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './home.page';
import { RepairsComponent } from './repairs/repairs.component';
import { AddRepairComponent } from './repairs/add-repair/add-repair.component';
import { EditRepairComponent } from './repairs/edit-repair/edit-repair.component';
import { JourneyReportsComponent } from './journey-reports/journey-reports.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { DeclarationsComponent } from './declarations/declarations.component';
import { LiveLocationsComponent } from './live-locations/live-locations.component';
import { PlanningComponent } from './planning/planning.component';
import { AddJourneyReportComponent } from './journey-reports/add-journey-report/add-journey-report.component';
import { JourneyReportDetailComponent } from './journey-reports/journey-report-detail/journey-report-detail.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { InspectionReportsComponent } from './inspection-reports/inspection-reports.component';
import { ChooseShipComponent } from './choose-ship/choose-ship.component';
import { AuthGuard } from '../guards/auth.guard';
import { MaintainanceModule } from './maintainance/maintainance.module';
import { WorkOrdersComponent } from './work-order/work-orders.component';
import { AddWorkOrderComponent } from './work-order/add-work-order/add-work-order.component';
import { WorkOrderDetailComponent } from './work-order/work-order-detail/work-order-detail.component';
import { HandOverComponent } from './handover/handover.component';
import { AddHandOverComponent } from './handover/add-handover/add-handover.component';
import { HandOverDetailComponent } from './handover/handover-detail/handover-detail.component';

const routes: Routes = [
    { path: '', component: HomePage, canActivate: [AuthGuard]},
    { path: 'journey-reports', component: JourneyReportsComponent, canActivate: [AuthGuard] },
    { path: 'add-journey-report', component: AddJourneyReportComponent, canActivate: [AuthGuard] },
    { path: 'journey-report/:id', component: JourneyReportDetailComponent, canActivate: [AuthGuard] },
    { path: 'certificates', component: CertificatesComponent, canActivate: [AuthGuard] },
    { path: 'declarations', component: DeclarationsComponent, canActivate: [AuthGuard] },
    { path: 'repairs', component: RepairsComponent, canActivate: [AuthGuard] },
    { path: 'repairs/add', component: AddRepairComponent, canActivate: [AuthGuard] },
    { path: 'repairs/edit/:id', component: EditRepairComponent, canActivate: [AuthGuard] },
    { path: 'live-locations', component: LiveLocationsComponent, canActivate: [AuthGuard] },
    { path: 'planning', component: PlanningComponent, canActivate: [AuthGuard] },
    { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard] },
    { path: 'choose-ship', component: ChooseShipComponent, canActivate: [AuthGuard] },
    { path: 'inspection-reports', component: InspectionReportsComponent, canActivate: [AuthGuard] },
    { path: 'maintainance', loadChildren: () => MaintainanceModule, canActivate: [AuthGuard] },
    { path: 'work-orders', component: WorkOrdersComponent, canActivate: [AuthGuard] },
    { path: 'add-work-order', component: AddWorkOrderComponent, canActivate: [AuthGuard] },
    { path: 'work-order/:id', component: WorkOrderDetailComponent, canActivate: [AuthGuard] },
    { path: 'handovers', component: HandOverComponent, canActivate: [AuthGuard] },
    { path: 'add-handover', component: AddHandOverComponent, canActivate: [AuthGuard] },
    { path: 'handover/:id', component: HandOverDetailComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule { }
