import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintainanceComponent } from './maintainance.component';
import { MaintainanceDetailsComponent } from './maintainance-details/maintainance-details.component';
import { AuthGuard } from 'src/app/guards/auth.guard';


const routes: Routes = [
    { path: '', component: MaintainanceComponent, canActivate: [AuthGuard] },
    { path: 'details/:id', component: MaintainanceDetailsComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MaintainanceRoutingModule { }
