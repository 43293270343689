import {
  Component,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { LiveLocationService } from 'src/app/home/live-locations/live-location.service';
import { BaseComponent } from 'src/app/shared/classes/BaseComponent';
import { NavController, Platform } from '@ionic/angular';
import { ILocationMarker } from 'src/app/shared/models/LocationMarker';
import { HttpClient } from '@angular/common/http';
import { GoogleMap, Marker } from '@capacitor/google-maps';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-live-locations',
  templateUrl: './live-locations.component.html',
  styleUrls: ['./live-locations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveLocationsComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('map') mapElement!: ElementRef<HTMLElement>;

  markers: ILocationMarker[] = [];
  map!: GoogleMap;
  icon = {
    url: 'assets/icon/marker-icon.png',
    size: {
      width: 32,
      height: 26,
    },
  };
  interval: any;

  constructor(
    navController: NavController,
    private liveLocationService: LiveLocationService,
    private platform: Platform,
    private httpClient: HttpClient
  ) {
    super(navController);
    this.markers = [];
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
      setTimeout(async () => await this.initMap(), this.platform.is('android') ? 300 : 0); //android has a page transition which affects the height of the page
    });
    this.interval = setInterval(() => this.refreshLocations(), 30000);
  }

  async initMap() {
    if (this.mapElement) {
      this.map = await GoogleMap.create({
        element: this.mapElement.nativeElement,
        apiKey: this.platform.is('ios')
          ? environment.googleMapsApi.ios
          : this.platform.is('android')
          ? environment.googleMapsApi.android
          : environment.googleMapsApi.web,
        id: 'live-locations-map',
        config: {
          center: {
            lat: 51.837548,
            lng: 4.623037,
          },
          zoom: 7,
        },
      });

      this.refreshLocations();
    }
  }

  refreshLocations() {
    this.liveLocationService
      .list(100, '', ['ShipName', 'ShipCompanyId'], false)
      .then(async (result) => {
        for (let location of result) {
          let existingLocation = this.markers.filter(
            (x) => x.location?.ShipId == location.ShipId
          )[0];
          if (existingLocation) {
            const newCoordinates = {
              lat: parseFloat(location.Latitude),
              lng: parseFloat(location.Longitude),
            };
            
            if (newCoordinates.lat == existingLocation.marker.coordinate.lat && newCoordinates.lng == existingLocation.marker.coordinate.lng)
              continue;

            existingLocation.marker.coordinate = newCoordinates;

            if (existingLocation.markerId != undefined) {
              await this.map.removeMarker(existingLocation.markerId);
            }

            existingLocation.markerId = await this.map.addMarker(
              existingLocation.marker
            );
          } else {
            let markerOptions: Marker = {
              coordinate: {
                lat: parseFloat(location.Latitude),
                lng: parseFloat(location.Longitude),
              },
              title: location.ShipName,
              iconUrl: this.icon.url,
              iconSize: this.icon.size
            };

            let marker: ILocationMarker = {
              marker: markerOptions,
              markerId: await this.map.addMarker(markerOptions),
              location: location,
            };

            this.markers.push(marker);
          }
        }
      });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.map?.destroy();
    if (this.interval) clearInterval(this.interval);
  }
}
