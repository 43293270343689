<ion-header>
    <ion-toolbar>
        <ion-title>{{'bedrijf' | translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button icon-only (click)="closeModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-searchbar [placeholder]="'zoek_bedrijven' | translate" debounce="600" (ionChange)="onSearchTermChanged($event)"></ion-searchbar>

    <ion-list>
        <ion-item *ngFor="let item of items" (click)="onItemSelected(item)">
            {{item.Name}}
        </ion-item>
    </ion-list>

    <ion-fab [hidden]="!isOnline" vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button (click)="openAddNewItemModal()">
            <ion-icon name="add"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</ion-content>
