import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable()
export class Disposable implements OnDestroy {
    protected subscriptions: Subscription[] = [];

    ngOnDestroy() {
        this.dispose();
    }

    protected dispose() {
        for(let subscription of this.subscriptions) {
            if (subscription)
                subscription.unsubscribe();
        }
    }
}