<home-toolbar [showBackButton]="true" [title]="'choose_ship' | translate"></home-toolbar>

<ion-content>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <div class="container">
        <div class="home-tiles">
            <div class="row justify-content-xs-between justify-content-md-start mb-2">

                <table class="table table-fc">
                    <thead>
                        <tr>
                            <th>{{'name' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ship of ships" (click)="SwitchShip(ship)">
                            <td>{{ship.Name}}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>

</ion-content>
