<home-toolbar [showBackButton]="true" [title]="'onderhoud' | translate"></home-toolbar>

<ion-content>
    <div class="header d-none d-md-block" [ngStyle]="{ 'background-image': 'url(' + layoutImage + ')'}"></div>

    <div class="container components">
        <h1>{{shipName}}</h1>
        <div (click)="printMode=!printMode" class="btn-print">{{'print' | translate}}</div>
        <ion-accordion-group class="component-overview" [multiple]="true" [value]="[getFirstZoneName()]">
            <ion-accordion value="{{zone.ZoneName}}" *ngFor="let zone of zoneComponents" class="component">
                <ion-item slot="header" color="fleet">
                    <ion-checkbox class="ion-checkbox" *ngIf="printMode" [(ngModel)]="zone.Checked" name="crewMatrixUpdated" (click)="stopPropagation($event)" (ionChange)="zoneSelected(zone)"></ion-checkbox>
                    <taskStatus-component [status]="zone.Status"></taskStatus-component>
                    <div class="zone-title">{{zone.ZoneName}}</div>
                </ion-item>
                
                <div class="ion-padding" slot="content">
                    <div *ngFor="let component of zone.Components" class="component-item">
                        <ion-checkbox class="ion-checkbox" *ngIf="printMode" class="cb_print" (ionChange)="taskSelected($event, zone)" [(ngModel)]="component.Checked" name="crewMatrixUpdated"></ion-checkbox>
                        <taskStatus-component (click)="navigateToComponentDetails(component.ComponentId)" [status]="component.Status"></taskStatus-component>
                        <div (click)="navigateToComponentDetails(component.ComponentId)" class="component-title">{{component.ComponentName}}</div>
                        <ion-icon (click)="navigateToComponentDetails(component.ComponentId)" name="chevron-forward-outline"></ion-icon>
                    </div>
                </div>
            </ion-accordion>
      </ion-accordion-group>
    </div>

    <button class="btn-login" *ngIf="printMode" (click)="startPrint()">
        {{ "printen" | translate }}
    </button>

    <div *ngIf="showPrintPopup" class="printPopup" #printpop style="position: absolute; top: 60px; left: 0px; width: 100vw; height: 100vh; background: #FFF; align-self: start; overflow: auto; padding-bottom: 100px;">
        <h1 style="margin: 20px 0px 0px 30px;">{{shipName}}</h1>

        <div style="margin-left: 3rem !important; margin-top: 0.5rem !important; align-self: start;">
            <table padding="0" cellpadding="0" style="width: 100%;">
                <tr>
                    <td width="20">&nbsp;</td>
                    <td width="40%" style="padding-right: 15px;padding-left: 15px;">
                        &nbsp;
                    </td>
                    <td width="20%" style="padding-right: 15px; padding-left: 15px;">
                        {{"datum" | translate}}
                    </td>
                    <td style="padding-right: 15px; padding-left: 15px;">
                        {{"opmerking" | translate}}
                    </td>
                </tr>
            </table>
        </div>

        <div *ngFor="let printZone of getSelectedZones()" class="component-item" style="width: 100%; top: 0px;">
            <div class="print-component-title" style="margin-top: 20px; font-weight: bold; margin-bottom: 20px; display: block;">{{printZone.ZoneName}}</div>
            
            <div *ngFor="let printComponent of getSelectedComponents(printZone.Components)" style="margin-left: 3rem !important; margin-top: 0.5rem !important;">
                <div class="print-component-title" style="margin-left: -20px; font-weight: bold; margin-bottom: 20px; display: block;">{{printComponent.ComponentName}}</div>

                <table padding="0" cellpadding="0" style="width: 100%;">
                    <tr *ngFor="let printTask of getComponentTasksByComponentId(printComponent.ComponentId)">
                        <td width="20">
                            <input type="checkbox" style="appearance: checkbox;height: 18px;width: 18px; box-sizing: border-box; padding: 0;">
                        </td>
                        <td width="40%" style="padding-right: 15px;padding-left: 15px;">
                            <div>{{printTask.Name}}</div>
                        </td>
                        <td width="20%" style="padding-right: 15px; padding-left: 15px; border-bottom: 1px #000 dotted;">
                            
                        </td>
                        <td style="padding-right: 15px; padding-left: 15px; border-bottom: 1px #000 dotted;">
                            
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>

</ion-content>