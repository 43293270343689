import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ISettings } from '../shared/models/Settings';
import { catchError, map } from 'rxjs/operators';
import { Constants } from 'src/app/constants';
import { Platform } from '@ionic/angular';
import { Subscription, firstValueFrom } from 'rxjs';
import { FcmService } from './fcm.service';
import { LocalAppSettingsService } from './local-app-settings.service';
import { Haptics } from '@capacitor/haptics';
import { StorageService } from './storage.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { IShip } from '../shared/models/Ship';

@Injectable({
  providedIn: 'root',
})
export class SettingsService implements OnDestroy {
  constructor(
    private http: HttpService,
    private storage: StorageService,
    private platform: Platform,
    private fcm: FcmService,
    private appSettings: LocalAppSettingsService
  ) {}

  notificationSubscription?: Subscription;

  async getSettings(ship: IShip | null) {
    let shipId = ship?.Id;

    const http$ = await this.http.post<ISettings>(
      `${environment.baseUrl}/api/Settings/GetSettingsByShip`,
      { shipId: shipId }
    );
    return firstValueFrom(
      http$
        .pipe(
          map(async (result: ISettings) => {
            if (typeof result.Ship.ShipImage !== 'undefined') {
              result.Ship.ShipImage = `${environment.baseUrl}/upload/${result.Ship.ShipImage}`;
            } else {
              result.Ship.ShipImage = '/assets/home-page-ship.png';
            }

            await this.storage.set(Constants.sqlStorageKeys.settings, result);
            return result;
          })
        )
        .pipe(
          catchError(
            async () =>
              await this.storage.get<ISettings>(
                Constants.sqlStorageKeys.settings
              )
          )
        )
    );
  }

  async enablePushNotifications() {
    if (!this.platform.is('ios') && !this.platform.is('android')) return;

    let hasPermission = await PushNotifications.checkPermissions();
    if (hasPermission.receive != 'granted') {
      // no permission yet, asking permission now
      hasPermission = await PushNotifications.requestPermissions();
      this.appSettings.pushNotificationsEnabled = true;
    }

    this.notificationSubscription = this.fcm.onNotificationRecieved.subscribe(
      () => {
        Haptics.vibrate({ duration: 1000 });
      }
    );
  }

  ngOnDestroy() {
    this.notificationSubscription?.unsubscribe();
  }
}
