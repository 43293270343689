import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ILoginResult } from '../shared/models/LoginModel';
import { LocalAppSettingsService } from './local-app-settings.service';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { IForgotPasswordModel } from '../shared/models/ForgotPassword';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private readonly isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpService,
        private appSettings: LocalAppSettingsService) { }

    async login(username: string, password: string) {
        const http$ = await this.http.post<ILoginResult>(`${environment.baseUrl}/api/token/gettoken`, { username, password });
        return firstValueFrom(http$.pipe(map((result) => {
            if (result.token.length > 0)
                this.isAuthenticatedSubject.next(true);
            return result;
        })));
    }

    async refresh() {
        const refreshToken = this.appSettings.refreshToken;
        const http$ = await this.http.post<ILoginResult>(`${environment.baseUrl}/api/token/refresh`, { refreshToken }, false);
        return firstValueFrom(http$.pipe(map((result) => {
            if (result.token.length > 0)
                this.isAuthenticatedSubject.next(true);
            return result;
        })));
    }

    async forgotPassword(model: IForgotPasswordModel) {
        const http$ = await this.http.post<void>(`${environment.baseUrl}/api/Password/forgot`, model);
        return firstValueFrom(http$.pipe(map((result) => result)));
    }

    logout() {
        this.isAuthenticatedSubject.next(false);
        this.appSettings.logout();
    }

    saveAccessTokens(model: ILoginResult) {
        this.appSettings.accessToken = model.token;
        this.appSettings.refreshToken = model.refreshToken;
        this.appSettings.accessTokenExpiresAt = model.expiresAt;
    }

    getAccessToken() {
        return this.appSettings.accessToken;
    }

    isAuthenticated() {
        return this.isAuthenticatedSubject.asObservable();
    }

    hasValidAccessToken() {
        const expiresAt = new Date(this.appSettings.accessTokenExpiresAt);
        const now = new Date();

        return expiresAt > now;
    }
}
