<home-toolbar [showBackButton]="true" [title]="'mijn_account' | translate"></home-toolbar>

<ion-content>
    <div class="container">

        <div class="row">
            <div class="col-12 col-md-6 ">
                <h1 class="text-uppercase mb-3"> {{ 'mijn_account' | translate }}</h1>
                <div class="row align-items-center mb-3 mr-0">
                    <label class="label col-md-5 col-sm-5 col-6">{{'gebruikersnaam' | translate}}</label>
                    <span class="col-md-7 col-sm-5 col-6">{{username}}</span>
                </div>
                <!-- Hide for now because it does not work.  -->
                <div class="row align-items-center mb-3 mr-0">
                    <label class="label col-md-5 col-sm-5 col-6">{{'push_notificaties' | translate}}</label>
                    <span class="col-md-7 col-sm-5 col-6">
                        <ion-toggle mode="md" [checked]="pushEnabled" (ionChange)="onNotificationToggleChange($event)"></ion-toggle>
                    </span>
                </div>
            </div>
            <div [hidden]="!isOnline" class="col-12 col-md-6 ">
                <h1 class="text-uppercase mb-3"> {{ 'wachtwoord_veranderen' | translate }}</h1>

                <form class="mb-3" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)">

                    <div class="row align-items-center mb-2 mr-0">
                        <label class="label col-md-5 col-sm-5 col-6">{{'wachtwoord' | translate}}</label>
                        <input class="input-text col-md-7 col-sm-5 col-6"
                            [class.input-error]="hasError('password', 'minLength') || hasFormError('passwords-not-equal')"
                            type="password" formControlName="password" [placeholder]="'wachtwoord' | translate" />
                    </div>
                    <div class="row align-items-center mb-3 mr-0">
                        <label class="label col-md-5 col-sm-5 col-6">{{'herhaal_wachtwoord' | translate}}</label>
                        <input class="input-text col-md-7 col-sm-5 col-6"
                            [class.input-error]="hasError('passwordRepeat', 'minLength') || hasFormError('passwords-not-equal')"
                            type="password" formControlName="passwordRepeat"
                            [placeholder]="'herhaal_wachtwoord' | translate" />
                    </div>
                    <button [disabled]="!form.valid || isSaving" class="btn-login"
                        type="submit">{{'wachtwoord_veranderen' | translate }}</button>
                </form>
            </div>
        </div>
    </div>
</ion-content>
