import { Pipe, PipeTransform } from '@angular/core';
import { RepairStatus } from '../classes/RepairStatus';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'repairStatus'
})
export class RepairStatusPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(status: RepairStatus, args?: any): any {
        switch (status) {
            case RepairStatus.Open:
                return this.translateService.instant("repairstatus_open");
            case RepairStatus.InProgress:
                return this.translateService.instant("repairstatus_inprogress");
            case RepairStatus.Finished:
                return this.translateService.instant("repairstatus_finished");
            case RepairStatus.Closed:
                return this.translateService.instant("repairstatus_closed");
            case RepairStatus.Rejected:
                return this.translateService.instant("repairstatus_rejected");
        }

        return this.translateService.instant("repairstatus_open");
    }
}
