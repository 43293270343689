import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authenticationService.isAuthenticated().pipe(
            mergeMap(async () => {
                if(!this.authenticationService.hasValidAccessToken()) {
                    try {
                        const result = await this.authenticationService.refresh();
                        this.authenticationService.saveAccessTokens(result);
                        return true;
                    } catch (exception) {
                        debugger;
                        //this.authenticationService.logout();
                    }
                } else {
                    return true;
                }

                return false;
            })
        )
    }
}
