import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JourneyReportService } from '../journey-report.service';
import { IJourneyReportEntity } from 'src/app/shared/models/JourneyReport';
import { Disposable } from '../../../shared/classes/Disposable';

@Component({
    selector: 'app-journey-report-detail',
    templateUrl: './journey-report-detail.component.html',
    styleUrls: ['./journey-report-detail.component.scss'],
})
export class JourneyReportDetailComponent extends Disposable implements OnInit {

    journeyReport?: IJourneyReportEntity;

    constructor(private route: ActivatedRoute, private journeyReportService: JourneyReportService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(this.route.paramMap.subscribe(async params => {
            const journeyReportId = params.get("id")!;
            
            this.journeyReport = await this.journeyReportService.retrieve(journeyReportId);
        }));
    }
}
