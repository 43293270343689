import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { TranslateModule } from '@ngx-translate/core';
import { RepairStatusPipe } from './pipes/repair-status.pipe';
import { PictureUploadComponent } from './components/picture-upload/picture-upload.component';
import { AddPortModalComponent } from './components/port-auto-complete-modal/add-port-modal/add-port-modal.component';
import { CargoAutoCompleteModalComponent } from './components/cargo-auto-complete-modal/cargo-auto-complete-modal.component';
import { AddCargoModalComponent } from './components/cargo-auto-complete-modal/add-cargo-modal/add-cargo-modal.component';
import { PortAutoCompleteModalComponent } from './components/port-auto-complete-modal/port-auto-complete-modal.component';
import { RepairPriorityPipe } from './pipes/repair-priority.pipe';
import { PlanningItemComponent } from '../home/planning/planning-item/planning-item.component';
import { HomeToolbarComponent } from './components/home-toolbar/home-toolbar.component';
import { AddImageComponent } from './components/picture-upload/add-image/add-image.component';
import { SignatureComponent } from './components/signature/signature.component';
import { CompanyAutoCompleteModalComponent } from './components/company-complete-modal/company-auto-complete-modal.component';
import { AddCompanyModalComponent } from './components/company-complete-modal/add-company-modal/add-company-modal.component';
import { WorkOrderStatusPipe } from './pipes/work-order-status.pipe';
import { ReadingCalculationService } from 'src/app/services/reading-calculation.service';

@NgModule({
    declarations: [
        LanguageSwitchComponent,
        PictureUploadComponent,
        HomeToolbarComponent,
        RepairStatusPipe,
        RepairPriorityPipe,
        PortAutoCompleteModalComponent,
        AddPortModalComponent,
        CargoAutoCompleteModalComponent,
        AddCargoModalComponent,
        PlanningItemComponent,
        AddImageComponent,
        SignatureComponent,
        CompanyAutoCompleteModalComponent,
        AddCompanyModalComponent,
        WorkOrderStatusPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        TranslateModule,
        LanguageSwitchComponent,
        PictureUploadComponent,
        PlanningItemComponent,
        HomeToolbarComponent,
        RepairStatusPipe,
        WorkOrderStatusPipe,
        RepairPriorityPipe,
        SignatureComponent,
    ], providers: [
        DatePipe,
        RepairStatusPipe,
        RepairPriorityPipe,
        WorkOrderStatusPipe
    ]
})
export class SharedModule { }
