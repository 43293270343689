<home-toolbar
  [showBackButton]="true"
  [title]="'reisrapport' | translate"
></home-toolbar>

<ion-content>
  <div class="d-flex flex-column justify-content-between h-100">
    <form
      [formGroup]="form"
      (ngSubmit)="submit(form.value)"
      novalidate
      autocomplete="off"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 mb-md-2 mb-sm-2 mb-3">
            <h1 class="text-uppercase mb-3">{{ "reisrapport" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "laadhaven" | translate
              }}</label>

              <input
                class="d-none"
                type="text"
                formControlName="loadingPortId"
              />

              <label
                class="autocomplete-wrapper col-md-6 col-sm-6 col-6 p-0 mb-0"
              >
                <input
                  class="input-text w-100 autocomplete"
                  type="text"
                  (click)="openPortModal('loadingPortId')"
                  [placeholder]="'selecteer_laadhaven' | translate"
                  readonly
                  [value]="autoCompleteControls['loadingPortId']?.Name"
                />

                <ion-icon md="ios-arrow-down" ios="ios-arrow-down"></ion-icon>
              </label>
            </div>
            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "vertrek" | translate
              }}</label>

              <label class="position-relative col-md-6 col-sm-6 col-6 p-0 mb-0">
                <ion-datetime-button
                  name="departmentDateButton"
                  id="departmentDateButton"
                  datetime="departmentDate"
                  class="input-text justify-content-start flex-nowrap text-nowrap"
                ></ion-datetime-button>
                <ion-icon class="icon-calendar" name="calendar" (click)="departmentDateModal.present()"></ion-icon>
                <ion-modal [keepContentsMounted]="true" #departmentDateModal>
                  <ng-template>
                    <ion-datetime
                      id="departmentDate"
                      presentation="date-time"
                      name="departmentDate"
                      formControlName="departmentDate"
                      [min]="minimalDate.toString() | date : 'yyyy-MM-dd'"
                      [max]="today.getFullYear() + 10"
                      [cancelText]="'annuleren' | translate"
                      [doneText]="'klaar' | translate"
                      [showDefaultButtons]="true"
                      [locale]="currentLanguage"
                      [formatOptions]="{
                          date: {
                            weekday: 'short',
                            month: 'long',
                            day: '2-digit',
                          },
                          time: {
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        }"
                    >
                      <span slot="time-label">{{'time' | translate}}</span>
                    </ion-datetime>
                  </ng-template>
                </ion-modal>
              </label>
            </div>
            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "loshaven" | translate
              }}</label>

              <input
                class="d-none"
                type="text"
                formControlName="dischargePortId"
              />

              <label
                class="autocomplete-wrapper col-md-6 col-sm-6 col-6 p-0 mb-0"
              >
                <input
                  class="input-text w-100 autocomplete"
                  type="text"
                  (click)="openPortModal('dischargePortId')"
                  [placeholder]="'selecteer_loshaven' | translate"
                  readonly
                  [value]="autoCompleteControls['dischargePortId']?.Name"
                />

                <ion-icon md="ios-arrow-down" ios="ios-arrow-down"></ion-icon>
              </label>
            </div>
            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "aankomst" | translate
              }}</label>

              <label class="position-relative col-md-6 col-sm-6 col-6 p-0 mb-0">
                <ion-datetime-button
                  datetime="arrivalDate"
                  class="input-text justify-content-start flex-nowrap text-nowrap"
                ></ion-datetime-button>
                <ion-icon class="icon-calendar" name="calendar" (click)="arrivalDateModal.present()"></ion-icon>
                <ion-modal [keepContentsMounted]="true" #arrivalDateModal>
                  <ng-template>
                    <ion-datetime
                      id="arrivalDate"
                      presentation="date-time"
                      name="arrivalDate"
                      formControlName="arrivalDate"
                      [min]="minimalDate.toString() | date : 'yyyy-MM-dd'"
                      [max]="today.getFullYear() + 10"
                      [cancelText]="'annuleren' | translate"
                      [doneText]="'klaar' | translate"
                      [showDefaultButtons]="true"
                      [locale]="currentLanguage"
                      [formatOptions]="{
                          date: {
                            weekday: 'short',
                            month: 'long',
                            day: '2-digit',
                          },
                          time: {
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        }"
                    ></ion-datetime>
                  </ng-template>
                </ion-modal>
              </label>
            </div>
            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "lading" | translate
              }}</label>
              <input class="d-none" type="text" formControlName="cargoId" />

              <label
                class="autocomplete-wrapper col-md-6 col-sm-6 col-6 p-0 mb-0"
              >
                <input
                  class="input-text w-100 autocomplete"
                  type="text"
                  (click)="openCargoModal('cargoId')"
                  [placeholder]="'selecteer_lading' | translate"
                  readonly
                  [value]="autoCompleteControls['cargoId']?.Cargo"
                />

                <ion-icon md="ios-arrow-down" ios="ios-arrow-down"></ion-icon>
              </label>
            </div>
            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "tonnage_schip" | translate
              }}</label>
              <input
                class="input-text col-md-6 col-sm-6 col-6"
                [class.input-error]="hasError('tonnage', 'min')"
                type="number"
                formControlName="tonnage"
                name="tonnage"
                placeholder="1234"
              />
            </div>
            <div class="row align-items-center mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "foto" | translate
              }}</label>

              <div class="col-md-6 col-6 pl-0">
                <div class="row">
                  <div
                    class="d-inline-flex col-md-5"
                    *ngFor="let number of [0, 1, 2, 3]"
                  >
                    <upload-picture
                      *ngIf="number <= pictures.length"
                      (pictureChanged)="pictureChanged($event)" 
                      [index]="number"
                    ></upload-picture>
                  </div>
                </div>
                <div class="row">
                  <div class="d-inline-flex col-md-5">
                    <label
                      class="temp-picture"
                      [ngClass]="{ 'has-image': image != null }"
                      [style.background-image]="
                        image.Image
                          ? 'url(' +
                            imageHostUrl +
                            '/upload/' +
                            image.Image +
                            ')'
                          : 'url(/assets/picture-placeholder.png)'
                      "
                      *ngFor="let image of tempImages; let i = index"
                    >
                      <div
                        class="delete-picture"
                        *ngIf="image"
                        (click)="deletePic(image)"
                      >
                        <img src="/assets/remove.svg" alt="" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <h1 class="text-uppercase mb-3">
              {{ "gasolieverbruik" | translate }}
            </h1>
            <span class="header-subtitle"
              >( {{ "inliters" | translate }} )</span
            >

            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "gasolie_stand_aanvang" | translate
              }}</label>
              <input
                class="input-text col-md-6 col-sm-6 col-6"
                [class.input-error]="hasError('oilLevelStart', 'min')"
                type="number"
                formControlName="oilLevelStart"
                name="oilLevelStart"
                placeholder="1234"
              />
            </div>
            <div class="row align-items-center mb-2 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "gebunkerd_tijdens_reis" | translate
              }}</label>
              <input
                class="input-text col-md-6 col-sm-6 col-6"
                [class.input-error]="hasError('bunkeredAmount', 'min')"
                type="number"
                formControlName="bunkeredAmount"
                name="bunkeredAmount"
                placeholder="1234"
              />
            </div>
            <div class="row align-items-center mb-3 mr-0">
              <label class="label col-md-5 col-sm-6 col-6">{{
                "gasolie_stand_aankomst" | translate
              }}</label>
              <input
                class="input-text col-md-6 col-sm-6 col-6"
                [class.input-error]="hasError('oilLevelEnd', 'min')"
                type="number"
                formControlName="oilLevelEnd"
                name="oilLevelEnd"
                placeholder="1234"
              />
            </div>
            <div
              class="row align-items-center mb-3 mr-0 journey-report-total"
              *ngIf="computeTotal() !== 0"
            >
              <label class="label col-md-5 col-sm-6 col-6">{{
                "totaal" | translate
              }}</label>
              <span class="col-md-6 col-sm-6 col-6 pl-0">{{
                computeTotal()
              }}</span>
            </div>

            <div *ngIf="barges.length > 0">
              <h1 class="text-uppercase mb-3 mt-3" *ngIf="barges.length > 0">
                {{ "duwbakken" | translate }}
              </h1>

              <div class="row">
                <div class="col-md-11 col-sm-12 col-12">
                  <table class="table table-fc">
                    <thead>
                      <tr>
                        <th>{{ "naam" | translate }}</th>
                        <th>{{ "tonnage" | translate }}</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let barge of barges; let i = index">
                        <td>{{ barge.Name }}</td>
                        <td>{{ barge.Tonnage }}</td>
                        <td class="text-center" (click)="deleteBarge(i)">
                          <ion-icon
                            class="icon-delete-barge"
                            color="primary"
                            name="trash"
                          >
                          </ion-icon>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row align-items-center mb-3 mr-0">
              <div class="col-md-11 col-sm-12 col-12 pr-0">
                <a class="btn-login" (click)="saveReport()">
                  {{ "Opslaan" | translate }}
                </a>
                <div><br /></div>
                <button class="btn-login" [disabled]="isSaving" type="submit">
                  {{ "versturen" | translate }}
                </button>
                <span class="small-txt" *ngIf="!form.valid">{{
                  "nietallesingevuld" | translate
                }}</span>

                <div *ngIf="showErrors && !form.valid">
                  <div>Errors</div>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('loadingPortId')!.hasError('required')"
                  >
                    {{ "laadhaven" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('dischargePortId')!.hasError('required')"
                  >
                    {{ "loshaven" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('departmentDate')!.hasError('required')"
                  >
                    {{ "vertrek" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('arrivalDate')!.hasError('required')"
                  >
                    {{ "aankomst" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('cargoId')!.hasError('required')"
                  >
                    {{ "lading" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('tonnage')!.hasError('required')"
                  >
                    {{ "tonnage_schip" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('oilLevelStart')!.hasError('required')"
                  >
                    {{ "gasolie_stand_aanvang" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('oilLevelEnd')!.hasError('required')"
                  >
                    {{ "gasolie_stand_aankomst" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('bunkeredAmount')!.hasError('required')"
                  >
                    {{ "gebunkerd_tijdens_reis" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="add-barge-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mb-2 mb-sm-0">
            <form
              [formGroup]="addBargeForm"
              (ngSubmit)="addBargeForm.valid && addBarge(addBargeForm.value)"
              *ngIf="barges.length < 7"
            >
              <div class="row row-add-barge mr-0">
                <label
                  class="label label-white label-big text-uppercase col-md-5 col-sm-6 col-6"
                  >{{ "duwbak" | translate }}</label
                >

                <button
                  [disabled]="!addBargeForm.valid"
                  class="btn-login col-md-6 col-sm-6 col-6 p-0"
                  type="submit"
                >
                  {{ "duwbak_toevoegen" | translate }}
                  <ion-icon name="add"></ion-icon>
                </button>
              </div>
              <div class="row align-items-center mb-2 mr-0">
                <label class="label label-white col-md-5 col-sm-6 col-6">{{
                  "naam" | translate
                }}</label>
                <input
                  class="input-text col-md-6 col-sm-6 col-6"
                  type="text"
                  formControlName="name"
                  [placeholder]="'naam' | translate"
                />
              </div>
              <div class="row align-items-center mb-2 mr-0">
                <label class="label label-white col-md-5 col-sm-6 col-6">{{
                  "tonnage" | translate
                }}</label>
                <input
                  class="input-text col-md-6 col-sm-6 col-6"
                  type="number"
                  [class.input-error]="
                    addBargeForm.controls['tonnage'].hasError('min')
                  "
                  formControlName="tonnage"
                  placeholder="1234"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
