import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalAppSettingsService } from '../../../services/local-app-settings.service';
import * as moment from "moment"

@Component({
    selector: 'language-switch',
    templateUrl: './language-switch.component.html',
    styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {

    currentLanguage: string = "nl";

    constructor(private translateService: TranslateService,
        private localAppSettings: LocalAppSettingsService) { }

    ngOnInit() {
        this.currentLanguage = this.localAppSettings.selectedLanguage;
    }

    onLanguageChange(event: CustomEvent) {
        const selectedLang = event.detail.value;
        this.currentLanguage = selectedLang;
        this.localAppSettings.selectedLanguage = selectedLang;
        this.translateService.setDefaultLang(selectedLang);
        moment.locale(selectedLang);
    }
}
