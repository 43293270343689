<home-toolbar [showBackButton]="true" [title]="'reparaties' | translate"></home-toolbar>

<ion-content>
    <div class="container">
        <form class="form-repair" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" novalidate
            autocomplete="off">

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'status' | translate}}</label>
                <input class="input-text col-7 readonly" type="text" id="status" name="status"
                    [value]="'Open' | translate" readonly />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'beschrijving' | translate}}</label>
                <input class="input-text col-7" type="text" formControlName="description" id="description"
                    name="description" [placeholder]="'beschrijving' | translate" />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'plaats' | translate}}</label>
                <input class="input-text col-7" type="text" formControlName="explanation" id="explanation"
                    name="explanation" [placeholder]="'plaats' | translate" />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'bedrijf' | translate}}</label>
                <input class="input-text col-7" type="text" formControlName="executiveName" id="executiveName" name="executiveName"
                    [placeholder]="'bedrijf' | translate" />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'contactpersoon' | translate}}</label>
                <input class="input-text col-7" type="text" formControlName="contact" id="contact" name="contact"
                    [placeholder]="'contact' | translate" />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'prioriteit' | translate}}</label>

                <ion-select class="dropdown col" [value]="selectedPriority" (ionChange)="onPriorityChanged($event)"
                    interface="popover" [cancelText]="'annuleren' | translate" [okText]="'ok' | translate">
                    <ion-select-option *ngFor="let priority of priorityOptions" [value]="priority">
                        {{priority | repairPriority}}</ion-select-option>
                </ion-select>

            </div>

            <div class="mb-3">
                <button class="btn-login" expand="full" type="submit" [disabled]="!form.valid">{{'toevoegen' |
                    translate}}</button>
            </div>
        </form>
    </div>
</ion-content>
