import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ISerenityListResponse } from '../shared/models/serenity/SerenityResponse';
import { IShipRole } from '../shared/models/ShipRole';
import { Constants } from 'src/app/constants';
import { catchError, flatMap, mergeMap } from 'rxjs/operators';
import { LocalAppSettingsService } from './local-app-settings.service';
import { firstValueFrom } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ShipRoleService {
  constructor(
    private http: HttpService,
    private storage: StorageService,
    private appSettings: LocalAppSettingsService
  ) {}

  async list(take = 100, includeColumns?: string[]): Promise<IShipRole[]> {
    const http$ = await this.http.post<ISerenityListResponse<IShipRole>>(
      `${environment.baseUrl}/api/ShipRole/List`,
      {
        take,
        includeColumns,
        equalityFilter: { ShipId: this.appSettings.ship.Id },
      }
    );
    return firstValueFrom(
      http$
        .pipe(
          mergeMap(async (result) => {
            await this.storage.set(
              Constants.sqlStorageKeys.shipRoles,
              result.Entities
            );
            return result.Entities;
          })
        )
        .pipe(
          catchError(
            async () =>
              (await this.storage.get<IShipRole[]>(
                Constants.sqlStorageKeys.shipRoles
              ))
          )
        )
    );
  }
}
