import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from "../services/authentication.service";
import { LocalAppSettingsService } from '../services/local-app-settings.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private appSettings: LocalAppSettingsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token = this.appSettings.accessToken;

        if (token) {
            const authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});

            return next.handle(authReq);
        }
        return next.handle(req);
    }
}
