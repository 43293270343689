import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular'
import { BaseModalComponent } from '../../classes/BaseModalComponent';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { WorkOrderCompanyService } from 'src/app/services/workorder-company.service';
import { IWorkOrderCompanyEntity } from '../../models/WorkOrderCompany';
import { AddCompanyModalComponent } from './add-company-modal/add-company-modal.component';

@Component({
    selector: 'company-auto-complete-modal',
    templateUrl: './company-auto-complete-modal.component.html',
    styleUrls: ['./company-auto-complete-modal.component.scss'],
})
export class CompanyAutoCompleteModalComponent extends BaseModalComponent implements OnInit {

    searchTerm: string = "";
    items: IWorkOrderCompanyEntity[] = [];
    isOnline: boolean = false;

    constructor(modalController: ModalController,
        private workOrderCompanyService: WorkOrderCompanyService,
        private networkService: NetworkService) {
            super(modalController);
        }

    ngOnInit() {
        this.subscriptions.push(this.networkService.onNetworkChange().subscribe((status) => {
            this.isOnline = status == ConnectionStatus.Online;
        }));

        this.workOrderCompanyService.list(100, this.searchTerm).then((items) => this.items = items);
    }

    async onSearchTermChanged(event: CustomEvent) {
        this.searchTerm = event.detail.value;

        this.items = await this.workOrderCompanyService.list(100, this.searchTerm);
    }

    onItemSelected(item: IWorkOrderCompanyEntity) {
        this.modalController.dismiss(item);
    }

    async openAddNewItemModal() {
        const modal = await this.modalController.create({
            component: AddCompanyModalComponent
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data != null) {
            this.modalController.dismiss(data);
        }
    }
}
