
<div class="container">
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <taskStatus-component [status]="task.Status"></taskStatus-component>
            <span class="header-title">{{task.Name}}</span>

            <div class="row detail-data">
                <div class="col-6">
                    {{"Interval" | translate}}
                </div>
                <div class="col-6">
                    {{task.RepeatInterval}} {{"uur" | translate}}
                </div>
            </div>
            <div class="row detail-data">
                <div class="col-6">
                    {{"Volgende_controle" | translate}}
                </div>
                <div class="col-6">
                    {{task.LastCompletedReading + task.RepeatInterval}} {{"uur" | translate}} / {{"nog" | translate}} {{(task.LastCompletedReading + task.RepeatInterval) - task.LastReading}} {{"uur" | translate}}
                </div>
            </div>
            <div class="row detail-data">
                <div class="col-6">
                    {{"Laatste_onderhoud" | translate}}
                </div>
                <div class="col-6">
                    {{task.LastCompletedReading}} {{"uur" | translate}} <span *ngIf="getYear(task.LastExecutedDate) > 1900">({{task.LastExecutedDate | date: 'dd-MM-yyyy'}})</span>
                </div>
            </div>
            <div class="row detail-data">
                <div class="col-6">
                    {{"Laatste_controle" | translate}}
                </div>
                <div class="col-6">
                    {{task.LastReading}} {{"uur" | translate}}
                </div>
            </div>
        </div>

        <div class="col-md-6 col-sm-12">
            
            <span class="title">{{"Nieuwe_registratie" | translate}}</span>

            <form [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" autocomplete="off">

                <div class="row">
                    <div class="col-4">
                        <span>{{"Datum" | translate}}</span>
                    </div>
                    <div class="col-4">
                        <ion-datetime-button
                        name="readingDateButton"
                        id="readingDateButton"
                        datetime="readingDate"
                        class="input-text justify-content-start flex-nowrap text-nowrap"></ion-datetime-button>

                        <ion-modal [keepContentsMounted]="true" #departmentDateModal>
                            <ng-template>
                              <ion-datetime
                                id="readingDate"
                                presentation="date"
                                name="readingDate"
                                formControlName="readingDate"
                                [cancelText]="'annuleren' | translate"
                                [doneText]="'klaar' | translate"
                                [showDefaultButtons]="true"
                                [locale]="currentLanguage"
                                [formatOptions]="{
                                    date: {
                                      weekday: 'short',
                                      month: 'long',
                                      day: '2-digit',
                                    }
                                  }"
                              >
                              </ion-datetime>
                            </ng-template>
                          </ion-modal>

                    </div>
                    <div class="col-4"></div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <span>{{"Naam" | translate}}</span>
                    </div>
                    <div class="col-4">
                            <input class="input-text w-100 autocomplete"
                                type="text"
                                [placeholder]="'Naam' | translate"
                                formControlName="employeeName" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <span>{{"aantal" | translate}}</span>
                    </div>
                    <div class="col-4">
                        <input class="input-text w-100 autocomplete"
                            type="text"
                            [placeholder]="'Aantal' | translate"                        
                            [value]="" formControlName="quantity" />
                    </div>
                    <div class="col-4"></div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <span>{{"uren_stand" | translate}}</span>
                    </div>
                    <div class="col-4">
                        <input class="input-text w-100 autocomplete"
                            type="text"
                            [placeholder]="'uren_stand' | translate"
                            [value]="" formControlName="shipReading" />
                    </div>
                    <div class="col-4"></div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <span>{{"Remark" | translate}}</span>
                    </div>
                    <div class="col-4">
                        <textarea class="input-text w-100 autocomplete"
                            row="3"
                            type="text"
                            [placeholder]="'remark' | translate"                        
                            [value]="" formControlName="remark"></textarea>
                    </div>
                    <div class="col-4">
                        <button class="btn-login" type="submit">{{'Opslaan' | translate}}</button>
                    </div>                    
                </div>

            </form>

        </div>
    </div>
</div>