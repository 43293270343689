import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RepairPriority } from '../models/RepairPriority';

@Pipe({
    name: 'repairPriority'
})
export class RepairPriorityPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(status: RepairPriority, args?: any): any {
        switch (status) {
            case RepairPriority.Low:
                return this.translateService.instant("repairpriority_low");
            case RepairPriority.Normal:
                return this.translateService.instant("repairpriority_normal");
            case RepairPriority.High:
                return this.translateService.instant("repairpriority_high");
        }

        return this.translateService.instant("repairpriority_normal");
    }
}

