<home-toolbar
  [showBackButton]="true"
  [title]="'werkbon' | translate"
></home-toolbar>

<ion-content>
  <div class="d-flex flex-column justify-content-between h-100">
    <form
      [formGroup]="form"
      (ngSubmit)="submit(form.value)"
      novalidate
      autocomplete="off"
    >
      <div class="container">
        <div class="row">
          <div class="col mb-md-2 mb-sm-2 mb-3">
            <h1 class="text-uppercase mb-3">{{ "werkbon" | translate }}</h1>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "datum" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="position-relative col p-0 mb-0">
                  <ion-datetime-button
                    name="dateButton"
                    id="dateButton"
                    datetime="date"
                    class="input-text justify-content-start flex-nowrap text-nowrap"
                  ></ion-datetime-button>
                  <ion-icon
                    class="icon-calendar"
                    name="calendar"
                    (click)="dateModal.present()"
                  ></ion-icon>
                  <ion-modal [keepContentsMounted]="true" #dateModal>
                    <ng-template>
                      <ion-datetime
                        id="date"
                        presentation="date"
                        name="date"
                        formControlName="date"
                        [cancelText]="'annuleren' | translate"
                        [doneText]="'klaar' | translate"
                        [showDefaultButtons]="true"
                        [locale]="currentLanguage"
                        [formatOptions]="{
                          date: {
                            weekday: 'short',
                            month: 'long',
                            day: '2-digit',
                          },
                          time: {
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        }"
                      >
                        <span slot="time-label">{{ "time" | translate }}</span>
                      </ion-datetime>
                    </ng-template>
                  </ion-modal>
                </label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "werkbon_bedrijf" | translate
              }}</label>

              <input
                class="d-none"
                type="number"
                formControlName="workOrderCompanyId"
                name="workOrderCompanyId"
              />

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="autocomplete-wrapper col p-0 mb-0">
                  <input
                    class="input-text w-100 autocomplete"
                    type="text"
                    (click)="openCompanyModal('workOrderCompanyId')"
                    [placeholder]="'selecteer_bedrijf' | translate"
                    readonly
                    [value]="autoCompleteControls['workOrderCompanyId']?.Name"
                  />

                  <ion-icon md="ios-arrow-down" ios="ios-arrow-down"></ion-icon>
                </label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "taakomschrijving" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <input
                  class="input-text col"
                  formControlName="jobDescription"
                  name="jobDescription"
                />
              </div>
            </div>
            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "starttijd" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="position-relative col p-0 mb-0">
                  <ion-datetime-button
                    datetime="startDateTime"
                    class="input-text justify-content-start flex-nowrap text-nowrap"
                  ></ion-datetime-button>
                  <ion-icon
                    class="icon-calendar"
                    name="calendar"
                    (click)="startDateTimeModal.present()"
                  ></ion-icon>
                  <ion-modal [keepContentsMounted]="true" #startDateTimeModal>
                    <ng-template>
                      <ion-datetime
                        id="startDateTime"
                        presentation="date-time"
                        name="startDateTime"
                        formControlName="startDateTime"
                        [cancelText]="'annuleren' | translate"
                        [doneText]="'klaar' | translate"
                        [showDefaultButtons]="true"
                        [locale]="currentLanguage"
                        [formatOptions]="{
                          date: {
                            weekday: 'short',
                            month: 'long',
                            day: '2-digit',
                          },
                          time: {
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        }"
                      ></ion-datetime>
                    </ng-template>
                  </ion-modal>
                </label>
              </div>
            </div>
            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "eindtijd" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <label class="position-relative col p-0 mb-0">
                  <ion-datetime-button
                    datetime="endDateTime"
                    class="input-text justify-content-start flex-nowrap text-nowrap"
                  ></ion-datetime-button>
                  <ion-icon
                    class="icon-calendar"
                    name="calendar"
                    (click)="endDateTimeModal.present()"
                  ></ion-icon>
                  <ion-modal [keepContentsMounted]="true" #endDateTimeModal>
                    <ng-template>
                      <ion-datetime
                        id="endDateTime"
                        presentation="date-time"
                        name="endDateTime"
                        formControlName="endDateTime"
                        [cancelText]="'annuleren' | translate"
                        [doneText]="'klaar' | translate"
                        [showDefaultButtons]="true"
                        [locale]="currentLanguage"
                        [formatOptions]="{
                          date: {
                            weekday: 'short',
                            month: 'long',
                            day: '2-digit',
                          },
                          time: {
                            hour: '2-digit',
                            minute: '2-digit',
                          },
                        }"
                      ></ion-datetime>
                    </ng-template>
                  </ion-modal>
                </label>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "werkbon_status" | translate
              }}</label>

              <div class="col">
                <ion-select
                  class="dropdown"
                  formControlName="status"
                  interface="popover"
                  placeholder="Kies een status"
                  [cancelText]="'annuleren' | translate"
                  [okText]="'ok' | translate">
                  <ion-select-option
                    *ngFor="let status of statusOptions"
                    [value]="status">
                    {{ status | workOrderStatus }}</ion-select-option>
                </ion-select>
              </div>
            </div>

            <div class="row align-items-center mb-2 mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "opmerkingen" | translate
              }}</label>

              <div class="col-md-6 col-sm-6 col-12 p-sm-0">
                <textarea
                  class="input-text col p-0"
                  formControlName="remarks"
                  name="remarks"
                ></textarea>
              </div>
            </div>

            <div class="row align-items-center mr-sm-0">
              <label class="label col-md-5 col-sm-6 col-12">{{
                "foto" | translate
              }}</label>

              <div class="col-md-6 col-6 pl-sm-0">
                <div class="row">
                  <div
                    class="d-inline-flex col-md-5"
                    *ngFor="let number of [0, 1, 2, 3]"
                  >
                    <upload-picture
                      *ngIf="number <= pictures.length"
                      (pictureChanged)="pictureChanged($event)"
                      [index]="number"
                    ></upload-picture>
                  </div>
                </div>
                <div class="row">
                  <div class="d-inline-flex col-md-5">
                    <label
                      class="temp-picture"
                      [ngClass]="{ 'has-image': image != null }"
                      [style.background-image]="
                        image.Image
                          ? 'url(' +
                            imageHostUrl +
                            '/upload/' +
                            image.Image +
                            ')'
                          : 'url(/assets/picture-placeholder.png)'
                      "
                      *ngFor="let image of tempImages; let i = index"
                    >
                      <div
                        class="delete-picture"
                        *ngIf="image"
                        (click)="deletePic(image)"
                      >
                        <img src="/assets/remove.svg" alt="" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <h1 class="text-uppercase mb-3">
                  {{ "handtekening_kapitein" | translate }}
                </h1>

                <div class="row align-items-center mb-2 mr-sm-0">
                  <label class="label col-md-5 col-sm-6 col-12">{{
                    "naam_kapitein" | translate
                  }}</label>

                  <div class="col-md col-sm-6 col-12 p-sm-0">
                    <input
                      class="input-text col p-0"
                      formControlName="nameCaptain"
                      name="nameCaptain"
                    />
                  </div>
                </div>

                <signature
                  (onChange)="updateSignature('signatureCaptain', $event)"
                  [value]="form.get('signatureCaptain')?.value"></signature>
              </div>
            </div>
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <h1 class="text-uppercase mb-3">
                  {{ "handtekening_monteur" | translate }}
                </h1>

                <div class="row align-items-center mb-2 mr-sm-0">
                  <label class="label col-md-5 col-sm-6 col-12">{{
                    "naam_monteur" | translate
                  }}</label>

                  <div class="col-md col-sm-6 col-12 p-sm-0">
                    <input
                      class="input-text col p-0"
                      formControlName="nameMechanic"
                      name="nameMechanic"
                    />
                  </div>
                </div>

                <signature
                  (onChange)="updateSignature('signatureMechanic', $event)"
                  [value]="form.get('signatureMechanic')?.value"
                ></signature>
              </div>
            </div>
            <div class="row align-items-center mb-3 mr-sm-0">
              <div class="col-md-11 col-sm-12 col-12 pr-sm-0">
                <div class="row">
                  <div class="col">
                    <a class="btn-login" (click)="saveReport()">
                      {{ "Opslaan" | translate }}
                    </a>
                  </div>
                  <div class="col">
                    <button
                      class="btn-login"
                      [disabled]="isSaving"
                      type="submit"
                    >
                      {{ "versturen" | translate }}
                    </button>
                  </div>
                </div>

                <span class="small-txt" *ngIf="!form.valid">{{
                  "nietallesingevuld" | translate
                }}</span>

                <div *ngIf="showErrors && !form.valid">
                  <div>Errors</div>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('date')!.hasError('required')"
                  >
                    {{ "datum" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('workOrderCompanyId')!.hasError('required')"
                  >
                    {{ "werkbon_bedrijf" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('jobDescription')!.hasError('required')"
                  >
                    {{ "taakomschrijving" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('startDateTime')!.hasError('required')"
                  >
                    {{ "starttijd" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('endDateTime')!.hasError('required')"
                  >
                    {{ "eindtijd" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="
                      form.get('shipEmail')!.hasError('required') ||
                      form.get('shipEmail')!.hasError('pattern')
                    "
                  >
                    {{ "email_ship" | translate }} {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('status')!.hasError('required')"
                  >
                    {{ "werkbon_status" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('nameCaptain')!.hasError('required')"
                  >
                    {{ "naam_kapitein" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('signatureCaptain')!.hasError('required')"
                  >
                    {{ "handtekening_kapitein" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('nameMechanic')!.hasError('required')"
                  >
                    {{ "naam_monteur" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                  <span
                    class="errorSummaryItem"
                    *ngIf="form.get('signatureMechanic')!.hasError('required')"
                  >
                    {{ "handtekening_monteur" | translate }}
                    {{ "incorrect" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ion-content>
