import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { BaseComponent } from '../shared/classes/BaseComponent';
import { SettingsService } from '../services/settings.service';
import { IShip } from '../shared/models/Ship';
import { LocalAppSettingsService } from '../services/local-app-settings.service';
import { FcmService } from '../services/fcm.service';
import { ChooseShipService } from '../home/choose-ship/choose-ship.service';

@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html',
    styleUrls: ['home.page.scss'],
})
export class HomePage extends BaseComponent implements OnInit {
    ship?: IShip;
    username?: string;
    appVersionNo?: string;

    constructor(navController: NavController,
        private settingService: SettingsService,
        private appSettings: LocalAppSettingsService,
        private platform: Platform,
        private fcm: FcmService,
        private chooseShipService: ChooseShipService
        ) {
        super(navController);
    }

    ngOnInit() {
        this.platform.ready().then(() => {
            this.subscriptions.push(
                this.appSettings.getShipObservable().subscribe(ship => {
                    this.settingService.getSettings(ship).then((settings) => {
                        this.ship = settings.Ship;  
                        this.username = settings.Username;
                        this.appSettings.ship = settings.Ship;
                        this.appSettings.username = settings.Username;
                        this.appSettings.userId = settings.UserId;
            
                        this.fcm.getToken(settings.UserId);              
                    });
                })
            )            
        });
    }
}
