<ion-header>
    <ion-toolbar>
        <ion-title>{{title}}</ion-title>
        <ion-buttons *ngIf="showBackButton" slot="start">
            <ion-back-button id="backButton" mode="md" defaultHref="/home"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-menu-toggle slot="end" auto-hide="false" menu="fc-menu">
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
