import { Component, OnInit } from '@angular/core';
import { NavController, RefresherCustomEvent } from '@ionic/angular';
import { BaseComponent } from 'src/app/shared/classes/BaseComponent';
import * as moment from "moment"
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { WorkOrderService } from './work-order.service';
import { WorkOrderArchiveContainer } from 'src/app/shared/classes/WorkOrderArchiveContainer';

@Component({
    selector: 'work-orders',
    templateUrl: './work-orders.component.html',
    styleUrls: ['./work-orders.component.scss'],
})
export class WorkOrdersComponent extends BaseComponent implements OnInit {
    workOrders: WorkOrderArchiveContainer[] = [];

    private readonly monthsBackInTime = 6;

    constructor(navController: NavController,
        private workOrderService: WorkOrderService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    async ngOnInit() {
        moment.locale(this.appSettings.selectedLanguage);

        await this.initializeReportArchive();
    }

    private async initializeReportArchive() {
        const start = moment().subtract(this.monthsBackInTime, 'months').startOf('month');
        const end = moment().endOf('month');

        const reportContainers: WorkOrderArchiveContainer[] = [];

        for (let i = 0; i <= this.monthsBackInTime; i++) {
            let date = moment().subtract(i, 'month');
            reportContainers.push(new WorkOrderArchiveContainer(date.month() + 1, moment.months()[date.month()], date.year()));
        }

        const reports = await this.workOrderService.listBetweenDates(start.toISOString(), end.toISOString());

        reports!.forEach((report) => {
            const departmentDate = moment(report.Date);

            for (let i = 0; i < reportContainers.length; i++) {
                const reportContainer = reportContainers[i];

                if (reportContainer.month === departmentDate.month() + 1 && reportContainer.year === departmentDate.year()) {
                    reportContainer.reports.push(report);
                    break;
                };
            };
        });

        this.workOrders = reportContainers;
    }

    async refresh(event: RefresherCustomEvent) {
        await this.initializeReportArchive();
        event.target.complete();
    }

    navigateToReportDetail(id: number) {
        this.navController.navigateForward(`/home/work-order/${id}`);
    }
}
