import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../../../shared/classes/BaseFormComponent';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { RepairService } from '../repair.service';
import { IRepairListModel } from 'src/app/shared/models/Repair';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { RepairPriority } from 'src/app/shared/models/RepairPriority';
import { ISerenitySaveResponse } from 'src/app/shared/models/serenity/SerenityResponse';

@Component({
    selector: 'add-repair',
    templateUrl: './add-repair.component.html',
    styleUrls: ['./add-repair.component.scss', '../repairs.component.scss'],
})
export class AddRepairComponent extends BaseFormComponent implements OnInit {

    selectedPriority: RepairPriority = RepairPriority.Normal;
    priorityOptions?: any[];

    constructor(navController: NavController,
        private alertController: AlertController,
        private translateService: TranslateService,
        private repairService: RepairService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    ngOnInit() {
        this.setPriorityStatusOptions();

        this.form = new FormGroup({
            description: new FormControl('', [Validators.required]),
            explanation: new FormControl('', [Validators.required]),
            priority: new FormControl('', [Validators.required]),
            executiveName: new FormControl('', [Validators.required]),
            contact: new FormControl('', []),
        });

        this.form.controls['priority'].setValue(RepairPriority.Normal);
        this.selectedPriority = RepairPriority.Normal;
    }

    async submit(value: IRepairListModel) {
        value.shipId = this.appSettings.ship.Id;
        value.CompanyId = this.appSettings.ship.CompanyId;
        value.AddedDate = new Date();

        try {
            const result = await this.disableFormDuringApiCall<ISerenitySaveResponse>(() => this.repairService.create(value));

            if (result != null) {

                const alert = await this.alertController.create({
                    header: this.translateService.instant('reparaties'),
                    message: this.translateService.instant('reparatie_toegevoegd'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
            } else {
                const alert = await this.alertController.create({
                    header: this.translateService.instant('reparaties'),
                    message: this.translateService.instant('reparatie_toegevoegd_offline'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
            }

            this.navController.navigateBack("/home/repairs");
        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('error'),
                message: this.translateService.instant('error_toevoegen_reparatie'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }
    }

    private setPriorityStatusOptions() {
        const keys = Object.keys(RepairPriority).filter(k => typeof RepairPriority[k as any] === "number");
        this.priorityOptions = keys.map(k => RepairPriority[k as any]);
    }

    onPriorityChanged(event: CustomEvent) {
        const priority = event.detail.value;
        this.selectedPriority = priority;
        this.form.controls['priority'].setValue(priority);
    }
}
