<ion-header>
        <ion-toolbar>
            <ion-title>{{'lading_toevoegen' | translate}}</ion-title>
            <ion-buttons slot="end">
                <ion-button icon-only (click)="closeModal()">
                    <ion-icon name="close"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <div class="container">
            <form class="mt-3" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" novalidate
                autocomplete="off">

                <div class="row align-items-center mb-3 mr-0">
                    <label class="label col-md-5 ccol-sm-6 col-6">{{'lading' | translate}}</label>
                    <input class="input-text col-md-7 ccol-sm-6 col-6" type="text" formControlName="cargo"
                        [placeholder]="'lading' | translate" />
                </div>

                <button [disabled]="!form.valid || isSaving" class="btn-login" type="submit">{{'lading_toevoegen' | translate }}</button>
            </form>
        </div>
    </ion-content>
