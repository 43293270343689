import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { Constants } from '../constants';
import { IStoredRequest } from '../shared/models/StoredRequest';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, forkJoin } from 'rxjs';
import { switchMap, finalize, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineEventService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private translateService: TranslateService
  ) {}

  loader?: HTMLIonLoadingElement;

  checkForEvents(): Observable<any> {
    return from(this.storage.get<any[]>(Constants.sqlStorageKeys.storedRequests)).pipe(
      switchMap((storedOperations) => {
        let storedObj = storedOperations;
        if (storedObj && storedObj.length > 0) {
          return this.sendRequests(storedObj)
            .pipe(
              mergeMap(async () => {
                this.loader = await this.loadingController.create({
                  message: this.translateService.instant(
                    'data_synchronizeren_met_server'
                  ),
                });
                await this.loader.present();
              })
            )
            .pipe(
              finalize(() => {
                this.loader?.dismiss();

                let toast = this.toastController.create({
                  message: this.translateService.instant(
                    'data_gesynchroniseerd'
                  ),
                  duration: 3000,
                  position: 'bottom',
                });
                toast.then((toast) => toast.present());

                this.storage.remove(Constants.sqlStorageKeys.storedRequests);
              })
            );
        } else {
          return of(false);
        }
      })
    );
  }

  storeRequest(url: string, type: string, data: any) {
    let action: IStoredRequest = {
      url: url,
      type: type,
      data: data,
      time: new Date().getTime(),
      id: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5),
    };

    return this.storage
      .get<any[]>(Constants.sqlStorageKeys.storedRequests)
      .then((storedOperations) => {
        let storedObj = storedOperations;

        if (storedObj) {
          storedObj.push(action);
        } else {
          storedObj = [action];
        }

        return this.storage.set(
          Constants.sqlStorageKeys.storedRequests,
          storedObj
        );
      });
  }

  private sendRequests(operations: IStoredRequest[]) {
    const storedRequests = operations.map((operation) =>
      this.http.request(operation.type, operation.url, { body: operation.data })
    );

    return forkJoin(storedRequests);
  }
}
