import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, skip } from 'rxjs';
import { ToastController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Disposable } from '../shared/classes/Disposable';
import { Network } from '@capacitor/network';

export enum ConnectionStatus {
  Online = 0,
  Offline = 1,
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService extends Disposable {
  private status = new BehaviorSubject(ConnectionStatus.Offline);

  constructor(
    private toastController: ToastController,
    private platform: Platform,
    private translateService: TranslateService
  ) {
    super();

    this.platform.ready().then(async () => {      
      const status = (await Network.getStatus()).connected
        ? ConnectionStatus.Online
        : ConnectionStatus.Offline;
      this.status.next(status);

      this.subscriptions.push(
        this.status.pipe(skip(1), distinctUntilChanged()).subscribe((x) => {
          this.updateNetworkStatus(x);
        })
      );

      this.initializeNetworkEvents();
    });

    // Hack to load toastcontroller in this service. Otherwise it could crash when needed.
    this.toastController.create({ animated: false }).then(t => { t.present(); t.dismiss(); });
  }

  public initializeNetworkEvents() {
    Network.addListener('networkStatusChange', (status) => {
      this.status.next(
        status.connected ? ConnectionStatus.Online : ConnectionStatus.Offline
      );
    });
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    let connection = status == ConnectionStatus.Offline ? 'offline' : 'online';
    let toast = await this.toastController.create({
      message: this.translateService.instant(`u_bent_nu_${connection}`),
      duration: 3000,
      position: 'bottom',
    });
    toast.present();
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    Network.removeAllListeners();
  }
}
