<ion-header>
    <ion-toolbar class="toolbar">
        <ion-buttons slot="start">
            <ion-back-button id="backButton" mode="md" defaultHref="/account/login" [text]="''"></ion-back-button>
        </ion-buttons>
        <ion-title class="toolbar-title">
            Wachtwoord vergeten
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="container">

        <form class="form-forgot-password" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" novalidate
            autocomplete="off">
            <h1 class="mb-3">Wachtwoord vergeten</h1>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">E-mailadres</label>
                <input class="input-text col-7" type="email" formControlName="email" id="email" name="E-mailadres" placeholder="E-mailadres" />
            </div>

            <button class="btn-login" color="primary" type="submit" [disabled]="!form.valid || isSaving">Wachtwoord herstellen</button>
        </form>
    </div>
</ion-content>
