import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  exportAs: 'signature',
})
export class SignatureComponent implements AfterViewInit {
  @Input() value?: string;
  @Output() onChange = new EventEmitter<Blob | null>();

  @ViewChild('signatureCanvas', { static: false })
  canvas!: ElementRef<HTMLCanvasElement>;
  protected canvasElement!: HTMLCanvasElement;
  protected saveX!: number;
  protected saveY!: number;

  protected drawing = false;
  protected lineWidth = 5;

  constructor() {}

  ngAfterViewInit() {
    // Set the Canvas Element and its size
    this.canvasElement = this.canvas.nativeElement;
    this.canvasElement.width = 400;
    this.canvasElement.height = 200;

    if (!!this.value) {
      let ctx = this.canvasElement.getContext('2d');
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = (e) => {
        ctx?.drawImage(img, 0, 0);
        this.onChange.emit(this.getSignature());
      };
      img.src = this.value;
    }
  }

  protected startDrawing(event: MouseEvent | TouchEvent) {
    this.drawing = true;
    event.preventDefault();
    const canvasPosition = this.canvasElement.getBoundingClientRect();

    const ev = (<TouchEvent>event).touches?.[0] ?? <MouseEvent>event;
    const pageX = ev.pageX;
    const pageY = ev.pageY;
    this.saveX = pageX - canvasPosition.x;
    this.saveY = pageY - canvasPosition.y;
  }

  protected endDrawing(event: MouseEvent | TouchEvent) {
    const ev = (<TouchEvent>event).changedTouches?.[0] ?? <MouseEvent>event;
    const canvasPosition = this.canvasElement.getBoundingClientRect();
    const pageX = ev.pageX;
    const pageY = ev.pageY;
    if (
      this.saveX == pageX - canvasPosition.x &&
      this.saveY == pageY - canvasPosition.y
    ) {
      this.moved(event, true);
    }

    this.drawing = false;
    this.onChange.emit(this.getSignature());
  }

  protected moved(event: MouseEvent | TouchEvent, isEnding = false) {
    if (!this.drawing) return;
    event.preventDefault();

    const canvasPosition = this.canvasElement.getBoundingClientRect();
    let ctx = this.canvasElement.getContext('2d');
    if (!ctx) return;

    const ev = (<TouchEvent>event).touches?.[0] ?? <MouseEvent>event;
    let currentX = ev.pageX - canvasPosition.x;
    let currentY = ev.pageY - canvasPosition.y;
    if (isEnding) {
      currentX = this.saveX + 0.01;
      currentY = this.saveY + 0.01;
    }
    ctx.lineJoin = 'round';
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = this.lineWidth;

    ctx.beginPath();
    ctx.moveTo(this.saveX, this.saveY);
    ctx.lineTo(currentX, currentY);
    ctx.closePath();

    ctx.stroke();

    this.saveX = currentX;
    this.saveY = currentY;
  }

  public getSignature() {
    if (this.isCanvasBlank()) return null;
    let dataUrl = this.canvasElement.toDataURL();
    let blob = this.dataURItoBlob(dataUrl);
    return blob;
  }

  @HostListener('window:mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  protected onWindowUpEvent(event: MouseEvent | TouchEvent) {
    if (this.drawing) this.endDrawing(event);
  }

  protected clear() {
    let ctx = this.canvasElement.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    this.onChange.emit(this.getSignature());
  }

  private dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  private isCanvasBlank() {
    let ctx = this.canvasElement.getContext('2d');
    if (!ctx) return true;
    return !ctx
      .getImageData(0, 0, this.canvasElement.width, this.canvasElement.height)
      .data.some((channel: number) => channel !== 0);
  }
}
