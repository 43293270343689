import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/classes/BaseFormComponent';
import { NavController, AlertController } from '@ionic/angular';
import { FileChangeEvent } from 'src/app/shared/classes/FileChangeEvent';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InspectionReportService } from './inspection-report.service';
import { IInspectionReport } from 'src/app/shared/models/InspectionReport';
import { FileService } from 'src/app/services/file.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { TaskService } from 'src/app/services/task.service';
import { ITask } from 'src/app/shared/models/Task';

@Component({
    selector: 'app-inspection-reports',
    templateUrl: './inspection-reports.component.html',
    styleUrls: ['./inspection-reports.component.scss'],
})
export class InspectionReportsComponent extends BaseFormComponent implements OnInit {

    pictures: (File | Blob)[] = [];
    isOnline: boolean = false;
    tasks?: ITask[];

    constructor(navController: NavController,
        private networkService: NetworkService,
        private inspectionReportService: InspectionReportService,
        private fileService: FileService,
        private alertController: AlertController,
        private translateService: TranslateService,
        private appSettings: LocalAppSettingsService,
        private taskService: TaskService) {
        super(navController);
    }

    ngOnInit() {
        this.isOnline = this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online;

        this.form = new FormGroup({
            description: new FormControl('', [Validators.required]),
            taskId: new FormControl('', [Validators.required])
        });

        this.taskService.list(this.appSettings.ship.Id).then((result) => { 
            this.tasks = result;
        });
    }

    async submit(report: IInspectionReport) {
        report.Images = [];
        report.ShipId = this.appSettings.ship.Id;
        report.CompanyId = this.appSettings.ship.CompanyId;

        if (this.pictures.length > 0) {
            report.Images = await Promise.all(this.pictures.map((file) => this.fileService.uploadTemporaryFile({ file })));
        }
        try {
            const result = await this.disableFormDuringApiCall(() => this.inspectionReportService.create(report));

            if (result) {
                const alert = await this.alertController.create({
                    header: this.translateService.instant('inspectierapporten'),
                    message: this.translateService.instant('inspectie_rapport_toegevoegd'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
            } else {
                const alert = await this.alertController.create({
                    header: this.translateService.instant('inspectierapporten'),
                    message: this.translateService.instant('inspectie_rapport_toegevoegd_offline'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
            }

            this.navController.navigateBack('/home');
        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('error'),
                message: this.translateService.instant('error_toevoegen_inspectierapport'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }
    }

    pictureChanged(event: FileChangeEvent) {
        if (event.file != null) {
            this.pictures.push(event.file);
        } else if (event.index != null) {
            this.pictures.splice(event.index, 1);
        }
    }
}
