
export abstract class BaseArchiveContainer<T> {
    month: number;
    monthName: string;
    year: number;
    reports: T[];

    constructor(month: number, monthName: string, year: number) {
        this.month = month;
        this.monthName = monthName;
        this.year = year;
        this.reports = [];
    }
}
