import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PortService } from 'src/app/services/port.service';
import { IPortEntity } from '../../models/Port';
import { BaseModalComponent } from '../../classes/BaseModalComponent';
import { AddPortModalComponent } from './add-port-modal/add-port-modal.component';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';

@Component({
    selector: 'port-auto-complete-modal',
    templateUrl: './port-auto-complete-modal.component.html',
    styleUrls: ['./port-auto-complete-modal.component.scss'],
})
export class PortAutoCompleteModalComponent extends BaseModalComponent implements OnInit {

    searchTerm: string = "";
    items: IPortEntity[] = [];
    isOnline: boolean = false;

    constructor(modalController: ModalController,
        private portService: PortService,
        private networkService: NetworkService) {
            super(modalController);
        }

    ngOnInit() {
        this.subscriptions.push(this.networkService.onNetworkChange().subscribe((status) => {
            this.isOnline = status == ConnectionStatus.Online;
        }));

        this.portService.list(100, this.searchTerm).then((items) => this.items = items);
    }

    async onSearchTermChanged(event: CustomEvent) {
        this.searchTerm = event.detail.value;

        this.items = await this.portService.list(100, this.searchTerm);
    }

    onItemSelected(item: IPortEntity) {
        this.modalController.dismiss(item);
    }

    async openAddNewItemModal() {
        const modal = await this.modalController.create({
            component: AddPortModalComponent
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data != null) {
            this.modalController.dismiss(data);
        }
    }
}
