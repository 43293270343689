import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() { }

    get<T>(key: string, defaultValue?: T) {
        let storageItem = localStorage.getItem(key)
        let item = (storageItem ? JSON.parse(storageItem) : null) as T;

        if (item == null && defaultValue) {
            this.set(key, defaultValue);
            return defaultValue;
        }

        return item;
    }

    set<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}
