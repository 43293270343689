import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { NetworkService, ConnectionStatus } from '../../services/network.service';
import { ISerenityListResponse } from '../../shared/models/serenity/SerenityResponse';
import { environment } from 'src/environments/environment';
import { ILiveLocationEntity } from '../../shared/models/LiveLocation';
import { catchError, map, tap } from 'rxjs/operators';
import { Constants } from '../../constants';
import { firstValueFrom, throwError } from 'rxjs';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LiveLocationService {

  constructor(private http: HttpService,
    private storage: StorageService,
    private appSettings: LocalAppSettingsService,
    private networkService: NetworkService) { }

  async list(take = 100, containsText?: string, includeColumns?: string[], showLoader = true): Promise<ILiveLocationEntity[]> {
    const http$ = await this.http.post<ISerenityListResponse<ILiveLocationEntity>>(`${environment.baseUrl}/api/live/List`, 
                        { take, containsText, includeColumns, equalityFilter: { "ShipCompanyId": this.appSettings.ship.CompanyId }}, showLoader);

    return firstValueFrom(http$.pipe(
      map(async (result) => {
        return result.Entities;
      }),
      tap(async (result) => {
        await this.storage.set(Constants.sqlStorageKeys.liveLocations, result);
      }),
      catchError(async (error) => {
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
          return await this.storage.get<ILiveLocationEntity[]>(Constants.sqlStorageKeys.liveLocations);
        } else {
          return firstValueFrom(throwError(() => error));
        };
      })));
  }
}
