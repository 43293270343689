import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../../../shared/classes/BaseFormComponent';
import {
  NavController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { FileChangeEvent } from 'src/app/shared/classes/FileChangeEvent';
import { FileService } from 'src/app/services/file.service';
import { ImageModel } from '../../../shared/models/ImageModel';
import { environment } from '../../../../environments/environment';
import { WorkOrderService } from '../work-order.service';
import { IWorkOrder } from 'src/app/shared/models/WorkOrder';
import { Constants } from 'src/app/constants';
import { CompanyAutoCompleteModalComponent } from 'src/app/shared/components/company-complete-modal/company-auto-complete-modal.component';
import { WorkOrderStatus } from 'src/app/shared/classes/WorkOrderStatus';

@Component({
  selector: 'app-add-work-order',
  templateUrl: './add-work-order.component.html',
  styleUrls: ['./add-work-order.component.scss'],
})
export class AddWorkOrderComponent extends BaseFormComponent implements OnInit {
  pictures: (File | Blob)[] = [];
  tempImages: ImageModel[] = [];
  imageHostUrl: string;
  showErrors: boolean = false;
  currentLanguage: string = '';
  statusOptions?: any[];

  autoCompleteControls: { [key: string]: any } = {
    workOrderCompanyId: null
  };

  readonly minimalDate = moment().subtract(1, 'month');

  constructor(
    navController: NavController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private workOrderService: WorkOrderService,
    private appSettings: LocalAppSettingsService,
    private fileService: FileService,
    private modalController: ModalController
  ) {
    super(navController);

    this.imageHostUrl = environment.baseUrl;
  }

  ngOnInit() {
    this.setStatusOptions();
    
    let date: Date | unknown = this.today.toISOString();
    let workOrderCompanyId: number | null = null;
    let jobDescription = null;
    let startDateTime: Date | unknown = this.today.toISOString();
    let endDateTime: Date | unknown = this.today.toISOString();
    let remarks = null;
    let nameCaptain = null;
    let signatureCaptain = null;
    let nameMechanic = null;
    let signatureMechanic = null;
    let shipEmail = this.appSettings.ship.Email;
    let status = null;

    if (this.appSettings.currentWorkOrder != null) {
      date = this.appSettings.currentWorkOrder.date;
      shipEmail = this.appSettings.currentWorkOrder.shipEmail;
      workOrderCompanyId = this.appSettings.currentWorkOrder.workOrderCompanyId;
      jobDescription = this.appSettings.currentWorkOrder.jobDescription;
      startDateTime = this.appSettings.currentWorkOrder.startDateTime;
      endDateTime = this.appSettings.currentWorkOrder.endDateTime;
      remarks = this.appSettings.currentWorkOrder.remarks;
      nameCaptain = this.appSettings.currentWorkOrder.nameCaptain;
      signatureCaptain = this.appSettings.currentWorkOrder.signatureCaptain ? `${this.imageHostUrl}/upload/${this.appSettings.currentWorkOrder.signatureCaptain}` : null;
      nameMechanic = this.appSettings.currentWorkOrder.nameMechanic;
      signatureMechanic = this.appSettings.currentWorkOrder.signatureMechanic ? `${this.imageHostUrl}/upload/${this.appSettings.currentWorkOrder.signatureMechanic}` : null;
      status = this.appSettings.currentWorkOrder.status;

      this.tempImages = this.appSettings.currentWorkOrder.images;
    }

    if (this.appSettings.currentWorkOrderListData != null) {
      this.autoCompleteControls = <any>(
        this.appSettings.currentWorkOrderListData
      );
    }

    this.form = new FormGroup(
      {
        date: new FormControl(date, [Validators.required]),
        shipEmail: new FormControl(shipEmail, [Validators.required,Validators.pattern(Constants.regexes.email), Validators.required]),
        jobDescription: new FormControl(jobDescription, [Validators.required]),
        workOrderCompanyId: new FormControl(workOrderCompanyId, [Validators.required]),
        startDateTime: new FormControl(startDateTime, [Validators.required]),
        endDateTime: new FormControl(endDateTime, [Validators.required]),
        status: new FormControl(status, [Validators.required]),
        remarks: new FormControl(remarks),
        nameCaptain: new FormControl(nameCaptain, [
          Validators.required,
        ]),
        signatureCaptain: new FormControl(signatureCaptain, [
          Validators.required,
        ]),
        nameMechanic: new FormControl(nameMechanic, [
          Validators.required,
        ]),
        signatureMechanic: new FormControl(signatureMechanic, [
          Validators.required,
        ]),
      },
      { validators: [this.compareStartAndEndDates] }
    );

    this.currentLanguage = this.appSettings.selectedLanguage;
    this.subscriptions.push(
      this.appSettings.getLanguageObservable().subscribe((x) => {
        this.currentLanguage = x;
      })
    );
  }

  pictureChanged(event: FileChangeEvent) {
    if (event.file != null) {
      this.pictures.push(event.file);
    } else if (event.index != null) {
      this.pictures.splice(event.index, 1);
    }
  }

  async saveReport() {
    if (!this.appSettings.ship) return;

    const alert = await this.alertController.create({
      header: this.translateService.instant('werkbonopgeslagen'),
      message: this.translateService.instant('werkbonopgeslagen_instructies'),
      buttons: ['OK'],
    });

    this.appSettings.currentWorkOrder = {
      date: this.form.controls['date'].value,
      shipEmail: this.form.controls['shipEmail'].value,
      workOrderCompanyId: this.form.controls['workOrderCompanyId'].value,
      jobDescription: this.form.controls['jobDescription'].value,
      startDateTime: this.form.controls['startDateTime'].value,
      endDateTime: this.form.controls['endDateTime'].value,
      status: this.form.controls['status'].value,
      remarks: this.form.controls['remarks'].value,
      nameCaptain: this.form.controls['nameCaptain'].value,
      signatureCaptain: this.form.controls['signatureCaptain'].value
        ? (
            await this.fileService.uploadTemporaryFile({
              file: (<unknown>(
                this.form.controls['signatureCaptain'].value
              )) as Blob,
            })
          )?.Image
        : null,
      nameMechanic: this.form.controls['nameMechanic'].value,
      signatureMechanic: this.form.controls['signatureMechanic'].value
        ? (
            await this.fileService.uploadTemporaryFile({
              file: (<unknown>(
                this.form.controls['signatureMechanic'].value
              )) as Blob,
            })
          )?.Image
        : null,
      shipId: this.appSettings.ship.Id,
      images: await Promise.all(
        this.pictures.map((file) =>
          this.fileService.uploadTemporaryFile({ file })
        )
      )
    };

    this.appSettings.currentWorkOrderListData = this.autoCompleteControls;

    await alert.present();
    return false;
  }

  async submit(report: IWorkOrder) {
    if (!this.form.valid || !this.appSettings.ship) {
      this.showErrors = true;
      return;
    }

    report.shipId = this.appSettings.ship.Id;

    try {
      if (this.pictures.length > 0) {
        report.images = await Promise.all(
          this.pictures.map((file) =>
            this.fileService.uploadTemporaryFile({ file })
          )
        );
      }
    } catch (error) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('toevoegen_afb_mislukt'),
        message: this.translateService.instant('toevoegen_afb_mislukt'),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();
    }

    try {
      let image = await this.fileService.uploadTemporaryFile({
        file: (<unknown>report.signatureCaptain) as Blob,
      });
      report.signatureCaptain = image.Image;
    } catch (error) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('toevoegen_handtekening_mislukt'),
        message: this.translateService.instant(
          'toevoegen_handtekening_mislukt'
        ),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();
    }

    try {
      let image = await this.fileService.uploadTemporaryFile({
        file: (<unknown>report.signatureMechanic) as Blob,
      });
      report.signatureMechanic = image.Image;
    } catch (error) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('toevoegen_handtekening_mislukt'),
        message: this.translateService.instant(
          'toevoegen_handtekening_mislukt'
        ),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();
    }

    if (this.tempImages != null) {
      if (report.images == null) report.images = [];

      this.tempImages.forEach((element) => {
        report.images.push(element);
      });
    }

    try {
      const result = await this.disableFormDuringApiCall(() =>
        this.workOrderService.create(report)
      );

      if (result != null) {
        const alert = await this.alertController.create({
          header: this.translateService.instant('werkbon_invoeren'),
          message: this.translateService.instant('werkbon_toegevoegd'),
          buttons: [this.translateService.instant('ok')],
        });

        await alert.present();
      } else {
        const alert = await this.alertController.create({
          header: this.translateService.instant('werkbon_invoeren'),
          message: this.translateService.instant('werkbon_toegevoegd_offline'),
          buttons: [this.translateService.instant('ok')],
        });

        await alert.present();
      }

      this.appSettings.currentWorkOrder = null;
      this.appSettings.currentWorkOrderListData = null;

      this.navController.navigateBack('/home');
    } catch (exception) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('error'),
        message: this.translateService.instant('error_toevoegen_werkbon'),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();

      this.isSaving = false;
    }
  }

  private compareStartAndEndDates(formGroup: AbstractControl<any, any>) {
    const startDate = formGroup.get('startDate');
    const endDate = formGroup.get('endDate');

    if (!startDate || !endDate) return null;

    const startDateValue = startDate.value as Date;
    const endDateValue = endDate.value as Date;

    if (endDateValue > startDateValue) {
      return null;
    } else {
      return { 'invalid-dates': true };
    }
  }

  deletePic(picture: ImageModel) {
    var startindex = this.tempImages.indexOf(picture);
    if (startindex >= 0) {
      this.tempImages.splice(startindex, 1);
    }
  }

  updateSignature(controlName: string, file: Blob | null) {
    this.form.controls[controlName].setValue(file);
  }

  async openCompanyModal(controlName: string) {
    const modal = await this.modalController.create({
      component: CompanyAutoCompleteModalComponent,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data !== null && typeof data !== 'undefined') {
      this.form.controls[controlName].setValue(data.Id);
      this.autoCompleteControls[controlName] = data;
    }
  }

  private setStatusOptions() {
    const keys = Object.keys(WorkOrderStatus).filter(k => typeof WorkOrderStatus[k as any] === "number");
    this.statusOptions = keys.map(k => WorkOrderStatus[k as any]);
}
}
