import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { catchError, flatMap, mergeMap } from 'rxjs/operators';
import { firstValueFrom, throwError } from 'rxjs';
import { ISerenitySaveResponse, ISerenityListResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { Constants } from 'src/app/constants';
import { OfflineEventService } from 'src/app/services/offline-event.service';
import { IShipZoneComponent } from 'src/app/shared/models/shipZoneComponent';
import { IComponentDetailModel } from 'src/app/shared/models/IComponentDetailModel';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ZoneComponentService {

    constructor(private http: HttpService,
        private storage: StorageService,
        private networkService: NetworkService,
        private offlineEventService: OfflineEventService) { }

    async list(ShipId: number): Promise<IShipZoneComponent[]> {
        const http$ = await this.http.post<ISerenityListResponse<IShipZoneComponent>>(`${environment.baseUrl}/api/ShipZoneComponent/GetShipZones`, { ShipId });
        const storageKey = `${Constants.sqlStorageKeys.shipComponentZones}${ShipId}`;

        return firstValueFrom(http$
            .pipe(mergeMap(async (result) => {
                await this.storage.set(storageKey, result.Entities);
                return result.Entities;
            })).pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        return await this.storage.get<IShipZoneComponent[]>(storageKey);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };

    async Details(componentId: number): Promise<IComponentDetailModel> {
        const http$ = await this.http.post<ISerenityListResponse<IComponentDetailModel>>(`${environment.baseUrl}/api/ShipZoneComponent/GetComponentDetails`, { componentId });
        const storageKey = `${Constants.sqlStorageKeys.shipComponentDetails}${componentId}`;

        return firstValueFrom(http$
            .pipe(mergeMap(async (result) => {
                await this.storage.set(storageKey, result.Entities);
                if(result.Entities.length > 0)
                    return result.Entities[0];
                else
                    return {} as IComponentDetailModel;
            })).pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        return await this.storage.get<IComponentDetailModel>(storageKey);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };
    
}