import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from "../../../environments/environment";
import { ISerenitySaveResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { IChangePasswordModel } from 'src/app/shared/models/ChangePassword';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(private http: HttpService) { }

    async update(model: { password: string }) {
        return await this.http.post<ISerenitySaveResponse>(`${environment.baseUrl}/api/User/Update`, { Entity: model });
    }
}
