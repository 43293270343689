import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ISerenityListResponse, ISerenitySaveResponse } from '../shared/models/serenity/SerenityResponse';
import { ICargoEntity, ICargo } from '../shared/models/Cargo';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Constants } from '../constants';
import { OfflineEventService } from './offline-event.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { firstValueFrom, throwError } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { LocalAppSettingsService } from './local-app-settings.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class CargoService {

    constructor(private http: HttpService,
        private storage: StorageService,
        private networkService: NetworkService,
        private appSettings: LocalAppSettingsService,
        private offlineEventService: OfflineEventService,
        private loadingController: LoadingController) { }

    async list(take = 100,  containsText?: string, includeColumns?: string[]): Promise<ICargoEntity[]> {
        const loader = await this.loadingController.create({
            keyboardClose: false
        });
        const companyId = this.appSettings.ship.CompanyId;

        await loader.present();
        const http$ = await this.http.post<ISerenityListResponse<ICargoEntity>>(`${environment.baseUrl}/api/Cargo/List`, {take, containsText, includeColumns, equalityFilter: { companyId }});

        return firstValueFrom(http$
            .pipe(mergeMap(async (result) => {
                await this.storage.set(Constants.sqlStorageKeys.cargo, result.Entities);
                return result.Entities;
            }))
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        const cargo = await this.storage.get<ICargoEntity[]>(Constants.sqlStorageKeys.cargo);

                        return containsText?.length
                            ? cargo.filter(c => c.Cargo.includes(containsText))
                            : cargo;
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ).pipe(finalize(async () => await loader.dismiss())));
    }

    async create(cargo: ICargo) {
        const url = `${environment.baseUrl}/api/Cargo/Create`;
        const data =  {Entity: cargo};
        const $http = await this.http.post<ISerenitySaveResponse>(url, data);
        return firstValueFrom($http.
            pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        await this.offlineEventService.storeRequest(url, "post", data);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    }
}
