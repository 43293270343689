import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ZoneComponentService } from './services/zone-component.service';
import { BaseComponent } from 'src/app/shared/classes/BaseComponent';
import { Platform, NavController } from '@ionic/angular';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { IShipZoneComponent, IZoneComponent } from 'src/app/shared/models/shipZoneComponent';
import { MaintanenceEventService } from './services/maintanance-event.service';
import { ShipLayoutService } from 'src/app/services/ship-layout.service';
import { IComponentTaskDetailModel } from 'src/app/shared/models/IComponentTaskDetailModel';
import { ReadingCalculationService } from 'src/app/services/reading-calculation.service';
import { Printer } from '@bcyesil/capacitor-plugin-printer';
import { Constants } from 'src/app/constants';

@Component({
    selector: 'app-maintainance',
    templateUrl: './maintainance.component.html',
    styleUrls: ['./maintainance.component.scss'],
})
export class MaintainanceComponent extends BaseComponent implements OnInit {

    @ViewChild('printpop') printElement!: ElementRef<HTMLElement>;
    
    zoneComponents: IShipZoneComponent[] = [];
    layoutImage?: string;
    shipName: string = '';
    printMode: boolean = false;

    printZoneData: IShipZoneComponent[] = [];
    printComponentTaskData: IComponentTaskDetailModel[] = [];
    showPrintPopup: boolean = false;

    constructor(navController: NavController,
        private platform: Platform,
        private appSettings: LocalAppSettingsService,
        private zoneComponentService: ZoneComponentService,
        private shipLayoutService: ShipLayoutService,
        public readingCalculationService: ReadingCalculationService,
        private maintanenceEventService: MaintanenceEventService) {
            super(navController);
        }

    ngOnInit() {
        this.loadShipDetail();
        this.loadZoneComponents();

        this.maintanenceEventService.refreshData.subscribe(() => {
            this.loadZoneComponents();
        });
    }

    loadShipDetail(){
        this.shipName = this.appSettings.ship.Name;

        this.shipLayoutService.retrieve(this.appSettings.ship.ShipLayoutId).then((shipLayout) => {
            this.layoutImage = shipLayout.Layout;
        });
    }

    loadZoneComponents(){
        this.zoneComponentService.list(this.appSettings.ship.Id).then((zones) => {
            
            zones.forEach((zone) => {
                zone.Checked = false;
                zone.Components.forEach((component) => {
                    component.Checked = false;
                });
            });
            
            this.zoneComponents = zones as IShipZoneComponent[];
        });
    }
    
    getFirstZoneName(): string {
        return this.zoneComponents.length > 0 ? this.zoneComponents[0].ZoneName : '';
    }

    navigateToComponentDetails(componentId: number) {
        this.navigateForward(`/home/maintainance/details/${componentId}`);        
    }

    stopPropagation = ($event: Event) => {
        $event.stopPropagation();        
    }

    zoneSelected = (zone: IShipZoneComponent) => {
        zone.Components.forEach((component) => {            
            if(component.Status == Constants.componentStatus.Overdue || component.Status == Constants.componentStatus.Warning){
                component.Checked = zone.Checked;
            }
        });
    }

    startPrint = async () => {
        let self = this;

        self.printZoneData = []
        self.printComponentTaskData = []

        for (let i = 0; i < self.zoneComponents.length; i++) {
            let zone = self.zoneComponents[i]

            if(zone.Checked){
                self.printZoneData.push(zone);

                for (let i = 0; i < zone.Components.length; i++) {
                    let component = zone.Components[i]
                    if(component.Checked){
                        let details = await this.zoneComponentService.Details(component.ComponentId);

                        self.printComponentTaskData = [...self.printComponentTaskData, ...details.Tasks]
                    }
                }

            }
        }
        
        self.showPrintPopup = true;

        setTimeout(() => {
            if (this.platform.is('ios') || this.platform.is('android')) {
                let content  = self.printElement.nativeElement.innerHTML;
                
                setTimeout(() => {
                    Printer.print({ content: content, name: 'Print', orientation: 'portrait' })

                    setTimeout(() => {
                        self.showPrintPopup = false;
                    }, 500);
                }, 1000);
                
            }else{
                let content  = self.printElement.nativeElement.innerHTML;
                let h = self.printElement.nativeElement.clientHeight;
                let w = self.printElement.nativeElement.clientWidth;

                var mywindow = window.open('', 'PRINT', 'height=' + h + ',width=' + w)!;
                mywindow.document.write('<html class="plt-desktop md hydrated"><head><title>Onderhoud</title>');
                mywindow.document.write('<link rel="stylesheet" href="/styles.css" type="text/css" />');
                mywindow.document.write('<style>@media print{ html { overflow: visible !important; }} body { overflow: scroll; max-height: 100%; overscroll-behavior-y: contain; position: relative; }</style>');
                mywindow.document.write('</head><body class="md hydrated">');
                mywindow.document.write(content);
                mywindow.document.write('</body></html>');

                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/

                
                setTimeout(() => {
                    mywindow.print();

                    setTimeout(() => {
                        mywindow.close();
                        self.showPrintPopup = false;
                    }, 750);
                }, 750);
                
            }            
        }, 750);
        
    }

    taskSelected = ($event: any, zone: IShipZoneComponent) => {
        if($event.detail.checked)
            zone.Checked = true
    }


    componentIsVisible = (componentId: number) => {
        return this.printComponentTaskData.some((detail) => detail.ComponentId === componentId)
    }

    getSelectedComponents = (zoneComponents: IZoneComponent[]) => {
        return zoneComponents.filter((component) => this.componentIsVisible(component.ComponentId))
    }

    getComponentTasksByComponentId = (componentId: number) => {        
        return this.printComponentTaskData.filter((detail) => detail.ComponentId === componentId);
    }

    getSelectedZones = () => {
        let self = this;
        return this.printZoneData.filter((zone) => zone.Checked && self.getSelectedComponents(zone.Components).some((zoneComponent: IZoneComponent) => self.getComponentTasksByComponentId(zoneComponent.ComponentId).length > 0))
    }
}