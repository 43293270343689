import { Component, OnInit } from '@angular/core';
import { NavController, RefresherCustomEvent } from '@ionic/angular';
import { JourneyReportService } from './journey-report.service';
import { BaseComponent } from 'src/app/shared/classes/BaseComponent';
import { IJourneyReportEntity } from 'src/app/shared/models/JourneyReport';
import * as moment from "moment"
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { JourneyReportArchiveContainer } from 'src/app/shared/classes/JourneyReportArchiveContainer';

@Component({
    selector: 'journey-reports',
    templateUrl: './journey-reports.component.html',
    styleUrls: ['./journey-reports.component.scss'],
})
export class JourneyReportsComponent extends BaseComponent implements OnInit {
    journeyReports: JourneyReportArchiveContainer[] = [];

    private readonly monthsBackInTime = 6;

    constructor(navController: NavController,
        private journeyReportService: JourneyReportService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    async ngOnInit() {
        moment.locale(this.appSettings.selectedLanguage);

        await this.initializeReportArchive();
    }

    private async initializeReportArchive() {
        const start = moment().subtract(this.monthsBackInTime, 'months').startOf('month');
        const end = moment().endOf('month');

        const reportContainers: JourneyReportArchiveContainer[] = [];

        for (let i = 0; i <= this.monthsBackInTime; i++) {
            let date = moment().subtract(i, 'month');
            reportContainers.push(new JourneyReportArchiveContainer(date.month() + 1, moment.months()[date.month()], date.year()));
        }

        const reports = await this.journeyReportService.listBetweenDates(start.toISOString(), end.toISOString());

        reports!.forEach((report) => {
            const departmentDate = moment(report.DepartmentDate);

            for (let i = 0; i < reportContainers.length; i++) {
                const reportContainer = reportContainers[i];

                if (reportContainer.month === departmentDate.month() + 1 && reportContainer.year === departmentDate.year()) {
                    reportContainer.reports.push(report);
                    break;
                };
            };
        });

        this.journeyReports = reportContainers;
    }

    async refresh(event: RefresherCustomEvent) {
        await this.initializeReportArchive();
        event.target.complete();
    }

    navigateToReportDetail(id: number) {
        this.navController.navigateForward(`/home/journey-report/${id}`);
    }
}
