<home-toolbar [showBackButton]="true" [title]="'planning' | translate"></home-toolbar>

<ion-content (window:resize)="setupUnitsForDeviceWidth()">
    <div class="container">
        <div class="w-100 d-flex justify-content-center">
            <div class="planning-header">
                <div class="arrow-wrapper ion-activatable" (click)="previousCalendar()">
                    <ion-ripple-effect type="unbounded" class="sc-ion-back-button-md unbounded hydrated"
                        role="presentation"></ion-ripple-effect>

                    <ion-icon class="arrow" name="arrow-back">
                    </ion-icon>
                </div>

                <h1 class="d-none d-md-inline text-uppercase mb-0"> {{'week' | translate}} {{startWeek}} - {{endWeek}} | {{dateContext.year()}}</h1>
                <h1 class="d-inline d-md-none text-uppercase mb-0"> {{dateContext.date()}} {{currentMonthName}} {{dateContext.year()}} </h1>

                <div class="arrow-wrapper ion-activatable" (click)="nextCalendar()">
                    <ion-ripple-effect type="unbounded" class="sc-ion-back-button-md unbounded hydrated"
                        role="presentation"></ion-ripple-effect>

                    <ion-icon class="arrow arrow-forward" name="arrow-back">
                    </ion-icon>
                </div>
            </div>

        </div>
        <div class="d-flex d-md-none justify-content-center align-items-center">
            <h2 class="text-uppercase">{{currentDayName}}</h2>
        </div>

        <div class="d-none d-md-flex row calendar-row" *ngFor="let week of weeks">
            <div class="ship-roles">
                <div class="calendar-head">
                    <div class="date">
                        <span class="week-number">{{'week' | translate}} {{week.weekNr}}</span>
                    </div>
                </div>
                <div class="ship-role-wrapper" *ngFor="let role of shipRoles">
                    <div class="ship-role"></div>
                    <div class="ship-role-name">
                        {{role.Name}}
                    </div>
                </div>
            </div>
            <div class="calendar-col" *ngFor="let day of week.days">
                <div class="calendar-head">
                    <div class="date">
                        {{day.day}} {{day.monthName}} <br />
                        <span class="day">{{day.dayName}}</span>
                    </div>
                </div>


                <div *ngFor="let role of shipRoles">
                    <planning-item [date]="day.date" [role]="role" [display]="'tablet'">

                    </planning-item>
                </div>
            </div>
        </div>

        <!-- MOBILE ONLY -->
        <div class="d-flex d-md-none">
            <div class="ship-roles">
                <div class="calendar-head">
                    <div class="date">
                        &nbsp;
                    </div>
                </div>
                <div class="ship-role-wrapper" *ngFor="let role of shipRoles">
                    <div class="ship-role"></div>
                    <div class="ship-role-name">
                        {{role.Name}}
                    </div>
                </div>
            </div>
            <div class="calendar-col">
                <div class="calendar-head">
                    <div class="date">
                        &nbsp;
                    </div>
                </div>

                <div *ngFor="let role of shipRoles">
                    <planning-item [date]="dateContext.toISOString()" [role]="role" [display]="'mobile'">

                    </planning-item>
                </div>
            </div>
        </div>
    </div>
</ion-content>
