import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/classes/BaseComponent';
import { NavController, AlertController, RefresherCustomEvent } from '@ionic/angular';
import { RepairService } from './repair.service';
import { IRepair } from 'src/app/shared/models/Repair';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-repairs',
    templateUrl: './repairs.component.html',
    styleUrls: ['./repairs.component.scss'],
})
export class RepairsComponent extends BaseComponent implements OnInit {

    get repairs(): Observable<IRepair[]> {
        return this.repairService.repairs;
    }

    constructor(navController: NavController,
        private repairService: RepairService,
        private alertController: AlertController,
        private translateService: TranslateService) {
        super(navController);
    }

    async ngOnInit() {
        await this.repairService.getRepairs();
    }

    async navigateToRepair(id: number)  {
        if (id === -1){
            const alert = await this.alertController.create({
                header: this.translateService.instant('reparaties'),
                message: this.translateService.instant('reparatie_toegevoegd_offline'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        } else {
            this.navigateForward('/home/repairs/edit/'+ id)
        }
    }

    async refresh(event: RefresherCustomEvent) {
        await this.repairService.getRepairs();
        event.target.complete();
    }
}
