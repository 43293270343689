import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Constants } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BaseFormComponent } from 'src/app/shared/classes/BaseFormComponent';
import { IForgotPasswordModel } from 'src/app/shared/models/ForgotPassword';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends BaseFormComponent implements OnInit {

    constructor(navContoller: NavController,
        private alertController: AlertController,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        // private firebase: FirebaseX
    ) {
            super(navContoller);
        }

    ngOnInit() {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required,Validators.pattern(Constants.regexes.email), Validators.maxLength(60)]),
        });
    }

    async submit(value: IForgotPasswordModel) {

        try {
            await this.disableFormDuringApiCall(() => this.authenticationService.forgotPassword(value));

            const alert = await this.alertController.create({
                header: this.translateService.instant("wachtwoord_vergeten"),
                message:  this.translateService.instant("wachtwoord_vergeten_instructies"),
                buttons: ['OK']
            });

            await alert.present();

            this.navigateBack("/account/login");
        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant("wachtwoord_vergeten"),
                message:  this.translateService.instant("error_wachtwoord_vergeten"),
                buttons: ['OK']
            });

            await alert.present();
        }
    }
}
