import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Disposable } from 'src/app/shared/classes/Disposable';
import { Constants } from 'src/app/constants';

@Component({
    selector: 'taskStatus-component',
    templateUrl: './taskStatus.component.html',
    styleUrls: ['./taskStatus.component.scss'],
})
export class TaskStatusComponent extends Disposable implements OnInit {

    @Input() status!: number;

    constructor(private route: ActivatedRoute) {
        super();

    }

    ngOnInit() {
        
    }

    getStatus(status: number) : string  {
        switch(status){
            case Constants.componentStatus.Ok:
                return 'ok';
            case Constants.componentStatus.Warning:
                return 'warning';
            case Constants.componentStatus.Overdue:
                return 'overdue';
            default:
                return 'ok';
        }
    }

}