import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HomePage } from './home.page';
import { SharedModule } from '../shared/shared.module';
import { HomeRoutingModule } from './home.routing.module';
import { RepairsComponent } from './repairs/repairs.component';
import { AddRepairComponent } from './repairs/add-repair/add-repair.component';
import { EditRepairComponent } from './repairs/edit-repair/edit-repair.component';
import { JourneyReportsComponent } from './journey-reports/journey-reports.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { DeclarationsComponent } from './declarations/declarations.component';
import { LiveLocationsComponent } from './live-locations/live-locations.component';
import { PlanningComponent } from './planning/planning.component';
import { AddJourneyReportComponent } from './journey-reports/add-journey-report/add-journey-report.component';
import { JourneyReportDetailComponent } from './journey-reports/journey-report-detail/journey-report-detail.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ChooseShipComponent } from './choose-ship/choose-ship.component';
import { InspectionReportsComponent } from './inspection-reports/inspection-reports.component';
import { TwoDecimalNumberDirective } from './declarations/twodecimal.directive';
import { WorkOrdersComponent } from './work-order/work-orders.component';
import { WorkOrderDetailComponent } from './work-order/work-order-detail/work-order-detail.component';
import { AddWorkOrderComponent } from './work-order/add-work-order/add-work-order.component';
import { HandOverComponent } from './handover/handover.component';
import { AddHandOverComponent } from './handover/add-handover/add-handover.component';
import { HandOverDetailComponent } from './handover/handover-detail/handover-detail.component';

@NgModule({
    imports: [
        SharedModule,
        HomeRoutingModule
    ],
    declarations: [
        HomePage,
        JourneyReportsComponent,
        JourneyReportDetailComponent,
        AddJourneyReportComponent,
        RepairsComponent,
        AddRepairComponent,
        EditRepairComponent,
        DeclarationsComponent,
        CertificatesComponent,
        LiveLocationsComponent,
        PlanningComponent,
        MyAccountComponent,
        InspectionReportsComponent,
        TwoDecimalNumberDirective,
        ChooseShipComponent,
        WorkOrdersComponent,
        WorkOrderDetailComponent,
        AddWorkOrderComponent,
        HandOverComponent,
        AddHandOverComponent,
        HandOverDetailComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomePageModule { }
