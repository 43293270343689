import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../../../shared/classes/BaseFormComponent';
import {
  NavController,
  AlertController,
  ModalController,
} from '@ionic/angular';
import { IJourneyReport } from '../../../shared/models/JourneyReport';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { IBarge } from '../../../shared/models/Barge';
import { TranslateService } from '@ngx-translate/core';
import { JourneyReportService } from '../journey-report.service';
import { PortAutoCompleteModalComponent } from 'src/app/shared/components/port-auto-complete-modal/port-auto-complete-modal.component';
import { CargoAutoCompleteModalComponent } from 'src/app/shared/components/cargo-auto-complete-modal/cargo-auto-complete-modal.component';
import * as moment from 'moment';
import { Constants } from 'src/app/constants';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { FileChangeEvent } from 'src/app/shared/classes/FileChangeEvent';
import { FileService } from 'src/app/services/file.service';
import { ImageModel } from '../../../shared/models/ImageModel';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-journey-report',
  templateUrl: './add-journey-report.component.html',
  styleUrls: ['./add-journey-report.component.scss'],
})
export class AddJourneyReportComponent
  extends BaseFormComponent
  implements OnInit
{
  addBargeForm!: FormGroup;
  barges: IBarge[] = [];
  pictures: (File | Blob)[] = [];
  tempImages: ImageModel[] = [];
  imageHostUrl: string;
  showErrors: boolean = false;
  currentLanguage: string = '';

  autoCompleteControls: { [key: string]: any } = {
    dischargePortId: null,
    loadingPortId: null,
    cargoId: null,
  };

  readonly minimalDate = moment().subtract(1, 'month');

  constructor(
    navController: NavController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private journeyReportService: JourneyReportService,
    private appSettings: LocalAppSettingsService,
    private modalController: ModalController,
    private fileService: FileService
  ) {
    super(navController);

    this.imageHostUrl = environment.baseUrl;
  }

  ngOnInit() {
    var loadingPortId = '';
    var dischargePortId = '';
    var cargoId = null;
    var departmentDate: Date | string = this.today.toISOString();
    var arrivalDate: Date | string = this.today.toISOString();
    var tonnage = null;
    var oilLevelStart = null;
    var oilLevelEnd = null;
    var bunkeredAmount = null;
    var shipId = null;

    if (this.appSettings.currentJourneyReport != null) {
      loadingPortId = this.appSettings.currentJourneyReport.loadingPortId;
      dischargePortId = this.appSettings.currentJourneyReport.dischargePortId;
      cargoId = this.appSettings.currentJourneyReport.cargoId;
      departmentDate = this.appSettings.currentJourneyReport.departmentDate;
      arrivalDate = this.appSettings.currentJourneyReport.arrivalDate;
      tonnage = this.appSettings.currentJourneyReport.tonnage;
      oilLevelStart = this.appSettings.currentJourneyReport.oilLevelStart;
      oilLevelEnd = this.appSettings.currentJourneyReport.oilLevelEnd;
      bunkeredAmount = this.appSettings.currentJourneyReport.bunkeredAmount;
      this.barges = this.appSettings.currentJourneyReport.barges;
      shipId = this.appSettings.currentJourneyReport.shipId;

      this.tempImages = this.appSettings.currentJourneyReport.images;
    }

    if (this.appSettings.currentJourneyReportListData != null) {
      this.autoCompleteControls = <any>(
        this.appSettings.currentJourneyReportListData
      );
    }

    this.form = new FormGroup(
      {
        loadingPortId: new FormControl(loadingPortId, [Validators.required]),
        dischargePortId: new FormControl(dischargePortId, [
          Validators.required,
        ]),
        departmentDate: new FormControl(departmentDate, [Validators.required]),
        arrivalDate: new FormControl(arrivalDate, [Validators.required]),
        cargoId: new FormControl(cargoId, [Validators.required]),
        tonnage: new FormControl(tonnage, [
          Validators.required,
          Validators.min(0),
          Validators.pattern(Constants.regexes.digits),
        ]),
        oilLevelStart: new FormControl(oilLevelStart, [
          Validators.required,
          Validators.min(1),
          Validators.pattern(Constants.regexes.digits),
        ]),
        oilLevelEnd: new FormControl(oilLevelEnd, [
          Validators.required,
          Validators.min(1),
          Validators.pattern(Constants.regexes.digits),
        ]),
        bunkeredAmount: new FormControl(bunkeredAmount, [
          Validators.min(0),
          Validators.pattern(Constants.regexes.digits),
        ]),
      },
      { validators: [this.compareArrivalAndDepartmentDates] }
    );

    this.addBargeForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      tonnage: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.pattern(Constants.regexes.digits),
      ]),
    });

    this.currentLanguage = this.appSettings.selectedLanguage;
    this.subscriptions.push(
      this.appSettings.getLanguageObservable().subscribe((x) => {
        this.currentLanguage = x;
      })
    );
  }

  computeTotal() {
    const oilLevelStart = this.form.controls['oilLevelStart'].value;
    const bunkeredAmount = this.form.controls['bunkeredAmount'].value;
    const oilLevelEnd = this.form.controls['oilLevelEnd'].value;

    return oilLevelStart + bunkeredAmount - oilLevelEnd;
  }

  pictureChanged(event: FileChangeEvent) {
    if (event.file != null) {
      this.pictures.push(event.file);
    } else if (event.index != null) {
      this.pictures.splice(event.index, 1);
    }
  }

  async openPortModal(controlName: string) {
    const modal = await this.modalController.create({
      component: PortAutoCompleteModalComponent,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data !== null && typeof data !== 'undefined') {
      this.form.controls[controlName].setValue(data.Id);
      this.autoCompleteControls[controlName] = data;
    }
  }

  async openCargoModal(controlName: string) {
    const modal = await this.modalController.create({
      component: CargoAutoCompleteModalComponent,
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data !== null && typeof data !== 'undefined') {
      this.form.controls[controlName].setValue(data.Id);
      this.autoCompleteControls[controlName] = data;
    }
  }

  async saveReport() {
    if (!this.appSettings.ship) return;

    const alert = await this.alertController.create({
      header: this.translateService.instant('reisrapportopgeslagen'),
      message: this.translateService.instant(
        'reisrapportopgeslagen_instructies'
      ),
      buttons: ['OK'],
    });

    this.appSettings.currentJourneyReport = {
      loadingPortId: this.form.controls['loadingPortId'].value,
      dischargePortId: this.form.controls['dischargePortId'].value,
      cargoId: this.form.controls['cargoId'].value,
      departmentDate: this.form.controls['departmentDate'].value,
      arrivalDate: this.form.controls['arrivalDate'].value,
      tonnage: this.form.controls['tonnage'].value,
      oilLevelStart: this.form.controls['oilLevelStart'].value,
      oilLevelEnd: this.form.controls['oilLevelEnd'].value,
      bunkeredAmount: this.form.controls['bunkeredAmount'].value,
      barges: this.barges,
      shipId: this.appSettings.ship.Id,
      images: await Promise.all(
        this.pictures.map((file) =>
          this.fileService.uploadTemporaryFile({ file })
        )
      ),
    };

    this.appSettings.currentJourneyReportListData = this.autoCompleteControls;

    await alert.present();
    return false;
  }

  async submit(report: IJourneyReport) {
    if (!this.form.valid || !this.appSettings.ship) {
      this.showErrors = true;
      return;
    }

    report.barges = this.barges;
    report.shipId = this.appSettings.ship.Id;

    if (report.bunkeredAmount == null) report.bunkeredAmount = 0;

    try {
      if (this.pictures.length > 0) {
        report.images = await Promise.all(
          this.pictures.map((file) =>
            this.fileService.uploadTemporaryFile({ file })
          )
        );
      }
    } catch (error) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('toevoegen_afb_mislukt'),
        message: this.translateService.instant('toevoegen_afb_mislukt'),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();
    }

    if (this.tempImages != null) {
      if (report.images == null) report.images = [];

      this.tempImages.forEach((element) => {
        report.images.push(element);
      });
    }

    try {
      const result = await this.disableFormDuringApiCall(() =>
        this.journeyReportService.create(report)
      );

      if (result != null) {
        const alert = await this.alertController.create({
          header: this.translateService.instant('reisrapport_invoeren'),
          message: this.translateService.instant('reisrapport_toegevoegd'),
          buttons: [this.translateService.instant('ok')],
        });

        await alert.present();
      } else {
        const alert = await this.alertController.create({
          header: this.translateService.instant('reisrapport_invoeren'),
          message: this.translateService.instant(
            'reisrapport_toegevoegd_offline'
          ),
          buttons: [this.translateService.instant('ok')],
        });

        await alert.present();
      }

      this.appSettings.currentJourneyReport = null;
      this.appSettings.currentJourneyReportListData = null;

      this.navController.navigateBack('/home');
    } catch (exception) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('error'),
        message: this.translateService.instant('error_toevoegen_reisrapport'),
        buttons: [this.translateService.instant('ok')],
      });

      this.isSaving = false;
    }
  }

  addBarge(barge: IBarge) {
    this.barges.push(barge);
    this.addBargeForm.reset();
  }

  deleteBarge(index: number) {
    this.barges.splice(index, 1);
  }

  private compareArrivalAndDepartmentDates(
    formGroup: AbstractControl<any, any>
  ) {
    const departmentDate = formGroup.get('departmentDate');
    const arrivalDate = formGroup.get('arrivalDate');

    if (!departmentDate || !arrivalDate) return null;

    const departmentDateValue = departmentDate.value as Date;
    const arrivalDateValue = arrivalDate.value as Date;

    if (arrivalDateValue > departmentDateValue) {
      return null;
    } else {
      return { 'invalid-dates': true };
    }
  }

  deletePic(picture: ImageModel) {
    var startindex = this.tempImages.indexOf(picture);
    if (startindex >= 0) {
      this.tempImages.splice(startindex, 1);
    }
  }
}
