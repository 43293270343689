import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { }

    async get<T>(key: string, defaultValue?: T) {
        const result = await Preferences.get({ key })
        return ((result.value ? JSON.parse(result.value) : result.value) ?? defaultValue) as T;
    }

    async set<T>(key: string, value: T) {
        await Preferences.set({ key, value: JSON.stringify(value) });
    }

    async remove(key: string) {
        await Preferences.remove({ key });
    }
}
