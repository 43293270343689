import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import {
  ISerenityListResponse,
  ISerenitySaveResponse,
} from '../shared/models/serenity/SerenityResponse';
import { IPortEntity, IPort } from '../shared/models/Port';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Constants } from '../constants';
import { OfflineEventService } from './offline-event.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { firstValueFrom, throwError } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { LocalAppSettingsService } from './local-app-settings.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class PortService {
  constructor(
    private http: HttpService,
    private storage: StorageService,
    private networkService: NetworkService,
    private offlineEventService: OfflineEventService,
    private appSettings: LocalAppSettingsService,
    private loadingController: LoadingController
  ) {}

  async list(
    take = 100,
    containsText?: string,
    includeColumns?: string[]
  ): Promise<IPortEntity[]> {
    const loader = await this.loadingController.create({
      keyboardClose: false,
    });
    const companyId = this.appSettings.ship.CompanyId;

    await loader.present();
    const http$ = await this.http.post<ISerenityListResponse<IPortEntity>>(
      `${environment.baseUrl}/api/Port/List`,
      { take, containsText, includeColumns, equalityFilter: { companyId } },
      false
    );

    return firstValueFrom(
      http$
        .pipe(
          mergeMap(async (result) => {
            await this.storage.set(
              Constants.sqlStorageKeys.ports,
              result.Entities
            );
            return result.Entities;
          })
        )
        .pipe(
          catchError(async (error) => {
            if (
              this.networkService.getCurrentNetworkStatus() ===
              ConnectionStatus.Offline
            ) {
              const ports = (await this.storage.get<IPortEntity[]>(
                Constants.sqlStorageKeys.ports
              ));

              return containsText?.length
                ? ports.filter(
                    (p) =>
                      p.Name.includes(containsText) ||
                      p.City.includes(containsText)
                  )
                : ports;
            } else {
              return firstValueFrom(throwError(error));
            }
          })
        )
        .pipe(finalize(async () => await loader.dismiss()))
    );
  }

  async create(port: IPort) {
    const url = `${environment.baseUrl}/api/Port/Create`;
    const data = { Entity: port };
    const $http = await this.http.post<ISerenitySaveResponse>(url, data);
    return firstValueFrom($http
      .pipe(
        catchError(async (error) => {
          if (
            this.networkService.getCurrentNetworkStatus() ===
            ConnectionStatus.Offline
          ) {
            await this.offlineEventService.storeRequest(url, 'post', data);
          } else {
            return firstValueFrom(throwError(() => error));
          }
        })
      ));
  }
}
