import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ISerenitySaveResponse } from '../shared/models/serenity/SerenityResponse';
import { catchError } from 'rxjs/operators';
import { Subject, firstValueFrom, throwError } from 'rxjs';
import { LocalAppSettingsService } from './local-app-settings.service';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { Disposable } from '../shared/classes/Disposable';

@Injectable({
  providedIn: 'root',
})
export class FcmService extends Disposable {
  onNotificationPressed = new Subject<ActionPerformed>();
  onNotificationRecieved = new Subject<PushNotificationSchema>();

  constructor(
    private http: HttpClient,
    private appSettings: LocalAppSettingsService
  ) {
    super();
  }

  async getToken(userId: number) {
    PushNotifications.addListener('registration', async (token: Token) => {
      await this.saveToken(token.value, userId);
    });

    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.onNotificationRecieved.next(notification);
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.onNotificationPressed.next(notification);
      }
    );

    let hasPermission = await PushNotifications.checkPermissions();
    if (hasPermission.receive != 'granted') {
      // no permission yet, asking permission now
      hasPermission = await PushNotifications.requestPermissions();
    }
    if (hasPermission) {
      await PushNotifications.register();
      this.appSettings.pushNotificationsEnabled = true;
    }
  }

  private async saveToken(token: string, userId: number) {
    if (!token) return;

    await firstValueFrom(
      this.http
        .post<ISerenitySaveResponse>(
          `${environment.baseUrl}/api/PushToken/Create`,
          { Entity: { Token: token, UserId: userId } }
        )
        .pipe(
          catchError((error) => {
            return throwError(() => error);
          })
        )
    );
  }
}
