import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../../shared/classes/BaseFormComponent';
import { NavController, AlertController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileChangeEvent } from '../../shared/classes/FileChangeEvent';
import { TranslateService } from '@ngx-translate/core';
import { IDeclaration } from '../../shared/models/Declaration';
import { DeclarationService } from './declaration.service';
import { FileService } from 'src/app/services/file.service';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { Constants } from 'src/app/constants';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { ImageModel } from 'src/app/shared/models/ImageModel';

@Component({
    selector: 'app-declarations',
    templateUrl: './declarations.component.html',
    styleUrls: ['./declarations.component.scss'],
})
export class DeclarationsComponent extends BaseFormComponent implements OnInit {

    pictures: (File | Blob)[] = [];
    isOnline: boolean = false;

    constructor(navController: NavController,
        private alertController: AlertController,
        private translateService: TranslateService,
        private declarationService: DeclarationService,
        private fileService: FileService,
        private networkService: NetworkService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    ngOnInit() {
        this.isOnline = this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online;

        this.form = new FormGroup({
            name: new FormControl('', [Validators.required]),
            description: new FormControl('', [Validators.required]),
            totalPrice: new FormControl('', [Validators.required, Validators.min(1), Validators.pattern(Constants.regexes.currency)]),
        })
    }

    async submit(declaration: any) {

        let images: ImageModel[] = [];
        
        if (this.pictures.length > 0) {
            images = await Promise.all(this.pictures.map((file) => this.fileService.uploadTemporaryFile({ file })));
        }

        if (!this.appSettings.ship)
            return;

        const shipDeclaration: IDeclaration = { 
            CompanyId: this.appSettings.ship.CompanyId,
            ShipId: this.appSettings.ship.Id,
            Images: images,
            TotalPrice: parseFloat(declaration.totalPrice.toString().replace(',', '.')), // on some devices twoDecimalNumber doesnt work
            Description: declaration.description,
            Name: declaration.name
        };

        try {
            const result = await this.disableFormDuringApiCall(() => this.declarationService.create(shipDeclaration));

            if (result) {
                const alert = await this.alertController.create({
                    header: this.translateService.instant('declaraties'),
                    message: this.translateService.instant('declaratie_ingediend'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
            } else {
                const alert = await this.alertController.create({
                    header: this.translateService.instant('declaraties'),
                    message: this.translateService.instant('declaratie_ingediend_offline'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
            }

            this.navController.navigateBack('/home');
        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('error'),
                message: this.translateService.instant('error_toevoegen_declaratie'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }
    }

    pictureChanged(event: FileChangeEvent) {
        if (event.file != null) {
            this.pictures.push(event.file);
        } else if (event.index != null) {
            this.pictures.splice(event.index, 1);
        }
    }
}
