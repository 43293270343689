export const environment = {
  production: false,
  name: "staging",
  baseUrl: "https://fleetcontrol.lucrasoft-staging.nl",
  googleMapsApi: {
    ios: 'AIzaSyAJDmwa_yNl0nmDLJAZG1EQ5Lepcbr0Wd0',
    android: 'AIzaSyB4lp73mX6ndO9fPBfE-gx8MavnzVEJc5E',
    web: 'AIzaSyAgVMoFkwgAMO4pNRUx_X4QQVCPZscxDoc'
  }
};
