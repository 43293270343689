import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Disposable } from 'src/app/shared/classes/Disposable';
import { ModalController, RefresherCustomEvent } from '@ionic/angular';
import { BaseFormComponent } from 'src/app/shared/classes/BaseFormComponent';
import { ZoneComponentService } from '../services/zone-component.service';
import { IComponentTaskDetailModel } from 'src/app/shared/models/IComponentTaskDetailModel';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import {NavController } from '@ionic/angular';
import { TaskService } from 'src/app/services/task.service';
import { IComponentReadingForm } from 'src/app/shared/models/IComponentReadingForm';

@Component({
    selector: 'app-maintainance-details',
    templateUrl: './maintainance-details.component.html',
    styleUrls: ['./maintainance-details.component.scss'],
})
export class MaintainanceDetailsComponent extends BaseFormComponent implements OnInit {

    // components: IShipEngineComponent[] = [];
    // readingDates: string[] = [];
    componentDetails?: IComponentTaskDetailModel[];
    componentId: number = -1;
    componentName: string = '';
    currentLanguage: string = '';

    constructor(navController: NavController, 
        private route: ActivatedRoute,
        private taskService: TaskService,
        private appSettings: LocalAppSettingsService,
        private zoneComponentService: ZoneComponentService,
        private modalController: ModalController) {
        super(navController);

    }

    ngOnInit() {
        this.currentLanguage = this.appSettings.selectedLanguage;
        var readingDate: Date | string = this.today.toISOString();
        let employeeName = '';
        let shipReading = '';        

        this.subscriptions.push(this.route.paramMap.subscribe(async params => {
            this.componentId = parseInt(params.get("id")!);
            await this.loadComponentDetails();
        }));        
        
        this.form = new FormGroup(
            {
                readingDate: new FormControl(readingDate, [Validators.required]),
                employeeName: new FormControl(employeeName, [Validators.required]),
                shipReading: new FormControl(shipReading, [Validators.required, Validators.min(1)])
            }
        );

    }

    loadComponentDetails(){
        this.zoneComponentService.Details(this.componentId).then((details) => {
            this.componentName = details.Name;
            this.componentDetails = details.Tasks;
        });
    }

    readingSent(){
        this.loadComponentDetails();
    }

    showGlobalHourForm(): boolean{
        return this.componentDetails?.some(x => x.IntervalType == 0) ?? false;
    }

    async submit(report: IComponentReadingForm) { //todo
        if (!this.form.valid) {            
            return;
        }

        const result = await this.disableFormDuringApiCall(() =>{
            report.componentId = this.componentId;
            
            this.taskService.postComponentReading(report).then((result) => { 
                debugger;
                this.loadComponentDetails();
            });            
        });

    }

    async refresh(event: RefresherCustomEvent) {
        event.target.complete();
    }
}