import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseModalComponent } from '../../classes/BaseModalComponent';
import { AddCargoModalComponent } from './add-cargo-modal/add-cargo-modal.component';
import { CargoService } from 'src/app/services/cargo.service';
import { ICargoEntity } from '../../models/Cargo';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';

@Component({
    selector: 'cargo-auto-complete-modal',
    templateUrl: './cargo-auto-complete-modal.component.html',
    styleUrls: ['./cargo-auto-complete-modal.component.scss'],
})
export class CargoAutoCompleteModalComponent extends BaseModalComponent implements OnInit {

    searchTerm: string = "";
    items: ICargoEntity[] = [];
    isOnline: boolean = false;

    constructor(modalController: ModalController,
        private cargoService: CargoService,
        private networkService: NetworkService) {
            super(modalController);
        }

    ngOnInit() {
        this.subscriptions.push(this.networkService.onNetworkChange().subscribe((status) => {
            this.isOnline = status == ConnectionStatus.Online;
        }));
        this.cargoService.list(100, this.searchTerm).then((items) => this.items = items);
    }

    async onSearchTermChanged(event: CustomEvent) {
        this.searchTerm = event.detail.value;

        this.items = await this.cargoService.list(100, this.searchTerm);
    }

    onItemSelected(item: ICargoEntity) {
        this.modalController.dismiss(item);
    }

    async openAddNewItemModal() {
        const modal = await this.modalController.create({
            component: AddCargoModalComponent
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data != null) {
            this.modalController.dismiss(data);
        }
    }
}
