<ion-header mode="ios">
    <ion-toolbar class="toolbar-home">
        <ion-title class="toolbar-title"></ion-title>
        <ion-buttons slot="end">
            <ion-menu-toggle slot="end" auto-hide="false" menu="fc-menu">
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<img class="logo" src="./assets/logo.svg">

<ion-content>
    <div class="content">
        <div class="ship" [ngStyle]="{ 'background-image': 'url(' + ship?.ShipImage + ')'}">
            <img class="swirl" src="./assets/swirl_logo beeldmerk.svg">
        </div>
    </div>

    <div class="container position-relative">
        <div class="ship-title">
            <h1 class="text-uppercase mb-0">
                {{username}}
            </h1>
            <h2 class="text-uppercase mb-4">{{ship?.Name}}</h2>
        </div>
        <div class="home-tiles">
            <div class="row justify-content-xs-between justify-content-md-start mb-2">
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/add-journey-report')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-travel-report" src="./assets/icon/dashboard/Reisrapport-toevoegen.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'reisrapport_toevoegen' | translate}}
                     </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/live-locations')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-live-locations" src="./assets/icon/dashboard/Live-locaties.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'live_locaties' | translate}}
                     </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/planning')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-planning" src="./assets/icon/dashboard/Planning.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'planning' | translate}}
                     </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/maintainance')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-maintainance" src="./assets/icon/dashboard/Onderhoud.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'onderhoud' | translate}}
                    </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/certificates')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-certificates" src="./assets/icon/dashboard/Certificaten.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'certificaten' | translate}}
                    </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/repairs')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-tasks" src="./assets/icon/dashboard/Taken.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'reparaties' | translate}}
                    </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/declarations')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-declarations" src="./assets/icon/dashboard/Declaraties.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'declaraties' | translate}}
                    </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/inspection-reports')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-inspection-reports" src="./assets/icon/dashboard/Inspectierapporten.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'inspectierapporten' | translate}}
                    </div>
                </div>

                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/add-work-order')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-workorder" src="./assets/icon/dashboard/work-order.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'werkbon_toevoegen' | translate}}
                    </div>
                </div>
                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" (click)="navigateForward('/home/add-handover')">
                    <ion-ripple-effect></ion-ripple-effect>

                    <div class="d-flex justify-content-center icon-wrapper">
                        <img class="icon-handover" src="./assets/icon/dashboard/handover-icon.svg" alt="">
                    </div>
                    <div class="tile-title">
                        {{'overdracht_toevoegen' | translate}}
                    </div>
                </div>

            </div>
        </div>
    </div>
</ion-content>