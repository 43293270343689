import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'home-toolbar',
    templateUrl: './home-toolbar.component.html',
    styleUrls: ['./home-toolbar.component.scss'],
})
export class HomeToolbarComponent implements OnInit {

    @Input() title?: string;
    @Input() showBackButton?: boolean;

    constructor() { }

    ngOnInit() { }
}
