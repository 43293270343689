import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Disposable } from '../../../shared/classes/Disposable';
import { WorkOrderService } from '../work-order.service';
import { IWorkOrderEntity } from 'src/app/shared/models/WorkOrder';

@Component({
    selector: 'app-work-order-detail',
    templateUrl: './work-order-detail.component.html',
    styleUrls: ['./work-order-detail.component.scss'],
})
export class WorkOrderDetailComponent extends Disposable implements OnInit {

    workOrder?: IWorkOrderEntity;

    constructor(private route: ActivatedRoute, private workOrderService: WorkOrderService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(this.route.paramMap.subscribe(async params => {
            const workOrderId = params.get("id")!;
            
            this.workOrder = await this.workOrderService.retrieve(workOrderId);
        }));
    }
}
