import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ISerenityListResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { ICertificate } from 'src/app/shared/models/Certificate';
import { catchError, mergeMap } from 'rxjs/operators';
import { Constants } from 'src/app/constants';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { firstValueFrom, throwError } from 'rxjs';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { StorageService } from 'src/app/services/storage.service';
import { Directory, Filesystem } from '@capacitor/filesystem';

@Injectable({
    providedIn: 'root'
})
export class CertifcatesService {

    constructor(private http: HttpService,
        private storage: StorageService,
        private networkService: NetworkService,
        private appSettings: LocalAppSettingsService
    ) { }

    async list(take = 100, includeColumns?: string[]): Promise<ICertificate[]> {
        const http$ = await this.http.post<ISerenityListResponse<ICertificate>>(`${environment.baseUrl}/api/Certificate/List`, { take, includeColumns, ContainsField: 'ShipId', ContainsText: this.appSettings.ship.Id });
        return firstValueFrom(http$.pipe(mergeMap(async (result) => {
            const certificates = await this.storeLocalCertificates(result.Entities);
            this.storage.set(Constants.sqlStorageKeys.certificates, certificates);

            return certificates;
        })).pipe(
            catchError(async (error) => {
                if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                    return await this.storage.get<ICertificate[]>(Constants.sqlStorageKeys.certificates);
                } else {
                    return firstValueFrom(throwError(() => error));
                }
            })
        ));
    }

    private async storeLocalCertificates(certificates: ICertificate[]): Promise<ICertificate[]> {
        try{

            for (const certificate of certificates) {

                if (certificate.hasOwnProperty("Certificate")) {
                    try {
                        var filenameSplit = certificate.Certificate?.split('/');
                        var filename = filenameSplit ? filenameSplit[filenameSplit.length-1] : '';
                        const filePath = `cert-${filename}`;

                        Filesystem.readFile({path: filePath, directory: Directory.Data }).then((file) => {
                            //file exists
                        }).catch(async (e) => {
                            const result = await Filesystem.downloadFile({ url: `${environment.baseUrl}/upload/${certificate.Certificate}`, path: filePath, directory: Directory.Data })
                            certificate.LocalUrl = result.path;
                        });
                    } 
                    catch (exception) {
                    }
                };
            }
        } catch (excptin) {
            
        }

        return certificates;
    }
}
