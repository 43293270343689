<home-toolbar
  [showBackButton]="true"
  [title]="'werkbon' | translate"
></home-toolbar>

<ion-content>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-md-2 mb-sm-2 mb-3">
        <h1 class="text-uppercase mb-3">{{ "werkbon" | translate }}</h1>

        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "datum" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.Date | date : "dd-MM-yyyy"
          }}</span>
        </div>
        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "email_ship" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.ShipEmail
          }}</span>
        </div>
        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "werkbon_bedrijf" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.WorkOrderCompanyName
          }}</span>
        </div>
        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "taakomschrijving" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.JobDescription
          }}</span>
        </div>
        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "starttijd" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.StartDateTime | date : "dd-MM-yyyy HH:mm"
          }}</span>
        </div>
        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "eindtijd" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.EndDateTime | date : "dd-MM-yyyy HH:mm"
          }}</span>
        </div>
        <div class="row align-items-center mb-2 mr-0">
            <label class="label col-md-6 col-sm-6 col-6">{{
              "werkbon_status" | translate
            }}</label>
            <span class="col-md-6 col-sm-6 col-6">{{ workOrder?.Status | workOrderStatus }}</span>
          </div>
        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "opmerkingen" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{ workOrder?.Remarks }}</span>
        </div>

        <h1 class="text-uppercase mb-3 mt-3">
          {{ "handtekening_kapitein" | translate }}
        </h1>

        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "naam_kapitein" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.NameCaptain
          }}</span>
        </div>

        <div class="row mb-3" *ngIf="workOrder?.SignatureCaptain">
          <div class="col-12">
            <img [src]="workOrder?.SignatureCaptain" />
          </div>
        </div>

        <h1 class="text-uppercase mb-3 mt-3">
          {{ "handtekening_monteur" | translate }}
        </h1>

        <div class="row align-items-center mb-2 mr-0">
          <label class="label col-md-6 col-sm-6 col-6">{{
            "naam_monteur" | translate
          }}</label>
          <span class="col-md-6 col-sm-6 col-6">{{
            workOrder?.NameMechanic
          }}</span>
        </div>

        <div class="row mb-3" *ngIf="workOrder?.SignatureMechanic">
          <div class="col-12">
            <img [src]="workOrder?.SignatureMechanic" />
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div *ngIf="workOrder?.Images?.length">
          <h1 class="text-uppercase mb-3 mt-3">{{ "fotos" | translate }}</h1>

          <div class="row mb-3" *ngFor="let image of workOrder?.Images">
            <div class="col-12">
              <img [src]="image.Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
