import { IGroupedScheduleItemsByDate } from './../../shared/models/Schedule';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { ISerenityListResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { IScheduleItem } from 'src/app/shared/models/Schedule';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { Constants } from 'src/app/constants';
import { catchError, mergeMap } from 'rxjs/operators';

import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  private _groupedScheduleItems: IGroupedScheduleItemsByDate = {};

  constructor(
    private http: HttpService,
    private storage: StorageService,
    private appSettings: LocalAppSettingsService
  ) {}

  async listBetweenDates(
    startDate: string,
    endDate: string
  ): Promise<IScheduleItem[]> {
    const http$ = await this.http.post<ISerenityListResponse<IScheduleItem>>(
      `${environment.baseUrl}/api/Schedule/ListBetweenDates`,
      {
        Startdate: startDate,
        EndDate: endDate,
        ShipId: this.appSettings.ship.Id,
      }
    );
    return firstValueFrom(
      http$
        .pipe(
          mergeMap(async (result) => {
            await this.storage.set(
              Constants.sqlStorageKeys.schedule,
              result.Entities
            );
            return result.Entities;
          })
        )
        .pipe(
          catchError(async () => {
            let scheduleItems = (await this.storage.get<IScheduleItem[]>(
              Constants.sqlStorageKeys.schedule
            ));

            scheduleItems.filter((item) => {
              let startTime = moment(item.StartTime);
              let endTime = moment(item.EndTime);

              let requestStartTime = moment(startDate);
              let requestEndTime = moment(endDate);

              return startTime <= requestEndTime && endTime >= requestStartTime;
            });

            return scheduleItems;
          })
        )
    );
  }

  get groupedScheduleItems(): IGroupedScheduleItemsByDate {
    return this._groupedScheduleItems;
  }

  set groupedScheduleItems(groupedScheduleItems: IGroupedScheduleItemsByDate) {
    this._groupedScheduleItems = groupedScheduleItems;
  }
}
