import { AbstractControl } from '@angular/forms';

export function comparePasswords(formGroup: AbstractControl<any, any>) {

    const password = formGroup.get('password');
    const passwordRepeat = formGroup.get('passwordRepeat');

    if (!password?.touched || !passwordRepeat?.touched)
        return null;

    if (password.value != passwordRepeat.value) {
        return { "passwords-not-equal": true }
    }

    return null;
}
