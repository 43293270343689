<home-toolbar [showBackButton]="true" [title]="'inspectierapporten' | translate"></home-toolbar>

<ion-content>
    <div class="container">
        <form class="mb-3" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" novalidate
            autocomplete="off">

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-md-5 ccol-sm-6 col-6">{{'omschrijving' | translate}}</label>
                <input class="input-text col-md-7 col-sm-6 col-6" type="text" formControlName="description"
                    [placeholder]="'omschrijving' | translate" />
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-md-5 ccol-sm-6 col-6">{{'taak' | translate}}</label>

                <ion-select formControlName="taskId" class="col-md-7 col-sm-6 col-6 pl-0" [placeholder]="'selecteertaak' | translate" interface="alert" mode="ios">
                    <ion-select-option *ngFor="let task of tasks" [value]="task.Id">{{task.Description}}</ion-select-option>
                </ion-select>
            </div>


            <div [hidden]="!isOnline" class="row align-items-top mb-3 mr-0">
                <label class="label col-md-5 col-sm-6 col-6">{{'fototoevoegen' | translate}}</label>

                <div class="col-md-7 col-6 pl-0">
                    <div class="row">
                        <div class="d-inline-flex col-md-3" *ngFor="let number of [0, 1, 2, 3]">
                            <upload-picture *ngIf="number <= pictures.length" (pictureChanged)="pictureChanged($event)" [index]="number">
                            </upload-picture>
                        </div>
                    </div>

                </div>
            </div>
            <button [disabled]="!form.valid || isSaving" class="btn-login"
                type="submit">{{'inspectie_rapport_toevoegen' | translate }}</button>

        </form>
    </div>
</ion-content>