import { FormGroup } from "@angular/forms";
import { BaseComponent } from './BaseComponent';
import { NavController } from '@ionic/angular';

export abstract class BaseFormComponent extends BaseComponent {
    abstract submit(formValue: any): void;

    today = new Date();

    form!: FormGroup;
    isSaving: boolean = false;

    constructor(navController: NavController) {
        super(navController);
    }

    hasError(controlName: string, errorName: string) {
        return this.form.controls[controlName] && this.form.controls[controlName].hasError(errorName);
    }

    hasFormError(errorName: string) {
        return this.form.hasError(errorName);
    }

    async disableFormDuringApiCall<T>(serverCall: Function): Promise<T> {
        this.isSaving = true;
        const result = await serverCall();
        this.isSaving = false;

        return result;
    }
}

