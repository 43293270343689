<ion-content>
    <div class="header">
        <img class="swirl" src="./assets/swirl_logo beeldmerk.svg">
        <img class="logo" src="./assets/logo.svg">

        <language-switch class="float-right"></language-switch>
    </div>

    <div class="container position-relative">
        <form class="form-signin" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value)" novalidate
            autocomplete="off">

            <h1> {{'login' | translate}} </h1>

            <div class="row align-items-center mb-2 mr-0">
                <label class="label col-5">{{'gebruikersnaam' | translate}}</label>
                <ion-input class="input-text col-7" type="text" formControlName="username" id="username" name="username"
                    placeholder="1234"></ion-input>
            </div>

            <div class="row align-items-center mb-3 mr-0">
                <label class="label col-5">{{'wachtwoord' | translate}}</label>
                <input class="input-text col-7" type="password" formControlName="password" id="password" name="Wachtwoord"
                    placeholder="Wachtwoord" />
            </div>

            <label class="label col-12 pl-0 mb-3 d-flex align-items-center">
                <div class="col-2 pl-0 d-flex align-items-center">
                    <ion-checkbox remember-me mode="md" formControlName="rememberMe" name="rememberMe" checked="false">
                    </ion-checkbox>
                </div>
                <span class="col-10 pl-0">{{'wachtwoord_onthouden' | translate}}</span>
            </label>

            <div class="mb-3">
                <button class="btn-login" expand="full" type="submit" [disabled]="!form.valid">{{'inloggen' | translate}}</button>
            </div>
            <a class="forgot-password" [routerLink]="['/account/wachtwoordvergeten']">{{'wachtwoord_vergeten' | translate}}</a>
        </form>
    </div>

</ion-content>
