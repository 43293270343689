<home-toolbar [showBackButton]="true" [title]="'reparaties' | translate"></home-toolbar>

<ion-content>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="repair-container">
        <div class="add-repair-wrapper">
            <button class="btn-login float-right" [routerLink]="['/home/repairs/add']">
                {{ 'reparatie_toevoegen' | translate }}
                <ion-icon name="add"></ion-icon>
            </button>
        </div>

        <table class="table table-fc">
            <thead>
                <tr>
                    <th>{{'aanmelddatum' | translate}}</th>
                    <th>{{'omschrijving' | translate}}</th>
                    <th class="d-none d-sm-none d-md-table-cell">{{'bedrijf' | translate}}</th>
                    <th>{{'status' | translate}}</th>
                    <th class="d-none d-sm-none d-md-table-cell">{{'statuswijziging' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let repair of repairs | async" [class.repair-offline]="repair.Id === -1"
                    (click)="navigateToRepair(repair.Id)">
                    <td>{{repair.ExecuteDate ? (repair.ExecuteDate | date: 'dd-MM-yyyy') : '-'}}</td>
                    <td>{{repair.Description}}</td>
                    <td class="d-none d-sm-none d-md-table-cell">{{repair.ExecutiveName}}</td>
                    <td class="status-in-progress"
                        [ngClass]="{'status-open' : repair.Status == 1, 'status-inprogress' : repair.Status == 2, 'status-denied': repair.Status == 3 || repair.Status == 4 || repair.Status == 5 }">
                        {{repair.Status | repairStatus}}</td>
                    <td class="d-none d-sm-none d-md-table-cell">{{repair.StatusChangeDate | date: 'dd-MM-yyyy'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ion-content>
