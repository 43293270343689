import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShipRoleService } from 'src/app/services/ship-role.service';
import { IShipRole } from 'src/app/shared/models/ShipRole';
import { PlanningService } from './planning.service';

import * as moment from 'moment';
import {
  IScheduleItem,
  IGroupedScheduleItemsByDate,
} from 'src/app/shared/models/Schedule';
import { Subject } from 'rxjs';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { Disposable } from 'src/app/shared/classes/Disposable';
import { Platform } from '@ionic/angular';
import { Constants } from 'src/app/constants';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
})
export class PlanningComponent extends Disposable implements OnInit {
  constructor(
    private shipRoleService: ShipRoleService,
    private planningService: PlanningService,
    private localAppSettings: LocalAppSettingsService,
    private platform: Platform,
    private storage: StorageService
  ) {
    super();
  }

  private unit?: moment.unitOfTime.DurationConstructor;
  private amountOfUnitsShown: number = 0;
  private dateContextSubject = new Subject<void>();

  dateContext: moment.Moment = moment(new Date());
  shipRoles: IShipRole[] = [];
  currentSchedule?: IScheduleItem[];
  weeks: {weekNr: number, days: any[]}[] = [];
  startWeek?: number;
  endWeek?: number;
  currentDayName?: string;
  currentMonthName?: string;

  ngOnInit() {
    this.setupUnitsForDeviceWidth();

    moment.locale(this.localAppSettings.selectedLanguage);
    this.dateContext = moment(new Date());

    this.loadSchedule(this.dateContext).then(() => {
      this.shipRoleService.list().then((shipRoles) => {
        this.shipRoles = shipRoles;
      });
    });
    
    this.subscriptions.push(
      this.localAppSettings
        .getLanguageObservable()
        .subscribe((locale: string) => {
          moment.locale(locale);
          this.weeks = [];
          this.loadSchedule(this.dateContext);
        })
    );

    this.loadOfflineSchedule();
  }

  public setupUnitsForDeviceWidth() {
    if (this.isTabletWidth()) {
      this.unit = 'weeks';
      this.amountOfUnitsShown = 4;
    } else {
      this.unit = 'days';
      this.amountOfUnitsShown = 1;
    }
  }

  private async loadOfflineSchedule() {
    const start = moment().subtract(1, 'month').startOf('month');
    const end = moment().add(1, 'month').endOf('month');

    const schedule = await this.planningService.listBetweenDates(
      start.toISOString(),
      end.toISOString()
    );
    await this.storage.set(Constants.sqlStorageKeys.schedule, schedule);
  }

  async previousCalendar() {
    var currentdate = this.dateContext.toDate();
    this.weeks = [];
    await this.loadSchedule(
      moment(currentdate).subtract(this.amountOfUnitsShown, this.unit)
    );

    this.dateContext.subtract(this.amountOfUnitsShown, this.unit);
    this.dateContextSubject.next();
  }

  async nextCalendar() {
    var currentdate = this.dateContext.toDate();
    this.weeks = [];
    await this.loadSchedule(
      moment(currentdate).add(this.amountOfUnitsShown, this.unit)
    );

    this.dateContext.add(this.amountOfUnitsShown, this.unit);
    this.dateContextSubject.next();
  }

  private async loadSchedule(date: moment.Moment) {
    if (this.isTabletWidth()) {
      //var currentdate = date.toDate();

      this.startWeek = date.startOf('isoWeek').week();
      this.endWeek = date
        .clone()
        .add(this.amountOfUnitsShown - 1, 'week')
        .startOf('isoWeek')
        .week();
      await this.listBetweenDates(
        date.clone().startOf('isoWeek').toISOString(),
        date
          .clone()
          .add(this.amountOfUnitsShown - 1, 'week')
          .endOf('isoWeek')
          .toISOString()
      );
      this.loadCalendarForDateContext(date.clone());
    } else {
      await this.listBetweenDates(
        date.startOf('day').toISOString(),
        date.endOf('day').toISOString()
      );
      (this.currentDayName = moment.weekdays(true)[date.isoWeekday() - 1]),
        (this.currentMonthName = moment.months()[date.month()]);
    }
  }

  private loadCalendarForDateContext(date: moment.Moment) {
    for (let i = 0; i < this.amountOfUnitsShown; i++) {
      let startOfWeek = date.clone().add(i, 'week').startOf('isoWeek');
      let endOfWeek = date.clone().add(i, 'week').endOf('isoWeek');

      let day = startOfWeek;
      let days = [];

      while (day <= endOfWeek) {
        days.push({
          date: day.toISOString(),
          dayName: moment.weekdays(true)[day.isoWeekday() - 1],
          monthName: moment.months()[day.month()],
          day: day.date(),
        });
        day = day.clone().add(1, 'd');
      }

      this.weeks.push({
        weekNr: startOfWeek.week(),
        days: days,
      });
      days = [];
    }
  }

  private async listBetweenDates(startDate: string, endDate: string) {
    const scheduleItems = await this.planningService.listBetweenDates(
      startDate,
      endDate
    );
    this.planningService.groupedScheduleItems = this.groupCalendarEvents(
      scheduleItems,
      startDate,
      endDate
    );
  }

  private groupCalendarEvents(
    scheduleItems: IScheduleItem[],
    startDate: string,
    endDate: string
  ): IGroupedScheduleItemsByDate {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    let totalDays = endMoment.diff(startMoment, 'days');

    if (!this.isTabletWidth()) totalDays = 3;

    let calendarEvents: IGroupedScheduleItemsByDate = {};

    for (let day = -1; day <= totalDays; day++) {
      var copy = moment(startMoment);
      var currentDay = copy.add(day, 'days');

      var eventsForDay = [];

      for (let i = 0; i < scheduleItems.length; i++) {
        let event = scheduleItems[i];

        let start = moment(event.StartTime);
        let end = moment(event.EndTime);

        if (
          currentDay.isBetween(start, end) ||
          currentDay.isSame(start, 'days') ||
          currentDay.isSame(end, 'days')
        ) {
          eventsForDay.push(event);
        } else {
          eventsForDay.push(null);
        }
      }

      calendarEvents[currentDay.format('L')] = eventsForDay;
    }

    return calendarEvents;
  }

  private isTabletWidth() {
    return this.platform.width() > 767;
  }
}
