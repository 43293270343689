import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../../../shared/classes/BaseFormComponent';
import { NavController, AlertController } from '@ionic/angular';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { FileChangeEvent } from 'src/app/shared/classes/FileChangeEvent';
import { FileService } from 'src/app/services/file.service';
import { environment } from '../../../../environments/environment';
import { HandOverService } from '../handover.service';
import { IHandOver, HandOver } from 'src/app/shared/models/HandOver';
import { HandOverFormType } from "src/app/shared/classes/HandOverFormType";
import { Constants } from "src/app/constants";

@Component({
  selector: 'app-add-handover',
  templateUrl: './add-handover.component.html',
  styleUrls: ['./add-handover.component.scss'],
})
export class AddHandOverComponent extends BaseFormComponent implements OnInit {
  pictures: (File | Blob)[] = [];
  imageHostUrl: string;
  showErrors: boolean = false;
  currentLanguage: string = '';
  shipType: HandOverFormType = HandOverFormType.DryLoad;

  readonly minimalDate = moment().subtract(1, 'month');

  constructor(
    navController: NavController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private handOverService: HandOverService,
    private appSettings: LocalAppSettingsService,
    private fileService: FileService
  ) {
    super(navController);

    this.imageHostUrl = environment.baseUrl;
  }

  ngOnInit() {
    let date: Date | unknown = this.today.toISOString();
    let remarks = null;
    let nameCaptainOnboard = null;
    let signatureCaptainOnboard: string|null = null;
    let nameCaptainOffboard = null;
    let signatureCaptainOffboard: string|null = null;
    let bunkerFront = null;
    let bunkerRear = null;
    let bunkerOrdered = null;
    let city = null;
    let drinkWaterFront = null;
    let drinkWaterRear = null;
    let engines = null;
    let loadingPapers = null;
    let loadingSituation = null;
    let movementQualities = null;
    let nauticalEquipment = null;
    let particularities = null;
    let pumps = null;    
    let travelOrders = null;    
    let certificatesValid = false;
    let materialsOrdered = false;
    let safetyEquipmentValid = false;
    
    let pipesHoses = null;
    let cleaningGasFree = null;
    let stateChargeTanks = null;
    let slobtanksBb = null;
    let slobtanksMid = null;
    let slobtanksSb = null;
    let rinsingWaterTankBb = null;
    let rinsingWaterTankSb = null;
    let rinsingWaterTankPeak = null;
    let rinsingWaterTankBallast = null;
    let boiler = null;
    let tracing = null;
    let crewMatrixUpdated = false;
    let maintenanceUpdated = false;
    let journeyReportUpdated = false;
    let repairListUpdated = false;
    let tankLevelEquipment = null;


    this.shipType = this.appSettings.ship.HandOverFormType;

    if (this.appSettings.currentHandover != null) {
      date = this.appSettings.currentHandover.date;
      remarks = this.appSettings.currentHandover.remarks;
      nameCaptainOnboard = this.appSettings.currentHandover.nameCaptainOnboard;
      signatureCaptainOnboard = this.appSettings.currentHandover
        .signatureCaptainOnboard
        ? `${this.imageHostUrl}/upload/${this.appSettings.currentHandover.signatureCaptainOnboard}`
        : null;
      nameCaptainOffboard = this.appSettings.currentHandover.nameCaptainOffboard;
      signatureCaptainOffboard = this.appSettings.currentHandover.signatureCaptainOffboard ? `${this.imageHostUrl}/upload/${this.appSettings.currentHandover.signatureCaptainOffboard}` : null;
      bunkerFront = this.appSettings.currentHandover.bunkerFront;
      bunkerRear = this.appSettings.currentHandover.bunkerRear;
      bunkerOrdered = this.appSettings.currentHandover.bunkerOrdered;
      city = this.appSettings.currentHandover.city;
      drinkWaterFront = this.appSettings.currentHandover.drinkWaterFront;
      drinkWaterRear = this.appSettings.currentHandover.drinkWaterRear;
      engines = this.appSettings.currentHandover.engines;
      loadingPapers = this.appSettings.currentHandover.loadingPapers;
      loadingSituation = this.appSettings.currentHandover.loadingSituation;
      movementQualities = this.appSettings.currentHandover.movementQualities;
      nauticalEquipment = this.appSettings.currentHandover.nauticalEquipment;
      particularities = this.appSettings.currentHandover.particularities;
      pumps = this.appSettings.currentHandover.pumps;
      travelOrders = this.appSettings.currentHandover.travelOrders;      
      certificatesValid = this.appSettings.currentHandover.certificatesValid;
      materialsOrdered = this.appSettings.currentHandover.materialsOrdered;
      safetyEquipmentValid = this.appSettings.currentHandover.safetyEquipmentValid;

      let formTypeSpecificFields = this.appSettings.currentHandover.formTypeSpecificFields!;
      let formTypeSpecificFieldData = JSON.parse(formTypeSpecificFields);

      pipesHoses = formTypeSpecificFieldData.pipesHoses;
      cleaningGasFree = formTypeSpecificFieldData.cleaningGasFree;
      stateChargeTanks = formTypeSpecificFieldData.stateChargeTanks;
      slobtanksBb = formTypeSpecificFieldData.slobtanksBb;
      slobtanksMid = formTypeSpecificFieldData.slobtanksMid;
      slobtanksSb = formTypeSpecificFieldData.slobtanksSb;
      rinsingWaterTankBb = formTypeSpecificFieldData.rinsingWaterTankBb;
      rinsingWaterTankSb = formTypeSpecificFieldData.rinsingWaterTankSb;
      rinsingWaterTankPeak = formTypeSpecificFieldData.rinsingWaterTankPeak;
      rinsingWaterTankBallast = formTypeSpecificFieldData.rinsingWaterTankBallast;
      boiler = formTypeSpecificFieldData.boiler;
      tracing = formTypeSpecificFieldData.tracing;
      crewMatrixUpdated = formTypeSpecificFieldData.crewMatrixUpdated;
      maintenanceUpdated = formTypeSpecificFieldData.maintenanceUpdated;
      journeyReportUpdated = formTypeSpecificFieldData.journeyReportUpdated;
      repairListUpdated = formTypeSpecificFieldData.repairListUpdated;
      tankLevelEquipment = formTypeSpecificFieldData.tankLevelEquipment;
    }


    this.form = new FormGroup(
      {
        date: new FormControl(date, [Validators.required]),
        remarks: new FormControl(remarks),
        nameCaptainOnboard: new FormControl(nameCaptainOnboard, [ Validators.required ]),
        signatureCaptainOnboard: new FormControl(signatureCaptainOnboard, [ Validators.required ]),
        nameCaptainOffboard: new FormControl(nameCaptainOffboard, [ Validators.required ]),
        signatureCaptainOffboard: new FormControl(signatureCaptainOffboard, [Validators.required ]),
        bunkerFront: new FormControl(bunkerFront),
        bunkerRear: new FormControl(bunkerRear),
        bunkerOrdered: new FormControl(bunkerOrdered),
        city: new FormControl(city),
        drinkWaterFront: new FormControl(drinkWaterFront),
        drinkWaterRear: new FormControl(drinkWaterRear),        
        loadingPapers: new FormControl(loadingPapers),
        loadingSituation: new FormControl(loadingSituation),
        stateChargeTanks: new FormControl(stateChargeTanks),
        pipesHoses: new FormControl(pipesHoses),
        cleaningGasFree: new FormControl(cleaningGasFree),
        slobtanksBb: new FormControl(slobtanksBb),
        slobtanksMid: new FormControl(slobtanksMid),
        slobtanksSb: new FormControl(slobtanksSb),
        rinsingWaterTankBb: new FormControl(rinsingWaterTankBb),
        rinsingWaterTankSb: new FormControl(rinsingWaterTankSb),
        rinsingWaterTankPeak: new FormControl(rinsingWaterTankPeak),
        rinsingWaterTankBallast: new FormControl(rinsingWaterTankBallast),
        movementQualities: new FormControl(movementQualities),
        nauticalEquipment: new FormControl(nauticalEquipment),
        particularities: new FormControl(particularities),
        engines: new FormControl(engines),
        pumps: new FormControl(pumps),
        tankLevelEquipment: new FormControl(tankLevelEquipment),
        boiler: new FormControl(boiler),
        tracing: new FormControl(tracing),
        travelOrders: new FormControl(travelOrders),
        certificatesValid: new FormControl(certificatesValid),
        materialsOrdered: new FormControl(materialsOrdered),
        safetyEquipmentValid : new FormControl(safetyEquipmentValid),
        crewMatrixUpdated: new FormControl(crewMatrixUpdated),
        maintenanceUpdated: new FormControl(maintenanceUpdated),
        journeyReportUpdated: new FormControl(journeyReportUpdated),
        repairListUpdated: new FormControl(repairListUpdated),
      }
    );

    this.currentLanguage = this.appSettings.selectedLanguage;
    this.subscriptions.push(
      this.appSettings.getLanguageObservable().subscribe((x) => {
        this.currentLanguage = x;
      })
    );
  }

  public get handOverFormType(): typeof HandOverFormType {
    return HandOverFormType; 
  }

  pictureChanged(event: FileChangeEvent) {
    if (event.file != null) {
      this.pictures.push(event.file);
    } else if (event.index != null) {
      this.pictures.splice(event.index, 1);
    }
  }

  async saveReport() {
    if (!this.appSettings.ship) return;

    const alert = await this.alertController.create({
      header: this.translateService.instant('overdrachtopgeslagen'),
      message: this.translateService.instant('overdrachtopgeslagen_instructies'),
      buttons: ['OK'],
    });

    this.appSettings.currentHandover = {
      date: this.form.controls['date'].value,
      bunkerFront: this.form.controls['bunkerFront'].value,
      bunkerRear: this.form.controls['bunkerRear'].value,
      bunkerOrdered: this.form.controls['bunkerOrdered'].value,
      certificatesValid: this.form.controls['certificatesValid'].value,
      city: this.form.controls['city'].value,
      drinkWaterFront: this.form.controls['drinkWaterFront'].value,
      drinkWaterRear: this.form.controls['drinkWaterRear'].value,
      engines: this.form.controls['engines'].value,
      loadingPapers: this.form.controls['loadingPapers'].value,
      loadingSituation: this.form.controls['loadingSituation'].value,
      materialsOrdered: this.form.controls['materialsOrdered'].value,
      movementQualities: this.form.controls['movementQualities'].value,
      nauticalEquipment: this.form.controls['nauticalEquipment'].value,
      particularities: this.form.controls['particularities'].value,
      pumps: this.form.controls['pumps'].value,
      safetyEquipmentValid: this.form.controls['safetyEquipmentValid'].value,
      travelOrders: this.form.controls['travelOrders'].value,
      remarks: this.form.controls['remarks'].value,
      nameCaptainOnboard: this.form.controls['nameCaptainOnboard'].value,
      signatureCaptainOnboard: this.form.controls['signatureCaptainOnboard']
        .value
        ? (
            await this.fileService.uploadTemporaryFile({
              file: (<unknown>(
                this.form.controls['signatureCaptainOnboard'].value
              )) as Blob,
            })
          )?.Image
        : null,
      nameCaptainOffboard: this.form.controls['nameCaptainOffboard'].value,
      signatureCaptainOffboard: this.form.controls['signatureCaptainOffboard']
        .value
        ? (
            await this.fileService.uploadTemporaryFile({
              file: (<unknown>(
                this.form.controls['signatureCaptainOffboard'].value
              )) as Blob,
            })
          )?.Image
        : null,
      shipId: this.appSettings.ship.Id,
      handOverFormType: this.appSettings.ship.HandOverFormType,      
      formTypeSpecificFields: this.getFormTypeSpecificFieldsJson()
    };

    await alert.present();
    return false;
  }

  async submit(report: IHandOver) {
    if (!this.form.valid || !this.appSettings.ship) {
      this.showErrors = true;
      return;
    }

    report.shipId = this.appSettings.ship.Id;
    report.handOverFormType = this.appSettings.ship.HandOverFormType;

    try {
      let image = await this.fileService.uploadTemporaryFile({
        file: (<unknown>report.signatureCaptainOnboard) as Blob,
      });

      report.signatureCaptainOnboard = image.Image;
    } catch (error) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('toevoegen_handtekening_mislukt'),
        message: this.translateService.instant(
          'toevoegen_handtekening_mislukt'
        ),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();
    }

    try {
      let image = await this.fileService.uploadTemporaryFile({
        file: (<unknown>report.signatureCaptainOffboard) as Blob,
      });
      report.signatureCaptainOffboard = image.Image;
    } catch (error) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('toevoegen_handtekening_mislukt'),
        message: this.translateService.instant(
          'toevoegen_handtekening_mislukt'
        ),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();
    }

    try {
      const result = await this.disableFormDuringApiCall(() => {
        report.formTypeSpecificFields = this.getFormTypeSpecificFieldsJson();

        let cleanedReport = this.cleanObject(report);
        this.handOverService.create(cleanedReport)
      });

      if (result != null) {
        const alert = await this.alertController.create({
          header: this.translateService.instant('overdracht_invoeren'),
          message: this.translateService.instant('overdracht_toegevoegd'),
          buttons: [this.translateService.instant('ok')],
        });

        await alert.present();
      } else {
        const alert = await this.alertController.create({
          header: this.translateService.instant('overdracht_invoeren'),
          message: this.translateService.instant('overdracht_toegevoegd_offline'),
          buttons: [this.translateService.instant('ok')],
        });

        await alert.present();
      }

      this.appSettings.currentHandover = null;

      this.navController.navigateBack('/home');
    } catch (exception) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('error'),
        message: this.translateService.instant('error_toevoegen_overdracht'),
        buttons: [this.translateService.instant('ok')],
      });

      await alert.present();

      this.isSaving = false;
    }
  }

  updateSignature(controlName: string, file: Blob | null) {
    this.form.controls[controlName].setValue(file);
  }

  getFormTypeSpecificFieldsJson = ():string => {
    let specificFieldsJson: {[k: string]: any} = {};

    if(this.appSettings.ship.HandOverFormType == HandOverFormType.DryLoad){      
      for (let index = 0; index < Constants.DryLoadHandoverFields.length; index++) {
        let key = Constants.DryLoadHandoverFields[index];

        specificFieldsJson[key] = this.form.controls[key].value;
      }
    }else{
      for (let index = 0; index < Constants.TankerHandoverFields.length; index++) {
        let key = Constants.TankerHandoverFields[index];

        specificFieldsJson[key] = this.form.controls[key].value;
      }
    }
    
    return JSON.stringify(specificFieldsJson);
  }
  
  cleanObject = (obj: IHandOver) => {
    let handoverObject = new HandOver();

    const interfaceProperties = Object.keys(handoverObject) as Array<keyof IHandOver>;
    const objectProperties = Object.keys(obj) as Array<keyof IHandOver>;
  
    objectProperties.forEach(property => {
      if (!interfaceProperties.includes(property)) {
        delete obj[property];
      }
    });
  
    return obj;
  }

}
