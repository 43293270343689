<home-toolbar [showBackButton]="true" [title]="'overdracht_archief' | translate"></home-toolbar>

<ion-content>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="container">
        <div class="home-tiles">
            <div class="row justify-content-xs-between justify-content-md-start mb-2"
                *ngFor="let handover of handovers" [hidden]="handover.reports.length === 0">

                <div class="journey-report-period">
                    <h1>{{handover.monthName}} {{handover.year}}</h1>
                </div>

                <div class="col-6 col-md-3 menu-tile mb-2 ion-activatable" *ngFor="let report of handover.reports"
                    (click)="navigateToReportDetail(report.Id)">
                    <ion-ripple-effect></ion-ripple-effect>
                    <div class="travel-number">
                        {{ report.City }} <br />
                        <span class="departure-date">
                            {{ report.Date | date:'dd-MM-yyyy'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ion-content>
