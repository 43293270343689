import { Component, OnInit } from '@angular/core';
import { NavController, RefresherCustomEvent } from '@ionic/angular';
import { ChooseShipService } from './choose-ship.service';
import { BaseComponent } from '../../shared/classes/BaseComponent';
import { IShip } from 'src/app/shared/models/Ship';
import * as moment from "moment"
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';

@Component({
    selector: 'choose-ship',
    templateUrl: './choose-ship.component.html',
    styleUrls: ['./choose-ship.component.scss'],
})
export class ChooseShipComponent extends BaseComponent implements OnInit {
    ships?: IShip[] = [];

    constructor(navController: NavController,
        private chooseShipService: ChooseShipService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    async ngOnInit() {
        moment.locale(this.appSettings.selectedLanguage);

        await this.getMyShips();
    }

    private async getMyShips() {
        this.chooseShipService.retrieve().then(result => {
            this.ships = result;
        });
    }

    async refresh(event: RefresherCustomEvent) {
        await this.getMyShips();
        event.target.complete();
    }

    SwitchShip = (ship: IShip) =>{
        this.appSettings.ship = ship;

        this.chooseShipService.setShipData(ship);

        this.navigateForward("/home");
    }

}