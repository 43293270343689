import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '../../../classes/BaseModalComponent';
import { ModalController, AlertController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PortService } from 'src/app/services/port.service';
import { IPort } from '../../../models/Port';
import { TranslateService } from '@ngx-translate/core';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';

@Component({
    selector: 'app-add-port-modal',
    templateUrl: './add-port-modal.component.html',
    styleUrls: ['./add-port-modal.component.scss'],
})
export class AddPortModalComponent extends BaseModalComponent implements OnInit {

    form!: FormGroup;
    isSaving: boolean = false;

    constructor(modalController: ModalController,
        private portService: PortService,
        private alertController: AlertController,
        private translateService: TranslateService,
        private appSettings: LocalAppSettingsService) {
        super(modalController);
    }

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl('', [Validators.required]),
            city: new FormControl('', [Validators.required])
        });
    }

    async submit(port: IPort) {
        this.isSaving = true;
        port.CompanyId = this.appSettings.ship.CompanyId;

        try {
            const result: any = await this.portService.create(port);

            if (result != null) {
                const alert = await this.alertController.create({
                    header: this.translateService.instant('havens'),
                    message: this.translateService.instant('haven_toegevoegd'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
                await this.modalController.dismiss({ Name: port.name, City: port.city, Id: result.EntityId });
            } else {
                const alert = await this.alertController.create({
                    header: this.translateService.instant('ladingen'),
                    message: this.translateService.instant('haven_toegevoegd_offline'),
                    buttons: [this.translateService.instant('ok')]
                });

                await alert.present();
                await this.modalController.dismiss();
            }

        } catch (exception) {
            const alert = await this.alertController.create({
                header: this.translateService.instant('error'),
                message: this.translateService.instant('error_toevoegen_haven'),
                buttons: [this.translateService.instant('ok')]
            });

            await alert.present();
        }

        this.isSaving = false;
    }
}
