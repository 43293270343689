import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from "../../../environments/environment";
import { IRepair, IRepairListModel } from 'src/app/shared/models/Repair';
import { ISerenityListResponse, ISerenityRetrieveResponse, ISerenitySaveResponse, ISerenityDeleteResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { BehaviorSubject, Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Constants } from 'src/app/constants';
import { OfflineEventService } from 'src/app/services/offline-event.service';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import * as moment from "moment"
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class RepairService {

    constructor(private http: HttpService,
        private storage: StorageService,
        private networkService: NetworkService,
        private appSettings: LocalAppSettingsService,
        private offlineEventService: OfflineEventService) { }

    private _repairs = new BehaviorSubject<IRepair[]>([]);

    public readonly repairs: Observable<IRepair[]> = this._repairs.asObservable();

    async create(repair: IRepairListModel) {
        const url = `${environment.baseUrl}/api/Repair/Create`;
        const data = { Entity: repair };
        const http$ = await this.http.post<ISerenitySaveResponse>(url, data);
        return firstValueFrom(http$
            .pipe(
                map(async (result) => {
                    this._repairs.next(await this.getRepairs());
                    return result;
                })
            )
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        const repairs = await this.storage.get(Constants.sqlStorageKeys.repairs) as IRepair[];
                        repairs.push({
                            Id: -1,
                            Contact: repair.contact,
                            Description: repair.description,
                            ExecuteDate: repair.executeDate,
                            ExecutiveName: repair.executiveName,
                            Explanation: repair.explanation,
                            Priority: repair.priority,
                            Status: repair.status,
                            StatusChangeDate: repair.statusChangeDate,
                            AddedDate: new Date(),
                            CompanyId: repair.CompanyId
                        });

                        this._repairs.next(repairs);

                        await this.storage.set(Constants.sqlStorageKeys.repairs, repairs);
                        await this.offlineEventService.storeRequest(url, "post", data);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };

    async list(take = 100, includeColumns?: string[]) {
        const http$ = await this.http.post<ISerenityListResponse<IRepair>>(`${environment.baseUrl}/api/Repair/List`, { take, includeColumns });

        return firstValueFrom(http$
            .pipe(mergeMap(async (result) => {
                await this.storage.set(Constants.sqlStorageKeys.repairs, result.Entities);
                this._repairs.next(result.Entities);
                return result.Entities;
            })).pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        const repairs = await this.storage.get(Constants.sqlStorageKeys.repairs) as IRepair[];
                        this._repairs.next(repairs);
                        return repairs;
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };

    // Retrieves repair untill one month ago and repairs that have no Excecute date.
    async getRepairs () {
        const today = moment(new Date()).endOf('day').toISOString();
        const oneMonthAgo = moment(new Date()).subtract(1, 'month').startOf('day').toISOString();

        return this.listBetweenDates(oneMonthAgo, today);
    }

    async listBetweenDates(startDate: string, endDate: string) {
        const http$ = await this.http.post<ISerenityListResponse<IRepair>>(`${environment.baseUrl}/api/Repair/ListBetweenDates`, { Startdate: startDate, EndDate: endDate, ShipId: this.appSettings.ship.Id });

        return firstValueFrom(http$
            .pipe(mergeMap(async (result) => {
                await this.storage.set(Constants.sqlStorageKeys.repairs, result.Entities);
                this._repairs.next(result.Entities);
                return result.Entities;
            })).pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        const repairs = await this.storage.get(Constants.sqlStorageKeys.repairs) as IRepair[];
                        this._repairs.next(repairs);
                        return repairs;
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };

    async retrieve(id: string) {
        const http$ = await this.http.post<ISerenityRetrieveResponse<IRepair>>(`${environment.baseUrl}/api/Repair/Retrieve`, { EntityId: id });
        return firstValueFrom(http$
            .pipe(map((result) => result.Entity))
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        const repairs = await this.storage.get(Constants.sqlStorageKeys.repairs) as IRepair[];
                        return repairs.filter(repair => repair.Id === parseInt(id)).pop();
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })));
    };

    async delete(id: number) {
        const url = `${environment.baseUrl}/api/Repair/Delete`;
        const data = { EntityId: id };
        const http$ = await this.http.post<ISerenityDeleteResponse>(url, data);
        return firstValueFrom(http$.
            pipe(
                mergeMap(async () => {
                    this.deleteRepair(id);
                    await this.storage.set(Constants.sqlStorageKeys.repairs, this._repairs.getValue());
                })
            ).pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        this.deleteRepair(id);
                        await this.offlineEventService.storeRequest(url, 'post', data);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };

    private deleteRepair(id: number) {
        let repair = this._repairs.getValue().filter(x => x.Id === id).pop()!;
        this._repairs.getValue().splice(this._repairs.getValue().indexOf(repair), 1);
        this._repairs.next(this._repairs.getValue());
    }

    async update(repair: IRepair, repairListModel: IRepairListModel) {
        const url = `${environment.baseUrl}/api/Repair/Update`;
        const data = { Entity: repairListModel, EntityId: repair.Id };
        const http$ = await this.http.post<ISerenitySaveResponse>(url, data);

        firstValueFrom(http$
            .pipe(mergeMap(async () => {
                this._repairs.next(await this.getRepairs());
            })).pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        // Update the status locally.
                        repair.Status = repairListModel.status;
                        const repairs = await this.storage.get<IRepair[]>(Constants.sqlStorageKeys.repairs);
                        const foundRepair = repairs.filter(x => x.Id === repair.Id).pop()!;

                        repairs[repairs.indexOf(foundRepair)] = repair;
                        await this.storage.set(Constants.sqlStorageKeys.repairs, repairs);
                        this._repairs.next(repairs);

                        await this.offlineEventService.storeRequest(url, 'post', data);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    }
}
