import { Component, OnInit } from '@angular/core';
import { NavController, RefresherCustomEvent } from '@ionic/angular';
import { BaseComponent } from 'src/app/shared/classes/BaseComponent';
import * as moment from "moment"
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { HandOverService } from './handover.service';
import { HandOverArchiveContainer } from 'src/app/shared/classes/HandOverArchiveContainer';

@Component({
    selector: 'handover',
    templateUrl: './handover.component.html',
    styleUrls: ['./handover.component.scss'],
})
export class HandOverComponent extends BaseComponent implements OnInit {
    handovers: HandOverArchiveContainer[] = [];

    private readonly monthsBackInTime = 6;

    constructor(navController: NavController,
        private handOverService: HandOverService,
        private appSettings: LocalAppSettingsService) {
        super(navController);
    }

    async ngOnInit() {
        moment.locale(this.appSettings.selectedLanguage);

        await this.initializeReportArchive();
    }

    private async initializeReportArchive() {
        const start = moment().subtract(this.monthsBackInTime, 'months').startOf('month');
        const end = moment().endOf('month');

        const reportContainers: HandOverArchiveContainer[] = [];

        for (let i = 0; i <= this.monthsBackInTime; i++) {
            let date = moment().subtract(i, 'month');
            reportContainers.push(new HandOverArchiveContainer(date.month() + 1, moment.months()[date.month()], date.year()));
        }

        const reports = await this.handOverService.listBetweenDates(start.toISOString(), end.toISOString());

        reports!.forEach((report) => {
            const departmentDate = moment(report.Date);

            for (let i = 0; i < reportContainers.length; i++) {
                const reportContainer = reportContainers[i];

                if (reportContainer.month === departmentDate.month() + 1 && reportContainer.year === departmentDate.year()) {
                    reportContainer.reports.push(report);
                    break;
                };
            };
        });

        this.handovers = reportContainers;
    }

    async refresh(event: RefresherCustomEvent) {
        await this.initializeReportArchive();
        event.target.complete();
    }

    navigateToReportDetail(id: number) {
        this.navController.navigateForward(`/home/handover/${id}`);
    }
}
