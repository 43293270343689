<div class="row justify-content-between">
  <div class="col pr-sm-0">
    <canvas
      #signatureCanvas
      (mousedown)="startDrawing($event)"
      (touchstart)="startDrawing($event)"
      (touchmove)="moved($event)"
      (mousemove)="moved($event)"
      (mouseup)="endDrawing($event)"
      (touchend)="endDrawing($event)"
    >
    </canvas>
    <br />
  </div>
  <div class="col-auto btn-min-width">
    <ion-button color="light" shape="circle" (click)="clear()">
        <ion-icon slot="icon-only" color="primary" name="trash">
        </ion-icon>
      </ion-button>
  </div>
</div>
