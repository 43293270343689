import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { catchError, mergeMap } from 'rxjs/operators';
import { firstValueFrom, throwError } from 'rxjs';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { Constants } from 'src/app/constants';
import { IShipLayout } from '../shared/models/ShipLayout';
import { StorageService } from './storage.service';
import { ISerenityRetrieveResponse } from 'src/app/shared/models/serenity/SerenityResponse';

@Injectable({
    providedIn: 'root'
})
export class ShipLayoutService {

    constructor(private http: HttpService,
        private storage: StorageService,
        private networkService: NetworkService) { }

    async retrieve(id: number) {
        const http$ = await this.http.post<ISerenityRetrieveResponse<IShipLayout>>(`${environment.baseUrl}/api/ShipLayout/Retrieve`, { EntityId: id })
        return firstValueFrom(http$
        .pipe(mergeMap(async (reponse: ISerenityRetrieveResponse<IShipLayout>) => {

                var result:IShipLayout = reponse.Entity;

                if (typeof result.Layout !== 'undefined') {
                    result.Layout = `${environment.baseUrl}/upload/${result.Layout}`;
                } else {
                    result.Layout = "/assets/top-banner.png";
                }

                await this.storage.set(`${Constants.sqlStorageKeys.shipLayout}${id}`, result);
                return result;
            }))
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        return await this.storage.get<IShipLayout>(`${Constants.sqlStorageKeys.shipLayout}${id}`);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })));
    };
}
