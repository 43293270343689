import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from "../../../environments/environment";
import { IJourneyReport, IJourneyReportEntity } from 'src/app/shared/models/JourneyReport';
import { ISerenitySaveResponse, ISerenityListResponse, ISerenityRetrieveResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { LocalAppSettingsService } from 'src/app/services/local-app-settings.service';
import { Constants } from 'src/app/constants';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OfflineEventService } from 'src/app/services/offline-event.service';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { firstValueFrom, throwError } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class JourneyReportService {

    constructor(private http: HttpService,
        private appSettings: LocalAppSettingsService,
        private storage: StorageService,
        private networkService: NetworkService,
        private offlineEventService: OfflineEventService) { }

    async create(report: IJourneyReport) {
        const url = `${environment.baseUrl}/api/JourneyReport/Create`;
        const data = { Entity: report };
        const http$ = await this.http.post<ISerenitySaveResponse>(url, data);
        return firstValueFrom(http$
            .pipe(
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        await this.offlineEventService.storeRequest(url, "post", data);
                    } else {
                        return firstValueFrom(throwError(() => error));
                    }
                })
            ));
    }

    async retrieve(id: string) {
        const http$ = await this.http.post<ISerenityRetrieveResponse<IJourneyReportEntity>>(`${environment.baseUrl}/api/JourneyReport/Retrieve`, { EntityId: id })
        return firstValueFrom(http$
            .pipe(
                map((result) => {
                    const report = result.Entity;
                    
                    for (let image of report.Images) { 
                        image.Image = `${environment.baseUrl}/upload/${image.Image}`;
                    }

                    return result.Entity
                }), 
                catchError(async (error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        const reports = await this.storage.get<IJourneyReportEntity[]>(Constants.sqlStorageKeys.journeyReports);
                        return reports.filter(report => report.Id === parseInt(id)).pop();
                    } else {
                        return firstValueFrom(throwError(() => error));
                    };
                })
            ));
    };

    async listBetweenDates(startDate: string, endDate: string) {
        const http$ = await this.http.post<ISerenityListResponse<IJourneyReportEntity>>(`${environment.baseUrl}/api/JourneyReport/ListBetweenDates`, { Startdate: startDate, EndDate: endDate, ShipId: this.appSettings.ship.Id, Sort: ["DepartmentDate"] })
        return firstValueFrom(http$
            .pipe(
                mergeMap(async (result) => {
                    await this.storage.set(Constants.sqlStorageKeys.journeyReports, result.Entities);
                    return result.Entities;
                }), 
                catchError(async (error) => {
                        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                            await this.storage.get<IJourneyReportEntity[]>(Constants.sqlStorageKeys.journeyReports)
                        } else {
                            return firstValueFrom(throwError(() => error));
                        }
                })
            ));
    }
}
