import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from "../../../environments/environment";
import { ISerenitySaveResponse } from 'src/app/shared/models/serenity/SerenityResponse';
import { IDeclaration } from 'src/app/shared/models/Declaration';
import { catchError } from 'rxjs/operators';
import { OfflineEventService } from 'src/app/services/offline-event.service';
import { NetworkService, ConnectionStatus } from 'src/app/services/network.service';
import { firstValueFrom, throwError } from 'rxjs';
import { IInspectionReport } from 'src/app/shared/models/InspectionReport';

@Injectable({
    providedIn: 'root'
})
export class InspectionReportService {

    constructor(private http: HttpService,
        private networkService: NetworkService,
        private offlineEventService: OfflineEventService) { }

    async create(inspectionReport: IInspectionReport) {
        const url = `${environment.baseUrl}/api/InspectionReport/Create`;
        const data = { Entity: inspectionReport };
        const http$ = await this.http.post<ISerenitySaveResponse>(url, data);
        return firstValueFrom(http$
            .pipe(
                catchError(async(error) => {
                    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
                        await this.offlineEventService.storeRequest(url, "post", data);
                    } else {
                        return firstValueFrom(throwError(error));
                    };
                })
            ));
    }
}
