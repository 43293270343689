import { Component, OnInit } from '@angular/core';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss'],
})
export class AddImageComponent implements OnInit {

  constructor(public popoverController: PopoverController) { }

  ngOnInit() { }

  openCamera() {
    this.takePicture(CameraSource.Camera);
  }

  openLibrary() {
    this.takePicture(CameraSource.Photos);
  }

  takePicture(sourceType: CameraSource) {
    const options: ImageOptions = {
      quality: 75,
      resultType: CameraResultType.DataUrl,
      correctOrientation: true,
      source: sourceType,
    }

    Camera.getPhoto(options).then(photo => {
      this.popoverController.dismiss(photo);
    }, (err) => {
      // Handle error
    });
  }
}
